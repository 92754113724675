import React, { useState, useContext } from 'react';
import {Row, Col, message} from 'antd'
import EmptyLine from "../../ui/EmptyLine";

import "./CreateAccount.css"
import AJInput from "../../ui/AJInput";
import AJButton from "../../ui/AJButton";
import EmptySpan from "../../ui/EmptySpan";
import AJCheckbox from "../../ui/AJCheckbox";
import VCenterContainer, {HCenterContainer} from "../../ui/VCenterContainer";
import CreateAccountProvider from "../../context/CreateAccountProvider";
import CreateAccountContext from "../../context/CreateAccountContext";
import {useHistory} from 'react-router'
import AJButton2 from "../../ui/AJButton2";
import AJButton3, {AJCenterButton} from "../../ui/AJButton3";
import {checkToken, companyCreation} from "../../http/AJHttp";
import AJInput2, {
    AJAutoCheckPasswordInput2, AJCheckPasswordInput2,
    AJEmailInput,
    AJMandatoryInput,
    AJPasswordInput2,
    AJPhoneInput
} from "../../ui/AJInput2";
import AJCard from "../../ui/AJCard";
import AppContext from "../../context/AppContext";
import {setCurrentUserId} from "../../storage/AJStorage";
import CompanyRegisterTemplate from "./CompanyRegisterTemplate";


function RegisterInput(props) {
    return (
        <div style={{width: 303}}>
            {props.children}
        </div>
    )
}

function RegisterInput2(props) {
    return (
        <div style={{width: 303 + 36}}>
            {props.children}
        </div>
    )
}

function CreateAccount(props) {
    let history = useHistory();

    const inputWidth = 300

    const [disabled, setDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [passwordValid, setPasswordValid] = useState(false)

    const {accountInfo, updateAccountInfo, checkAccountInfoCompleted} = useContext(CreateAccountContext)
    const {setCurrentAccount} = useContext(AppContext)

    const onValueChange = (key, value, checkFun) => {
        updateAccountInfo(key, value)
        if (checkAccountInfoCompleted(checkFun)) {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }
    }

    const createAccount = async () => {
        setLoading(true)
        const companyInfo = {
            ...accountInfo,
            username:  accountInfo.email,
            companyname: accountInfo.username,
        }
        let registerSuccess = false
        let res = await companyCreation(companyInfo)

        if (res) {
            res = await checkToken(res.data.key)
            if (res) {
                setCurrentAccount(res.data)


                // TODO
                // document.getElementById('airjobb-menu-sign').hidden = true
                // document.getElementById('airjobb-menu-out').hidden = false
                if (props.login) {
                    props.login()
                }
                registerSuccess = true
            }
        }

        setLoading(false)

        if (registerSuccess) {
            const isAdmin = res?.data?.is_admin ?? false;
            const userId = res?.data?.userid ?? 0;
            const companyName = res?.data?.companyname ?? '';
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'custom_event',
                'eventCategory':'sign_in',
                'eventAction': 'click',
                'eventlabel': isAdmin? `#admin# : ${userId}` : (companyName || userId),
                'lastLogin': new Date().toUTCString()
            });
            history.push("/register/filter-key-values")
        }
        else {
            message.error('Resister failed!')
        }
    }

    return (
        <CompanyRegisterTemplate tips={'Are You Ready To Leverage Values & Purpose?'}>
            <AJCard width={969} height={623} borderRadius={44} type={'wide'} onClose={(e) => {
                history.goBack()
            }}>
                        <div className={'create-account-container'}>

                            <div className={'login-common-title'}>
                                New company user
                            </div>
                            <div className={'create-account-input-group'}>
                                <Row>
                                    <Col span={12}>
                                        <RegisterInput>
                                            <AJMandatoryInput label={'Company Name'}
                                                      onChange={(e)=>{
                                                          onValueChange('username', e.target.value)
                                                      }}
                                                      defaultValue={accountInfo.username}
                                            />
                                        </RegisterInput>
                                    </Col>
                                    <Col span={12}>
                                        <RegisterInput2>
                                            <AJEmailInput mandatory={true} label={'Email'}
                                                      onChange={(value)=>{
                                                          onValueChange('email', value)
                                                      }}
                                                      defaultValue={accountInfo.email}
                                            />
                                        </RegisterInput2>
                                    </Col>
                                </Row>
                                <EmptyLine height={35}/>
                                <Row>
                                    {/*<Col span={12}>*/}
                                    {/*    <RegisterInput>*/}
                                    {/*        <AJInput2 label={'Org. Nr'}*/}
                                    {/*                  onChange={(e)=>{*/}
                                    {/*                      onValueChange('org.Nr', e.target.value)*/}
                                    {/*                  }}*/}
                                    {/*                  defaultValue={accountInfo['org.Nr']}*/}
                                    {/*        />*/}
                                    {/*    </RegisterInput>*/}

                                    {/*</Col>*/}
                                    <Col span={12}>
                                        <RegisterInput2>
                                            <AJPhoneInput mandatory={false} label={'Phone'}
                                                      onChange={(value)=>{
                                                          onValueChange('phone', value, ()=>true)
                                                      }}
                                                      defaultValue={accountInfo.phone}
                                            />
                                        </RegisterInput2>

                                    </Col>
                                </Row>

                                <EmptyLine height={85}/>
                                <Row>
                                    <Col span={12}>
                                        <RegisterInput>

                                            <AJPasswordInput2 label={'Password'}
                                                              onChange={(e)=>{
                                                                  const value = e.target.value
                                                                  if (value === accountInfo.password2) {
                                                                      setPasswordValid(true)
                                                                  }
                                                                  else {
                                                                      setPasswordValid(false)
                                                                  }
                                                                  onValueChange('password1', value)
                                                              }}
                                            />
                                        </RegisterInput>
                                    </Col>
                                    <Col span={12}>
                                        <RegisterInput2>

                                            <AJCheckPasswordInput2 label={'Input password again'}
                                                                       showIcon={passwordValid}
                                                              onChange={(e)=>{
                                                                  const value = e.target.value
                                                                  if (value === accountInfo.password1) {
                                                                      setPasswordValid(true)
                                                                  }
                                                                  else {
                                                                      setPasswordValid(false)
                                                                  }
                                                                  onValueChange('password2', value)
                                                              }}
                                            />
                                        </RegisterInput2>

                                    </Col>
                                </Row>
                            </div>

                            <EmptyLine height={56}/>
                            <Row>
                                <Col span={12}>
                                    <HCenterContainer>
                                        <div>
                                            <div>
                                                <AJCheckbox
                                                    title={<span>Agree to &nbsp;
                                                        <a onClick={()=>{
                                                            window.open('/terms-conditions')
                                                        }}><u>terms and Conditions</u>
                                                        </a></span>}
                                                    titleClass={'create-account-agree'}
                                                    space={6}
                                                    onChange={(value) => {
                                                        onValueChange('agree', value, (value)=>value)
                                                    }}/>
                                            </div>
                                        </div>
                                    </HCenterContainer>
                                </Col>
                            </Row>

                            <EmptyLine height={43}/>

                            <AJButton3
                                title={'Continue'}
                                titleStyle={{color: 'white', fontFamily: 'ManropeBold', fontSize: '22px'}}
                                height={60}
                                width={303}
                                disabled={disabled}
                                loading={loading}
                                onClick={createAccount}
                            />
                        </div>
            </AJCard>
        </CompanyRegisterTemplate>
    )
}

export default CreateAccount
