import React from "react";

import {Row, Col} from "antd"


export function alignVMiddle(renderFun) {
    return (
        <Row type="flex" justify="space-around" align="middle" style={{height: '100%'}}>
            <Col span={24}>
                {
                    renderFun()
                }
            </Col>
        </Row>
    )
}