import React, {useState} from "react";
import "./StructureChanging.less";
import CompanyConfigureContainer, {FooterButtons} from "../login/CompanyConfigureContainer";
import {HCenterContainer} from "../../ui/VCenterContainer";
import EmptyLine from "../../ui/EmptyLine";
import {Col, Row, Input} from "antd";
import AJInput2 from "../../ui/AJInput2";
const {TextArea} = Input

export default function StructureChanging(props) {
    const header = 'Edit Company information'

    const {name, location, introducation, website} = props.profileData

    // TODO
    const introduction = introducation

    const idPrefix = props.idPrefix || 'aj-structure-changing'
    const companyNameId = `${idPrefix}-company-name`
    const companyTagId = `${idPrefix}-company-tag`
    const companyLocationId = `${idPrefix}-company-location`
    const companyWebsiteId = `${idPrefix}-company-website`
    const footer = (
        <FooterButtons
            nextWidth={191}
            titleStyle={{ fontSize: '21px', fontFamily: 'ManropeBold' }}
            backName={'Close'}
            nextName={'Save'}
        onNext={() => {
            if (props.onSave) {
                props.onSave({
                    name: document.getElementById(companyNameId).value,
                    location: document.getElementById(companyLocationId).value,
                    website: document.getElementById(companyWebsiteId).value,
                    introduction: document.getElementById(companyTagId).value
                })
            }
            if (props.onClose) {
                props.onClose()
            }
        }}
        onBack={()=>{
            if (props.onClose) {
                props.onClose()
            }
        }}
        />
    )

    const [leftWords, setLeftWords] = useState(120 - (introduction ? introduction.length : 0))

    const body = (
        <div className={'structure-changing-container'}>
            <br/>
            <div className={'structure-changing-container-sub-title'}>
                Here you can change your Name, Location, Webaddress<br/> and the companys Core Value.
            </div>
            <EmptyLine height={62}/>
            <Row>
                <Col span={14}>
                    <AJInput2
                        placeholder={'Company Name'}
                        id={companyNameId}
                        defaultValue={name}/>
                    <EmptyLine height={35}/>
                    <AJInput2
                        placeholder={'Location'}
                        id={companyLocationId}
                        defaultValue={location}/>
                    <EmptyLine height={35}/>
                    <AJInput2
                        placeholder={'Company Website'}
                        id={companyWebsiteId}
                        defaultValue={website}/>
                    <EmptyLine height={35}/>
                </Col>
                <Col span={10}>
                    <div style={{paddingLeft: 48, marginTop: -16}}>
                        <div className={'structure-changing-container-tag-title'}>
                            Company Tag Line
                        </div>
                        <br/>
                        <div style={{position: 'relative'}}>
                            <TextArea
                                id={companyTagId}
                                style={{height: 140}}
                                autoSize={{minRows: 6, maxRows: 6}}
                                defaultValue={introduction}
                                maxLength={120}
                                onChange={(e)=>{
                                    setLeftWords(120-e.target.value.length)
                                }}
                            />
                            <EmptyLine height={28}/>
                            <div style={{position: 'absolute', bottom: 0, right: 0}}>
                                {`${leftWords}/120`}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )

    return (
        <HCenterContainer>
            <CompanyConfigureContainer header={header} body={body} footer={footer}
                                       borderRadius={22}
                                       hideClose={true}/>
        </HCenterContainer>
    )
}