import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import EmptyLine from "../../ui/EmptyLine";
import {defaultValuesConfig, valuePatch} from "../../component/values/ValuesConfig";
import QuestionsInInterviewContext, {QuestionsInInterviewProvider} from "./QustionsInInterviewContext";
import StartFooter from "../../component/start/StartFooter";
import _ from 'lodash'
import "../../component/company/ValueChanging.less"

import "./QuestionsInInterview.less"
import {Col, message, Row} from "antd";
import AJInput2 from "../../ui/AJInput2";
import uuid from "react-uuid";
import {useOnClickOutside} from "../../hooks/common";
import AJButton3, {AJButton4} from "../../ui/AJButton3";
import AJModalV2 from "../../ui/AJModalV2";
import {getJoinedCommunityEmail, isFreeEducationFTU, setJoinedCommunity} from "../../storage/AJStorage";
import {getInterviewQuestions, sendCommunityMail} from "../../http/AJHttp";
// import "../../component/common/JoinOurTalent.less"
const valuesConfig = defaultValuesConfig.filter(config => {
    const title = config.textLines.join(' ');
    return title.indexOf('Sustainability') === -1 && title.indexOf('Company Structure')
});
export default function QuestionsInInterview() {
    return <QuestionsInInterviewProvider>
        <QuestionsInInterviewBody/>
    </QuestionsInInterviewProvider>
}

function QuestionsInInterviewBody() {

    const valueConfigGroups = _.chunk(valuesConfig, 2)
    const [modalVisible, setModalVisible] = useState(false)
    const {selectedValues} = useContext(QuestionsInInterviewContext);
    const [showWelcome, setShowWelcome] = useState(false);

    useEffect(() => {
        if (selectedValues.length === 9) {
            setModalVisible(true)
        }
    }, [selectedValues])

    // useEffect(() => {
    //     if (isFreeEducationFTU()) {
    //         setTimeout(() => {
    //             if (!modalVisible) {
    //                 setShowWelcome(true)
    //             }
    //         }, 500)
    //
    //     }
    // }, [])

    return <>
        <div className={'toolbox-page-container'}>
            <div className={'toolbox-page-header-container'}>
                <EmptyLine height={12}/>
                <div className={'toolbox-page-header-title'}>
                    The important questions to <br/>ask in your job interview.
                </div>
            </div>
            <div className={'toolbox-page-content-container'}>
                <EmptyLine height={56}/>
                <div className={'toolbox-page-content-left'} >
                    {/*<Row align={'middle'} justify={'center'} style={{height: '100%'}}>*/}
                    <div>
                        <img src={'/img/toolbox/left-pic-v2.png'} alt={'left page'} width={393} height={393}/>
                    </div>
                    {/*</Row>*/}
                </div>
                <div className={'toolbox-page-content-right'}>
                    <div className={'toolbox-page-content-right-title'}>
                        Pick your 9 most essential team<br/> values below and get your questions<br/> sent to you.
                    </div>
                    <EmptyLine height={45}/>
                    <div className={'toolbox-page-content-right-values'}>
                        {
                            valueConfigGroups.map((group, index) => {
                                return <ValuesPickerGroup group={group} key={index}/>
                            })
                        }
                    </div>
                    <EmptyLine height={20}/>
                    <GetYourQuestions onClick={() => {
                        setModalVisible(true)
                    }}/>
                    <EmptyLine height={50}/>
                </div>
            </div>
            <AJModalV2 visible={modalVisible}
                       width={400}
                       closable={true}
                       centered={true}
                       destroyOnClose={true}
                       onClose={() => {
                           //window.open(href);
                           setModalVisible(false)
                       }}
            >
                <ConfirmModal onClose={() => setModalVisible(false)}/>
            </AJModalV2>

            {/*<AJModalV2 visible={showWelcome}*/}
            {/*           width={400}*/}
            {/*           closable={true}*/}
            {/*           centered={true}*/}
            {/*           destroyOnClose={true}*/}
            {/*           onClose={() => {*/}
            {/*               setShowWelcome(false)*/}
            {/*           }}*/}
            {/*>*/}
            {/*    <WelcomeModal onClose={() => setShowWelcome(false)}/>*/}
            {/*</AJModalV2>*/}
        </div>

        <StartFooter/>
    </>

}

function GetYourQuestions({onClick})
{
    const {selectedValues} = useContext(QuestionsInInterviewContext);

    return <div
        onClick={() => {
            if (selectedValues.length === 9) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'custom_event',
                    'eventCategory':'get_your_questions',
                    'eventAction': 'click',
                    'eventlabel': '/toolbox',
                });
                onClick && onClick()
            }

        }}
        style={{marginRight: 56}}
        className={`values-picker-button ${selectedValues?.length === 9 ? '' : 'values-picker-button-disabled'}`}>
        Get your questions
    </div>;
}

export function ValuesPickerGroup({group}) {
    return <Row style={{paddingBottom: 60}}>
        {
            group.map((config, index) => {
                return <Col span={12} key={index} style={{paddingRight: 56}}>
                    <ValuesPicker config={config}/>
                </Col>
            })
        }
    </Row>
}

export function ValuesPicker({config, disable}) {
    const title = config.textLines.join(" ")
    const values = valueReorder(config.values);
    return <div className={'values-picker-container'}>
        <div className={'values-picker-title'}>
            {title}
        </div>
        <div className={'values-picker-line'}/>
        <div className={'values-picker-tags-container'}>
            {
                values.map((v, index) => {
                    return <div key={index} style={{paddingRight: '14px', paddingBottom: '24px', display: 'inline-block'}}>
                        <ValueTag value={v.value} disabled={disable || false}/>
                    </div>
                })
            }
        </div>
    </div>
}

function ValueTag({value, disabled}) {
    const {selectedValues, toggleValues} = useContext(QuestionsInInterviewContext);
    const selected = selectedValues.filter(v => v === value).length > 0;
    let selectedClass = selected ?
        'value-changing-value-tag value-changing-value-tag-selected' : "value-changing-value-tag"
    if (disabled) {
        selectedClass = 'value-changing-value-tag-disabled'
    }
    return (
        <span className={`${selectedClass}`}
              onClick={()=>{
                  toggleValues(value)
              }}>
            {valuePatch(value)}
        </span>
    )
}

function ConfirmModal({onClose})
{
    const [sending, setSending] = useState(false);
    const ref = useRef();
    const uuidRef = useRef(uuid());
    const [disable, setDisable] = useState(!getJoinedCommunityEmail());
    const [sent, setSent] = useState(false);
    const {selectedValues} = useContext(QuestionsInInterviewContext);

    // console.log(disable);
    // const [email, setEmail] = useState(getJoinedCommunityEmail())
    useOnClickOutside(ref, [], () => {
        onClose && onClose(true);
    });
    useLayoutEffect(() => {
        document.getElementById(uuidRef.current).focus();
    }, []);

    return <div className={'values-picker-confirm-modal'}>
        <EmptyLine height={40}/>
        <div style={{textAlign: 'center'}}>
            <img src={'/img/toolbox/prompt-pic-v2.png'} alt={'prompt'} width={274} height={274}/>
        </div>
        <EmptyLine height={12}/>

        <div className={'values-picker-confirm-title'}>
            {sent ? <>Thank you!<br/>Your questions<br/> are on the way.</> : <>Yes please, send<br/> me the questions</>}
        </div>

        {
            sent ? <>
                <EmptyLine height={40}/>
                <div className={'values-picker-confirm-modal-button'}>
                    <div style={{margin: 'auto', fontSize: 38}}>
                        <AJButton3 title={'Close'}
                                   size={'mini'}
                                   width={320}
                                   type={'secondary'}
                                   loading={sending}
                                   disabled={disable}
                                   height={60}
                                   titleStyle={{
                                       fontSize: 32,
                                       display: 'inline-block'
                                   }}
                                   onClick={() => {
                                       onClose && onClose()
                                   }}/>
                    </div>
                </div>
            </> : <>
                <EmptyLine height={20}/>
                <div style={{textAlign: 'center'}}>
                    <div className={'join-our-talent-email-2'} style={{width: 250, margin: 'auto'}} onChange={(e) => {
                        const email = e.target.value;
                        const pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;
                        if (pattern.test(email)) {
                            setDisable(false);
                        }
                        else {
                            setDisable(true);
                        }
                    }} onClick={() => {
                        document.getElementById(uuidRef.current).focus();
                    }}>
                        <AJInput2 placeholder={'Enter your email'} id={uuidRef.current} defaultValue={getJoinedCommunityEmail()}/>
                    </div>
                </div>

                <EmptyLine height={30}/>

                <div className={'values-picker-confirm-modal-button'}>
                    <div style={{margin: 'auto', fontSize: 38}}>
                        <AJButton3 title={'Send'}
                                   size={'mini'}
                                   width={320}
                                   type={'secondary'}
                                   loading={sending}
                                   disabled={disable}
                                   height={60}
                                   titleStyle={{
                                       fontSize: 32,
                                       display: 'inline-block'
                                   }}
                                   onClick={
                                       async () => {
                                           const id = uuidRef.current
                                           const email = document.getElementById(id).value
                                           setSending(true)

                                           const origEmail = getJoinedCommunityEmail()
                                           if (email !== origEmail) {
                                               const response = await sendCommunityMail(email)
                                               if (response) {
                                                   setJoinedCommunity(email)
                                               }
                                           }
                                           await getInterviewQuestions(email, selectedValues)
                                           // console.log(email)
                                           // const response = await sendCommunityMail(email)
                                           // await jobOpeningVisitor(email, url)
                                           //
                                           // if (response) {
                                           //     setJoinedCommunity(email);
                                           // }
                                           // else {
                                           // }
                                           setSending(false)
                                           // message.success("Coming soon...")
                                           document.getElementById(id).value = ''
                                           //onClose && onClose();
                                           setSent(true)
                                       }
                                   }/>
                    </div>

                </div>

                <EmptyLine height={16}/>
                <div className={'values-picker-confirm-modal-privacy'} onClick={() => {
                    window.open('/privacy')
                }}>
                    Privacy
                </div>
            </>
        }


    </div>
}

export function WelcomeModal({onClose}) {
    return <div className={'free-education-welcome-modal'}>
        <EmptyLine height={40}/>
        <div style={{textAlign: 'center'}}>
            <img src={'/img/toolbox/left-pic-v2.png'} alt={'modal'} width={274}/>
        </div>
        <EmptyLine height={36}/>
        <div className={'free-education-welcome-modal-text'}>
            Get free interview education
        </div>
        <EmptyLine height={36}/>
        <div className={'free-education-welcome-modal-button'}>
            <div style={{margin: 'auto', fontSize: 38}}>
                <AJButton4 title={'Yes!'}
                           size={'mini'}
                           width={320}
                           type={'secondary'}
                           loading={false}
                           disabled={false}
                           height={60}
                           titleStyle={{
                               fontSize: 32,
                           }}
                           onClick={ () => {
                               onClose && onClose()
                           }}/>
            </div>
        </div>

        <EmptyLine height={16}/>
        <div className={'free-education-welcome-modal-privacy'} onClick={() => {
            window.open('/privacy')
        }}>
            Privacy
        </div>
    </div>
}

function valueReorder(values) {
    const orderedValues = [];
    let index = 0;
    for (let i = 0; i < values.length; ++i) {
        if (values[i].order) {
            orderedValues[values[i].order] = values[i];
            values[i].used = true
        }
    }
    for (let i = 0; i < values.length; ++i) {
        if (values[i].used) {
            continue
        }
        while(orderedValues[index] && index < values.length) {
            index++
        }
        orderedValues[index] = values[i]
    }

    return orderedValues;
}