import React, {useState, useEffect, useRef} from 'react';
import 'antd/dist/antd.less';
import './App.less';
import {Drawer} from 'antd';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import AppProvider from "./context/AppProvider";
import Navigator from "./component/Navigator";
import Menu from "./component/Menu";
import {JoinCommunity2, StartPageWrapper} from "./page/StartPageV2";
import MatchedCompaniesWrapper from './page/MatchedCompanies';
import PageWrapper from "./page/PageWrapper";
import FilterValuesProvider from "./context/FilterValuesProvider";
import "./ui/AJFont.less";
import {getFavorite} from "./http/AJHttp";
import RegisterRoute from "./route/RegisterRoute";
import AboutPage from "./page/AboutPage";
import {currentToken, isLogged} from "./storage/AJStorage";
import CompanyProfile2Container from "./page/CompanyProfile2";
import CompanyProfile3Container from "./page/CompanyProfile3";
import CompanySavedPage from "./page/CompanySavedPage";
import NotFound404 from "./page/NotFound404";
import ScrollPageContainer from "./component/ScrollPageContainer";
import TermsConditionsPage from "./page/TermsConditionsPage";
import ReactGA from 'react-ga';
import PrivacyPage from "./page/PrivacyPage";
import InsightsPage from "./page/InsightsPage";
import BlogPage from "./page/BlogPage";
import HowItWorks from "./component/HowItWorks";
import DocumentTitle from "./component/DocumentTitle";
import HowItWorkCompany from "./page/HowItWorkCompany";
import HowItWorkTalent from "./page/HowItWorkTalent";
import ContactPage from "./page/ContactPage";
import NFTPage from "./page/NFTPage";
import {Provider} from "react-redux";
import store from './redux/store';
import OAuthCallback from "./component/OAuthCallback";
import QuestionsInInterview from "./page/toolbox/QuestionsInInterview";
import PricePage from "./page/price/PricePage";
import TechStackEditing from "./component/tech-stack/TechStackEditing";
import JobVisitors from "./page/JobVisitors";
import JobTruecaller from "./syncjobs/JobTruecaller";
import ValueSelectWrapper from "./component/value-select/valueSelect";


ReactGA.initialize('UA-160729462-1');

const injectGA = () => {
    if (typeof window == 'undefined') {
        return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'UA-160729462-1');
};

function App() {

    const [menuClass, setMenuClass] = useState('')
    // let [containerHeight, setContainerHeight] = useState(window.innerHeight)

    function AppNavigator(props) {
        const newProps = {
            ...props,
            onClickMenu: () => {
                //setMenuClass('app-menu-container-show')
                setMenuVisible(true)
                // setContainerHeight(window.innerHeight)
            }
        }

        return (<Navigator {...newProps} favorite={favoriteData}/>)
    }

    const onCloseMenu = () => {
        setMenuVisible(false)
    }
    const [menuVisible, setMenuVisible] = useState(false)

    const loginBackgroundColor = 'linear-gradient(180deg, rgba(168, 94, 187, 0.54) 2.6%, rgba(143, 195, 242, 0.138) 100%)'

    const loginBackgroundImage = "url('/img/common/logon-bg.svg')"

    const [isLogon, setIsLogon] = useState(!!currentToken())

    const [showAJModel, setShowAJModel] = useState(false)
    const [currentValue, setCurrentValue] = useState("Diversity")

    const [favoriteData, setFavoriteData] = useState([])

    useEffect(()=>{
        fetchFavorite()
    }, [])

    const fetchFavorite = async () => {
        const response = await getFavorite()
        if (response) {
            setFavoriteData(response.data)
            ReactGA.event(response)
        }

        ReactGA.pageview('/home');

        injectGA()

        return response
    }

    const [readonly, setReadonly] = useState(false)
    const [value, setValue] = useState('')
    const richEditorRef = useRef()

    return (
        <Provider store={store}>

            <AppProvider>
                <DocumentTitle>
                    <div className={'App-container'}>
                        <Router>
                            <ScrollPageContainer>

                                <Switch>
                                    <Route exact path='/' render={() =>
                                        <div style={{background: 'white'}}>
                                            <AppNavigator colorStyle={'black'} version={'2'}/>
                                                <FilterValuesProvider>
                                                    <StartPageWrapper/>
                                                </FilterValuesProvider>
                                            <div hidden={isLogged()}>
                                                <JoinCommunity2/>
                                            </div>
                                            <HowItWorks/>
                                        </div>
                                    }>
                                    </Route>
                                    
                                    <Route path={'/company-profile-edit/:id'} render={routeProps =>{
                                        return (<div key={routeProps.match.params.id}>
                                            <AppNavigator colorStyle={'white'} hideHowItWorks={true}/>
                                            <CompanyProfile2Container 
                                                refreshFavorite={fetchFavorite}/>
                                        </div>)
                                    }}/>

                                    <Route path={'/company-profile/:id'} render={routeProps =>{
                                        return (<div key={routeProps.match.params.id}>
                                            <AppNavigator colorStyle={'black'} hideHowItWorks={true}/>
                                            <CompanyProfile3Container
                                                refreshFavorite={fetchFavorite}/>
                                        </div>)
                                    }}/>

                                    <Route exact path={'/value-select'}>
                                        <ValueSelectWrapper style={{width: '100%', textAlign: 'center'}}></ValueSelectWrapper>
                                    </Route>

                                    <Route exact path='/companies-matched' render={() =>
                                        <div style={{background: 'white', width: '100%'}}>
                                            <AppNavigator colorStyle={'black'} version={'2'}/>
                                            <MatchedCompaniesWrapper/>
                                        </div>
                                    }>
                                    </Route>

                                    <Route path={'/company-saved'}>
                                        <AppNavigator colorStyle={'black'} version={'2'}/>
                                        <div id='page-1' style={{position: 'relative', top: 60}}>
                                            <PageWrapper image={'/img/common/company-save.png'}  mediaHeight={904} background={'#FFF'} id={'company-saved-pic'}>
                                                <CompanySavedPage refreshFavorite={fetchFavorite}/>
                                            </PageWrapper>
                                        </div>
                                    </Route>

                                    <Route exact path={'/about'}>
                                        <AppNavigator colorStyle={'white'}/>
                                        <div id='page-2' style={{position: 'relative', top: 60}}>
                                            <PageWrapper image={'/img/about/header-img.png'} background={'#FFF'} id={'about-pic'}>
                                                <DocumentTitle title={'Who we are & why we care about engineering values'} description={'Millennials and GenZ talents want to find companies that do work that matters and share the same values.'}>
                                                    <AboutPage/>
                                                </DocumentTitle>
                                            </PageWrapper>
                                        </div>
                                    </Route>

                                    <Route path={'/register'}>
                                        <AppNavigator colorStyle={'black'} version={'1'}/>
                                        {/* <div id='page-3'> */}
                                            <PageWrapper space={130} backgroundImage={loginBackgroundImage}>
                                                <RegisterRoute login={() => setIsLogon(true)}/>
                                            </PageWrapper>
                                        {/* </div> */}
                                    </Route>

                                    <Route path={'/terms-conditions'}>
                                        <AppNavigator colorStyle={'white'}/>
                                        <div id='page-4' style={{position: 'relative', top: 60}}>
                                            <PageWrapper space={0} background={'white'} mediaHeight={897} image={'/img/common/terms-conditions-header.png'} id={'terms-conditions-pic'}>
                                                <TermsConditionsPage/>
                                            </PageWrapper>
                                        </div>
                                    </Route>

                                    <Route path={'/privacy'}>
                                        <AppNavigator colorStyle={'white'}/>
                                        <div id='page-5' style={{position: 'relative', top: 60}}>
                                            <PageWrapper space={0} background={'white'} mediaHeight={897} image={'/img/common/privacy-header.png'} id={'privacy-pic'}>
                                                <PrivacyPage/>
                                            </PageWrapper>
                                        </div>
                                    </Route>

                                    <Route path={'/latest'}>
                                        <AppNavigator colorStyle={'black'} customStyle={{borderBottom: '1px solid black'}}/>
                                        <div className="blog-page" style={{background: 'white'}}>
                                            <InsightsPage/>
                                        </div>
                                    </Route>

                                    <Route path={'/blog/:id'}>
                                        <AppNavigator colorStyle={'black'} customStyle={{borderBottom: '1px solid black'}}/>
                                        <div className="blog-page" style={{background: 'white'}}>
                                            <BlogPage/>
                                        </div>
                                    </Route>

                                    <Route path={'/employer'}>
                                        <AppNavigator colorStyle={'white'}/>
                                        <div id='page-8' style={{position: 'relative', top: 60}}>
                                            <PageWrapper space={0} background={'white'} mediaHeight={480} image={'/img/how-it-works/company-header.png'} id={'employer-pic'}>
                                                <DocumentTitle title={'How tech companies can attract engineers based on their values'} description={'Genvalues company. How you can publish a profile for your tech team to attract top engineers based on team values looking for more than just a job.'}>
                                                    <HowItWorkCompany/>
                                                </DocumentTitle>
                                            </PageWrapper>
                                        </div>
                                    </Route>

                                    <Route path={'/talent'}>
                                        <AppNavigator colorStyle={'white'}/>
                                        <div id='page-9' style={{position: 'relative', top: 60}}>
                                            <PageWrapper space={0} background={'white'} mediaHeight={480} image={'/img/how-it-works/talent-header.png'} id={'talent-pic'}>
                                                <DocumentTitle title={'How engineers can find jobs & teams based on their values'} description={'Genvalues talent. Engineers to find cool tech teams & jobs based on shared values at companies with a great mission.'}>
                                                    <HowItWorkTalent/>
                                                </DocumentTitle>
                                            </PageWrapper>
                                        </div>
                                    </Route>

                                    <Route path={'/contact'}>
                                        <AppNavigator colorStyle={'white'}/>
                                        <div id='page-10' style={{position: 'relative', top: 60}}>
                                            <PageWrapper space={0} background={'white'} mediaHeight={583} image={'/img/toolbox/page-header-v2.png'} id={'contact-pic'}>
                                                <DocumentTitle title={'Contact Genvalues'} description={'Contact us'}>
                                                    <ContactPage/>
                                                </DocumentTitle>
                                            </PageWrapper>
                                        </div>
                                    </Route>

                                    <Route path={'/nft'}>
                                        <AppNavigator colorStyle={'white'}/>
                                        <div id='page-11' style={{position: 'relative', top: 60}}>
                                            <PageWrapper space={0} background={'white'} mediaHeight={480} image={'/img/common/nft-page-header.png'} id={'nft-pic'}>
                                                <DocumentTitle title={'nft'} description={'nft'}>
                                                    <NFTPage/>
                                                </DocumentTitle>
                                            </PageWrapper>
                                        </div>
                                    </Route>

                                    <Route path={'/toolbox'}>
                                        <AppNavigator colorStyle={'white'}/>
                                        <div id='page-12' style={{position: 'relative', top: 60}}>
                                            <PageWrapper space={0} background={'white'} mediaHeight={583} image={'/img/toolbox/page-header-v2.png'} id={'toolbox-pic'}>
                                                <DocumentTitle title={'toolbox'} description={'toolbox'}>
                                                    <QuestionsInInterview/>
                                                </DocumentTitle>
                                            </PageWrapper>
                                        </div>
                                    </Route>

                                    <Route path={'/pricing'}>
                                        <AppNavigator colorStyle={'white'}/>
                                        <div id='page-13' style={{position: 'relative', top: 60}}>
                                            <PageWrapper space={0} background={'white'} mediaHeight={596} image={'/img/price-header-v2.png'} id={'pricing-pic'}>
                                                <DocumentTitle title={'pricing'} description={'pricing'}>
                                                    <PricePage/>
                                                </DocumentTitle>
                                            </PageWrapper>
                                        </div>
                                    </Route>

                                    <Route path={'/oauth/callback'}>
                                        <OAuthCallback/>
                                    </Route>

                                    <Route path={'/test'}>
                                        <TechStackEditing/>
                                    </Route>

                                    <Route path={'/jobvisitors'}>
                                        <JobVisitors/>
                                    </Route>

                                    <Route path={'/syncjobs/truecaller'}>
                                        <JobTruecaller/>
                                    </Route>

                                    <Route path={'*'}>
                                        <AppNavigator colorStyle={'black'} version={'2'}/>
                                        <PageWrapper space={138} background={loginBackgroundColor}>
                                            <NotFound404/>
                                        </PageWrapper>
                                    </Route>
                                </Switch>


                                <Drawer
                                    placement={'right'}
                                    closable={false}
                                    onClose={onCloseMenu}
                                    visible={menuVisible}
                                    drawerStyle={{ background: "#000", overflow: 'hidden' }}
                                    width={405}>
                                    <div>
                                        <Menu onClose={onCloseMenu} isLogon={isLogon} logout={() => setIsLogon(false)} visibleChange={menuVisible}/>
                                    </div>
                                </Drawer>

                            </ScrollPageContainer>

                        </Router>

                    </div>
                </DocumentTitle>
            </AppProvider>
        </Provider>
    )
}



export default App;
