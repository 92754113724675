import React from "react";

function SvgInstagram(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 27" fill="none" {...props}>
      <path
        d="M14.125 6.793c-3.75 0-6.738 3.047-6.738 6.738a6.702 6.702 0 006.738 6.739c3.691 0 6.738-2.989 6.738-6.739 0-3.691-3.047-6.738-6.738-6.738zm0 11.133A4.389 4.389 0 019.73 13.53c0-2.402 1.934-4.336 4.395-4.336a4.326 4.326 0 014.336 4.336c0 2.461-1.934 4.395-4.336 4.395zM22.68 6.559c0-.88-.703-1.582-1.582-1.582-.88 0-1.582.703-1.582 1.582 0 .878.703 1.582 1.582 1.582.879 0 1.582-.704 1.582-1.582zm4.453 1.582c-.117-2.11-.586-3.985-2.11-5.508C23.5 1.109 21.625.64 19.516.523c-2.168-.117-8.672-.117-10.84 0-2.11.118-3.926.586-5.508 2.11C1.645 4.156 1.176 6.03 1.058 8.14c-.117 2.168-.117 8.671 0 10.84.118 2.109.587 3.925 2.11 5.507 1.582 1.524 3.398 1.992 5.508 2.11 2.168.117 8.672.117 10.84 0 2.109-.117 3.984-.586 5.507-2.11 1.524-1.582 1.993-3.398 2.11-5.508.117-2.168.117-8.671 0-10.84zM24.32 21.266c-.41 1.172-1.347 2.05-2.46 2.52-1.758.702-5.86.526-7.735.526-1.934 0-6.035.176-7.734-.527a4.443 4.443 0 01-2.52-2.52c-.703-1.699-.527-5.8-.527-7.734 0-1.875-.176-5.976.527-7.734a4.52 4.52 0 012.52-2.461c1.699-.703 5.8-.527 7.734-.527 1.875 0 5.977-.176 7.734.527 1.114.41 1.993 1.348 2.461 2.46.703 1.759.528 5.86.528 7.735 0 1.934.175 6.035-.528 7.735z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgInstagram;
