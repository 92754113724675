import React, {useState} from "react";
import {defaultValuesConfig, valuePatch} from "../values/ValuesConfig";

import AJModalV2 from "../../ui/AJModalV2";
import AJCard from "../../ui/AJCard";
import Row from "antd/es/grid/row";
import {Col} from "antd";
import {SelectableKeyCard} from "../KeyCard";
import {arrayContains, formatElements} from "../../utils/arrayUtil";
import EmptyLine from "../../ui/EmptyLine";
import "./ValueChanging.less"
import AJButton3 from "../../ui/AJButton3";
import {formatValues} from "../values/PickValues";
import EmptySpan from "../../ui/EmptySpan";


const keyCardTextStyle = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    paddingLeft: '30px'
}

function findKeyByValue(keys, value) {
    for (const key of keys) {
        const values = key.values
        if (values.filter(v => v.value === value).length > 0) {
            return key
        }
    }

    console.log(`There is no key contains ${value}!`)

    // using default key:
    return keys[0]
}

export default function ValueChanging(props) {
    const valuesConfig = [...defaultValuesConfig]
    valuesConfig.pop()


    const {onSave, onClose} = props

    const [currentValue, setCurrentValue] = useState(props.value)
    const [editingKey, setEditingKey] = useState(findKeyByValue(valuesConfig, valuePatch(props.value)))


    return (
        <AJModalV2
            centered
            visible={props.showModal}
            closable={true}
            onClose={() => {
                // if (editingKey) {
                //     setEditingKey(null)
                // }
                // else {
                    if (props.onClose)
                        props.onClose(currentValue)
                // }

            }}>

            <div className={`value-changing-value-container-folder ${editingKey? 'value-changing-value-container' : null}`}>
                <ValuesContainer
                    {...props}
                    editingKey={editingKey}
                    currentValue={currentValue}
                    onChangeValue={(newValue) => {
                        setCurrentValue(newValue)
                    }}/>
            </div>

            <div>
                <AJCard width={1170}
                        height={352/*'infinity'*/}
                        borderRadius={22}
                        type={'wide'}
                        hideClose={true}>
                    <div style={{padding: '60px 61px 0'}}>
                        {/*<div className={'value-changing-key-container-title'}>*/}
                        {/*    Edit your value*/}
                        {/*    /!*<span style={{color: '#754FDE'}}>*!/*/}
                        {/*    /!*    {editingKey ? ` in ${editingKey.textLines.join(' ')}` : ''}*!/*/}
                        {/*    /!*</span>*!/*/}

                        {/*</div>*/}
                        {/*<EmptyLine height={50}/>*/}
                        {
                            formatElements(valuesConfig, 4,
                                37,
                                (valueKey) => <SelectableKeyCard
                                    // disSelectable={false}
                                    // highlight={highlight ? highlight(card) : null}
                                    valueKey={valueKey}
                                    selected={editingKey == valueKey}
                                    // valueKey={card}
                                    textLines={valueKey.textLines}
                                    // cardContainerStyle={keyCardContainerStyle}
                                    cardTextStyle={keyCardTextStyle}
                                    onClick={(key, selected)=>{

                                        if (!editingKey) {
                                            setEditingKey(key)
                                        }
                                        else {
                                            setEditingKey(key)
                                        }
                                    }}/>)
                        }
                        <EmptyLine height={12}/>
                        <div style={{textAlign: "center"}}>
                            <span>
                                <AJButton3
                                    type={'secondary'}
                                    size={'small'}
                                    height={38}
                                    width={184}
                                    title={'Cancel'}
                                    onClick={()=>{
                                        if (props.onClose)
                                            props.onClose(currentValue)
                                    }}/>
                            </span>
                            <EmptySpan width={18}/>
                            <span>
                                <AJButton3
                                    // type={'secondary'}
                                    size={'small'}
                                    height={38}
                                    width={184}
                                    title={'Save111'}
                                    titleStyle={{'color': 'white'}}
                                    onClick={()=>{
                                        if (onSave) {
                                            onSave(currentValue)
                                        }
                                    }}/>
                            </span>

                        </div>
                    </div>

                </AJCard>
            </div>

        </AJModalV2>
    )
}

function ValuesContainer(props) {
    const {editingKey, onChangeValue} = props
    const exclude = props.exclude || []
    const leftValues = editingKey.values
    const leftValueArray = formatValues(editingKey.layout, leftValues)
    const [currentValue, setCurrentValue] = useState(props.currentValue)
    if (!editingKey) {
        return null
    }
    return (
        <div style={{paddingLeft: 30, paddingRight:30, textAlign: 'center'}}>
            <div className={'value-changing-value-title'}>
                {editingKey.cardTitle ? editingKey.cardTitle : 'Pick your most important values'}
            </div>
            <EmptyLine height={34}/>
            <Row type={'flex'} align={'middle'} style={{height: 140}}>
                <div style={{textAlign: 'center', width: '100%'}}>
                    {
                        leftValueArray.map((vs, index)=>{
                            return (
                                <div key={index}>
                                    {
                                        vs.map((v, i) => {
                                            return (
                                                <div key={i} style={{paddingRight: '14px', paddingBottom: '24px', display: 'inline-block'}}>
                                                    <ValueRender
                                                        key={index}
                                                        value={v.value}
                                                        data={v}
                                                        exclude={exclude}
                                                        currentValue={currentValue}
                                                        type={editingKey.type}
                                                        selectedValue={currentValue}
                                                        onChange={(valueNew)=>{
                                                            setCurrentValue(valueNew)
                                                            if (onChangeValue) {
                                                                onChangeValue(valueNew)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    {/*<div style={{paddingLeft: editingKey.hPadding, paddingRight: editingKey.hPadding}}>*/}
                    {/*    {*/}
                    {/*        // editingKey.values.map((value, index) => {*/}
                    {/*        //     return (*/}
                    {/*        //         arrayContains(exclude, value.value) ?*/}
                    {/*        //             null*/}
                    {/*        //             :*/}
                    {/*        //         <div style={{paddingRight: '14px', paddingBottom: '24px', display: 'inline-block'}}>*/}
                    {/*        //             <ValueRender*/}
                    {/*        //                 key={index}*/}
                    {/*        //                 value={value.value}*/}
                    {/*        //                 data={value}*/}
                    {/*        //                 exclude={exclude}*/}
                    {/*        //                 currentValue={currentValue}*/}
                    {/*        //                 type={editingKey.type}*/}
                    {/*        //                 selectedValue={currentValue}*/}
                    {/*        //                 onChange={(valueNew)=>{*/}
                    {/*        //                     setCurrentValue(valueNew)*/}
                    {/*        //                     if (onChangeValue) {*/}
                    {/*        //                         onChangeValue(valueNew)*/}
                    {/*        //                     }*/}
                    {/*        //                 }}*/}
                    {/*        //             />*/}
                    {/*        //         </div>*/}
                    {/*        //     )*/}
                    {/*        // })*/}
                    {/*    }*/}
                    {/*</div>*/}
                </div>

            </Row>




        </div>


    )
}

function ValueRender(props) {
    const {type, value} = props

    let disabled = false
    if (arrayContains(props.exclude, value)) {
        console.log(`exclude ${value} `)
        disabled = true
    }

    if (type !== 'icon') {
        return <ValueTag {...props} disabled={disabled}/>
    }
    else {
        return <ValueIcon {...props} disabled={disabled}/>
    }
}

function ValueTag(props) {
    const {value, currentValue, onChange, disabled} = props

    let selectedClass = value === currentValue ?
        'value-changing-value-tag value-changing-value-tag-selected' : "value-changing-value-tag"
    if (disabled) {
        selectedClass = 'value-changing-value-tag-disabled'
    }
    return (
        <span className={`${selectedClass}`}
        onClick={()=>{
            if (disabled) {

            }
            else {
                if (onChange) {
                    onChange(value)
                }
            }
        }}>
            {valuePatch(value)}
        </span>
    )
}

function ValueIcon(props) {
    const {value, currentValue, onChange, data, disabled} = props
    const selected = value === currentValue
    let className = selected ? 'value-changing-value-icon-selected' : "value-changing-icon-tag"
    if (disabled) {
        className = "value-changing-icon-tag-disable"
    }
    return (
        <Row type={'flex'} align={'middle'} justify="center" className={'value-changing-icon-container'}>
            <Col>
                <div className={className}
                     onClick={()=>{
                         if (onChange) {
                             onChange(value)
                         }
                     }}>
                    <img src={`/img/value/${data.image}.svg`}/>
                </div>
            </Col>
            <div className={'value-changing-value-icon-checked'} hidden={!selected}>
                <Row type={'flex'} align={'middle'} justify="center" style={{height: '100%'}}>
                    <Col>
                        <img src={"/img/selectable-icon/selected.svg"}/>
                    </Col>
                </Row>
            </div>
        </Row>
    )
}
