import React from 'react'
import "./AJCard.less"
import {Icon} from "antd";
import SvgClose from "../icons/Close";
import {useHistory} from 'react-router'



// TODO
export  default function AJCard(props) {

    let {width, height, type, hideClose, onClose, customStyle, borderRadius} = props

    let history = useHistory();

    type = type || 'narrow'

    width = width || 270

    if (height === 'infinity') {
        height = null
    }
    else {
        height = height || 290
    }

    hideClose = hideClose || false

    onClose = onClose || function (e) { console.log("go back!"); history.goBack()}
    const className = `ajcard-container-${type}`

    customStyle = customStyle || {}

    if (height) {
        customStyle = {
            ...customStyle,
            height
        }
    }

    if (width) {
        customStyle = {
            ...customStyle,
            width
        }
    }

    if (borderRadius) {
        customStyle = {
            ...customStyle,
            borderRadius
        }
    }

    let closeComponent = (
        <div className={'ajcard-close'} onClick={onClose} hidden={hideClose}>
            <SvgClose height={32} width={32} fill={'#DEDEDE'}/>
        </div>)

    return (
        <div className={'ajcard'}>
            <div style={customStyle} className={className}>
                {hideClose ?  null : closeComponent}
                {props.children}
            </div>
        </div>

    )

}