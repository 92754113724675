import React, {useContext, useState} from "react";
import CompanyRegisterContext from "./CompanyRegisterContent";
import CreateAccountProvider from "./CreateAccountProvider";
import PickValuesProvider from "./PickValuesProvider";
import CompanyStructureProvide from "./CompanyStructureProvider";
import DescribeValuesProvider from "./DescribeValuesProvider";
import JobOpeningsProvider from "./JobOpeningsProvider";
import SocialLinksProvider from "./SocialLinksProvider";
import CompanyStructureContext from "./CompanyStructureContext";
import PickValuesContext from "./PickValuesContext";
import DescribeValuesContext from "./DescribeValuesContext";
import JobOpeningsContext from "./JobOpeningsContext";
import SocialLinksContext from "./SocialLinksContext";
import {getCompanyRegisterData} from "../storage/AJStorage";


function CompanyRegisterProvide(props) {
    const [companyRegister, setCompanyRegister] = useState({})
    return (
        <CreateAccountProvider>
            <CompanyStructureProvide>
                <PickValuesProvider>
                    <DescribeValuesProvider>
                        <JobOpeningsProvider>
                            <SocialLinksProvider>
                                <CompanyRegisterContext.Provider value={{
                                    companyRegister: companyRegister,
                                    updateCompanyRegister : (key, value) => {
                                        companyRegister[key] = value
                                        setCompanyRegister(companyRegister)
                                    },
                                    clearCompanyRegister: () => {
                                        setCompanyRegister({})
                                    },
                                    saveCompanyRegister: () => {
                                        // localStorage.airjobbSavedCompanyRegister = JSON.stringify(companyRegister)
                                    },
                                    getSavedCompanyRegister: () => {
                                        return getCompanyRegisterData()
                                    }
                                }}>
                                    {props.children}
                                </CompanyRegisterContext.Provider>
                            </SocialLinksProvider>
                        </JobOpeningsProvider>
                    </DescribeValuesProvider>
                </PickValuesProvider>
            </CompanyStructureProvide>

        </CreateAccountProvider>

    )
}

export default CompanyRegisterProvide