import React from "react";
import EmptySpan from "../ui/EmptySpan";
import SvgLinkedin from "../icons/Linkedin";
import SvgFacebook from "../icons/Facebook";
import SvgTwitter from "../icons/Twitter";
import SvgMedium from "../icons/Medium";
import SvgYoutube from "../icons/Youtube";
import SvgInstagram from "../icons/Instagram";
import {arrayContains} from "../utils/arrayUtil";
import {Col, Row} from "antd";
import "./CompanyIconLinks.less";

function SocialIcon_(props) {
    const {social} = props
    switch (social.toLowerCase()) {
        case 'linkedin':
            return <SvgLinkedin {...props}/>
        case 'facebook':
            return <SvgFacebook {...props}/>
        case 'twitter':
            return <SvgTwitter {...props}/>
        case 'instagram':
            return <SvgInstagram {...props}/>
        case 'medium':
            return <SvgMedium {...props}/>
        case 'youtube':
            return <SvgYoutube {...props}/>
        default:
            return null
    }
}

export default function SocialMediaIcons(props) {
    let {space, height, width, color, onClick, socials, version=1, section, twitterStyle} = props
    color = color || '#fff'
    space = space || 24
    height = height || 24
    width = width || 24
    onClick = onClick || ((company) => {
        const link = socials[company]
        if (link && link.startsWith('http')) {
            if (section) addGA(company, section);
            window.open(socials[company])
        }
    })

    socials = socials || {
        linkedin : '',
        facebook : '',
        twitter : '',
        instagram: '',
        medium : '',
        youtube: ''
    }

    const contains = (key) => {
        return socials.hasOwnProperty(key) && socials[key] != null
    }

    return (
        <div className={'company-icon-links'}>

            <span hidden={!contains('linkedin')}>
                <SvgLinkedin height={height} width={width} fill={color} style={{
                    cursor: 'pointer'
                }} onClick={(e) => {
                    onClick('linkedin')
                }}/>
            <EmptySpan width={space}/>
            </span>

            <span hidden={!contains('facebook')}>
            <SvgFacebook height={height} width={width} fill={color} style={{
                cursor: 'pointer'
            }} onClick={(e) => {
                onClick('facebook')
            }}/>
            <EmptySpan width={space}/>
            </span>

            <span hidden={!contains('twitter')}>
                <img src={color === 'black' ? '/img/menu/twitterBlack.svg' : '/img/menu/twitter.svg'} 
                style={{'width': '24px', 'height': '25px', 'position': 'relative', 'bottom': '8px', ...twitterStyle }}
                onClick={(e) => onClick('twitter')}></img>
            <EmptySpan width={space}/>
            </span>

            { version !== 1 ? 
            <div>
                <br />
            </div> : <></>}

            <span hidden={!contains('instagram')}>
            <SvgInstagram height={height} width={width} fill={color} style={{
                cursor: 'pointer'
            }} onClick={(e) => {
                onClick('instagram')
            }}/>
            <EmptySpan width={space}/>
            </span>

            <span hidden={!contains('medium')}>
            <SvgMedium height={height} width={width} fill={color} style={{
                cursor: 'pointer'
            }} onClick={(e) => {
                onClick('medium')
            }}/>
            <EmptySpan width={space}/>
            </span>

            <span hidden={!contains('youtube')}>
            <SvgYoutube height={height} width={width} fill={color} style={{
                cursor: 'pointer'
            }} onClick={(e) => {
                onClick('youtube')
            }}/>
            </span>

        </div>
    )
}

export function SocialMediaIconWithText(props) {
    let {space, height, width, color, onClick, socials, editing} = props
    color = color || '#fff'
    space = space || 24
    height = height || 24
    width = width || 24
    onClick = onClick || ((company) => {
        const link = socials[company]
        if (link && link.startsWith('http')) {
            window.open(socials[company])
        }
    })

    const defaultSocials =  {
        linkedin : '',
        facebook : '',
        twitter : '',
        instagram: '',
        medium : '',
        youtube: ''
    }

    socials = socials || defaultSocials

    return (
        <Row className={'company-icon-links-2'}>

            {
                Object.keys(socials).map((social, index) => {
                    if (!arrayContains(Object.keys(defaultSocials), social)) {
                        return null
                    }

                    if (!editing && !(socials[social] && socials[social].startsWith('http'))) {
                        return <></>
                    }

                    return (<Col span={8} style={{
                        cursor: 'pointer', paddingBottom: index > 2 ? 0 : 24
                    }} className={'social-link-icon'} onClick={(e) => {
                        onClick(social)
                    }}>
                        <SocialIcon_ social={social} height={height} width={width} fill={color} scheme={'black'} />
                        <EmptySpan width={8}/>
                        <span className={'company-icon-text'}>{social.substring(0, 1).toUpperCase() + social.substring(1)}</span>
                    <EmptySpan width={space}/>
                    </Col>)
                })
            }

        </Row>
    )
}

function addGA(socialLinkName, sectionName) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'custom_event',
        'eventCategory': 'social_clicks',
        'eventAction': `${socialLinkName}`,
        'eventlabel': `${sectionName}`
    });
}
