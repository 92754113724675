export const urlBase = process.env.REACT_APP_BASE_URL ? `${process.env.REACT_APP_BASE_URL}` : ``
export const imageUrlBase = `${urlBase}`
export const apiUrlBase = `${urlBase}/api`

export function AJUrl(path) {
    return `${urlBase}/${path}`
}

export function AJApiUrl(path) {
    return `${apiUrlBase}/${path}`
}


export function AJImageUrl(path) {
    if (!path || path.length === 0) {
        return null
    }
    return `${urlBase}${path}`
}

export const uploadPath = AJApiUrl("uploadfile/")
export const loginPath = AJApiUrl("login/")
export const creationPath = AJApiUrl("rest-auth/registration/")

export const jobOpeningsExtractPath = AJApiUrl("jobextract")

export const companyRegisterPath = AJApiUrl('companyPost/')

export const companyProfilePath = AJApiUrl('companyPost/')

export const companyProfileUpdatePath = AJApiUrl('companyprofileupdate/')

export const checkTokenPath = AJApiUrl('checkToken/')

export const talentValueMatchPath = AJApiUrl('talentvaluematch/')

export const favoritePath = AJApiUrl('favorite/')


export const sendCommunityMailPath = AJApiUrl('communityemail/')

export const getMediumNewsPath = AJApiUrl('getmediumnews')

export const resetEmailPasswordPath = AJApiUrl('resetpassword/')

export const changeEmailPasswordPath = AJApiUrl('rest-auth/password/change/')

export const saveClapCountPath = AJApiUrl('clap/')

export const sendNFTEmailPath = AJApiUrl('sendNTFEmail')

export const jobOpeningVisitorPath = AJApiUrl('jobopening/visitor')

export const getInterviewQuestionsPath = AJApiUrl('getInterviewQuestions')

export const sendPriceRequestPath = AJApiUrl('price-request')

export const getJobVisitorsPath = AJApiUrl('all-visitors')
export const getAllOpenJobsPath = AJApiUrl('all-openjobs')
export const getAllCompaniesPath = AJApiUrl('all-companies')