import React, {useState} from "react";
import "./JobOpenings2.less";
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import JobCard from "./JobCard";
import {Col, Row} from "antd";


export default function JobOpenings2(props) {
    const [value, setValue] = useState(0)
    const {data, isCustomer, company} = props

    if (!data || data.length === 0) {
        return (
            <Row type={'flex'} align={'middle'} className={'job-openings2'}>
                <Col span={24}>
                    <div className={'job-openings-empty-info-container'}>
                        <Row type={'flex'} align={'middle'} style={{height: '100%'}}>
                            <Col span={24}>
                                <div className={'job-openings-empty-info'}>
                                    {isCustomer ? <div style={{color: '#181414'}}>Let top talents know your job openings.<br/> Link & sync them easily.</div> :
                                        <div>We will soon hire amazing people like you.<br/> Follow us and learn more about our values.</div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }

    let jobOpenings = [...data]

    const onChange = (value) => {
        setValue(value)
    }

    let cardWidth = props.cardWidth || (267)
    const windowWidth = window.innerWidth
    let distance = 0
    let slidesPerPage = 0
    while (distance < 20) {
        slidesPerPage = Math.floor(windowWidth/cardWidth)
        distance = (windowWidth - slidesPerPage * cardWidth) / slidesPerPage
        cardWidth += 5
    }

    const sliderPerDot = slidesPerPage - 1 || 1
    const showDot = jobOpenings.length > sliderPerDot

    const jobOpeningsSlides = jobOpenings.map((jobOpening, index) => {
        if (!jobOpening) {
            return <div style={{opacity: 0}}>
                <JobCard/>
            </div>
        }

        return (
            <JobCard
                title={jobOpening.title || 'Job Title'}
                href={jobOpening.url}
                company={company}
                description={[jobOpening.location || 'Location',
                    'Full time Remote']}/>
        )
    })

    return (
        <>
        <div className={'job-openings2'}>
            <Carousel
                value={value}
                slides={jobOpeningsSlides}
                onChange={onChange}
                slidesPerPage={slidesPerPage}
                keepDirectionWhenDragging
            />
        </div>
            <div className={'job-openings2-dots'} hidden={!showDot}>
                <Dots value={Math.ceil(value/sliderPerDot)}
                      onChange={(value) => onChange(value*sliderPerDot)}
                      number={Math.ceil(jobOpenings.length/sliderPerDot)} />
            </div>
        </>
    );
}
