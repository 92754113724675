import React from 'react';
import EmptyLine from "../ui/EmptyLine";
import "./BlogCard.less";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import DynamicRatioImage from './dynamicRatioImage/dynamicRatioImage';

export function formatBlogCard(cards) {
    return (
        <div className='all-blogs'>
            {
                cards && cards.map((article, index1, index2) => {
                    return article.type === 'skeleton' ? 
                    <div key={`${index1} - ${index2}`} className='empty-blog'>
                        <BlogCardSkeleton index={`${index1} - ${index2}`}/>
                        <EmptyLine height={160}></EmptyLine>
                    </div> : 
                    <div className="blog" onClick={() => window.open(`/blog/${article.slug}`)}>
                        <div className="blog-img">
                            <DynamicRatioImage imageUrl={article.image} />
                        </div>

                        <div className="blog-metadata-date">
                            {article?.createdAt && formatDateTime(article?.createdAt)}
                        </div>

                        <div className="blog-title">{article.headline}</div>

                        <div className="blog-metaDesc">{article.metaDescription ? article.metaDescription : '  '}</div>

                        <EmptyLine height={160} />
                    </div>
                })
            }
        </div>
    );
}

export function BlogCardSkeleton({index}) {
    return <div key={index} className={'empty-blog-card'}>
        <Skeleton width={'100%'} height={150} />
        <EmptyLine height={17} />
        <Skeleton width={60} />
        <EmptyLine height={17} />
        <Skeleton width={'100%'} height={23} />
        <EmptyLine height={17} />
        <Skeleton width={'100%'} height={46} />
    </div>
}

export function formatDate(isoString) {
    const date = new Date(isoString);
    
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    
    return `${month} ${day}, ${year}`;
}

export function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
