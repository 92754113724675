import React, {useState} from "react";
import "./InsightsPage.less";
import {client} from "../http/AJHttp";
import { Menu, Dropdown } from 'antd';
import AJLoading from "../ui/AJLoading";
import StartFooter from "../component/start/StartFooter";
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton } from "react-share";
import SvgFacebook from '../icons/Facebook';
import SvgLinkedin from '../icons/Linkedin';
import InfiniteScroll from 'react-infinite-scroller';
import _ from "lodash";
import {message} from "antd";
import { formatBlogCard } from "../component/BlogCard";

export default function InsightsPage(props) {
    return (
        <>      
            <div className={'insights-page-container'}>

                <div className="page-title-container">
                    <div className="main-title">Media & News</div>
                    <div className="sub-title">Stay ahead of the curve with the latest insights on attractive engineering values, sustainability & impact tech.</div>
                </div>

                <div className="all-blogs-container">
                    <InfiniteScrollBlogs />
                </div>

            </div>
            <StartFooter/>
        </>
    )
}

export function InfiniteScrollBlogs(props) {
    let {ignoreBlogSlug} = props;
    ignoreBlogSlug = ignoreBlogSlug || null;

    const [currentPage, setCurrentPage] = useState(0); // PS: keep the page index consistent with getAtricles API
    const [hasMore, setHasMore] = useState(true);
    let [isFetching, setIsFetching] = useState(false);
    let [blogCard, setBlogCard] = useState([]);
    
    const fetchBlogsData = async (page, numberPerPage) => {
        if (isFetching || !hasMore) {
            console.log("skip fetch blogs...");
            return;
        }

        setIsFetching(true);

        const skeletons = _.times(6, () => {
            return {type : 'skeleton'};
        });
        setBlogCard([...blogCard, ...skeletons]);

        try {
            const response = await client.getArticles(page, 6);

            if(response?.articles) {
                if((page+1)*numberPerPage>=response.total) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
                
                if (ignoreBlogSlug) {
                    const tmp = response.articles.filter(blog => blog.slug !== ignoreBlogSlug);
                    setBlogCard([...blogCard, ...tmp]);
                } else {
                    setBlogCard([...blogCard, ...response.articles]);
                }
                setCurrentPage(page => page+1); 
            } else {
                throw('fetch blogs failed');
            }
        } catch(e) {
            setHasMore(false);
            message.error("fetch blogs failed");
        } finally {
            setIsFetching(false);
        }
    }
    
    return (
        <InfiniteScroll
            key={currentPage}
            pageStart={0}
            loadMore={async () => {
                await fetchBlogsData(currentPage, 6);
            }}
            hasMore={hasMore}
            loader={<AJLoading size={50}/>}
            threshold={200}
        >
            {formatBlogCard(blogCard, 3, 32)}
        </InfiniteScroll>
    );
}

export function removeH1Content(str) {
    const regex = /<h1.*?>.*?<\/h1>/s;
    return str.replace(regex, '');
}

export function SocialShare(props) {
    let {article} = props;

    const menu = (
        <Menu>
          <Menu.Item>
            <div className="share-menu twitter-share">
                <TwitterShareButton
                    url={`https://genvalues.com/blog/${article.slug}`}
                    title={`${article.headline}`}
                    className="twitter-share-button"
                >
                    <img src={'/img/menu/twitterBlack.svg'}></img>
                    <span className="share-title">Share on X</span>
                </TwitterShareButton>
             </div>
          </Menu.Item>
          <Menu.Item>
            <div className="share-menu">
                <FacebookShareButton
                    url={`https://genvalues.com/blog/${article.slug}`}
                    title={`${article.headline}`}
                    className="facebook-share-button"
                >
                    <SvgFacebook height={18} width={18} fill={'black'}/>
                    <span className="share-title">Share on Facebook</span>
                </FacebookShareButton>
             </div>
          </Menu.Item>
          <Menu.Item>
            <div className="share-menu">
                <LinkedinShareButton
                    url={`https://genvalues.com/blog/${article.slug}`}
                    title={`${article.headline}`}
                    className="linkedin-share-button"
                >
                    <SvgLinkedin height={18} width={18} fill={'black'}/>
                    <span className="share-title">Share on LinkedIn</span>
                </LinkedinShareButton>
             </div>
          </Menu.Item>
        </Menu>
    );
    
    return (
        <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
            <img src={`/img/icons/share.svg`} width={22}></img>
        </Dropdown>
    );
}
