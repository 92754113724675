import React from "react";

function SvgRightIcon(props) {
    let {state=1} = props;

    switch(state) {
        case 0:
            return null;
        case 1: // normal
            return (
                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17" cy="17" r="16.25" stroke="black" stroke-width="1.5"/>
                    <path d="M15 10L21.3201 15.5183C21.5331 15.7001 21.7043 15.9269 21.8217 16.1829C21.9392 16.4388 22 16.7177 22 17C22 17.2823 21.9392 17.5612 21.8217 17.8171C21.7043 18.0731 21.5331 18.2999 21.3201 18.4817L15 24" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
            );
        case 2: // click
            return (
                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17" cy="17" r="16.25" fill="black" stroke="black" stroke-width="1.5"/>
                    <path d="M15 10L21.3201 15.5183C21.5331 15.7001 21.7043 15.9269 21.8217 16.1829C21.9392 16.4388 22 16.7177 22 17C22 17.2823 21.9392 17.5612 21.8217 17.8171C21.7043 18.0731 21.5331 18.2999 21.3201 18.4817L15 24" stroke="white" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
            );
    }
}

export default SvgRightIcon;
