import React, {useState} from "react";

import "./StartFooter.css"
import {Row, Col} from "antd";
import EmptyLine from "../../ui/EmptyLine";
import Contact from "../Contact";
import AJModalV2 from "../../ui/AJModalV2";
import SocialMediaIcons from "../SocialMediaIcons";
import {useHistory} from 'react-router'
import {socialLinkConfig} from "../Menu";
import AJLogo from "../../ui/AJLogo";
import {clearLoginInfo, isLogged} from "../../storage/AJStorage";


export default function StartFooter() {
    const [showContact, setShowContact] = useState(false)
    let history = useHistory()

    const companyLogout = (event) => {
        // TODO
        // logout
        clearLoginInfo()

        history.push("/")
    }

    return (
        <div className={'start-footer'} id={'aj-start-footer'}>
            <div className={'start-footer-container'}>
                <Row>
                    <Col span={5}>
                        <div className={'start-footer-title'} onClick={() => {

                            history.push('/')
                        }}>
                            <AJLogo/>
                        </div>
                    </Col>
                    <Col span={14}>
                        <div className={'start-footer-menu'}>
                            <Row>
                                <Col span={8}>
                                    <EmptyLine height={12}/>
                                    {
                                        isLogged() ? <div className={'start-footer-clickable'} onClick={companyLogout}>
                                        Employer sign out
                                        </div>

                                            : <div className={'start-footer-clickable'} onClick={()=>{
                                                history.push("/register")
                                            }}>
                                                Employer sign in
                                            </div>
                                    }

                                    <br/>
                                    <div className={'start-footer-clickable'}
                                         onClick={() => {
                                            addGA('Contact', '/contact');
                                            gotoLink("/contact", history)
                                         }}
                                    >
                                        Contact
                                    </div>
                                    <AJModalV2 visible={showContact}
                                               width={569}
                                               top={200}>
                                        <Contact onClose={()=> setShowContact(false)}/>
                                    </AJModalV2>
                                    <br/>

                                    <div className={'start-footer-clickable'} onClick={()=>{
                                        addGA('About us', '/about');
                                        gotoLink('/about', history)
                                    }}>
                                        About us
                                    </div>
                                    <br/>

                                    <div className={'start-footer-clickable'} onClick={()=>{
                                        addGA('News & Media', '/latest');
                                        gotoLink('/latest', history)
                                    }}>
                                        News & Media
                                    </div>
                                    <br/>

                                </Col>
                                <Col span={8}>
                                    <EmptyLine height={12}/>
                                    {/*<div className={'start-footer-clickable'}*/}
                                    {/*     onClick={() => {*/}
                                    {/*         gotoLink('/nft', history)*/}
                                    {/*     }}*/}
                                    {/*>*/}
                                    {/*    NFT*/}
                                    {/*</div>*/}
                                    {/*<br/>*/}
                                    <div className={'start-footer-clickable'}
                                         onClick={() => {
                                            addGA('Talent', '/talent');
                                            gotoLink('/talent', history)
                                         }}
                                    >
                                        Talent
                                    </div>
                                    <br/>
                                    <div className={'start-footer-clickable'}
                                         onClick={() => {
                                            addGA('Employer', '/employer');
                                            gotoLink('/employer', history)
                                         }}
                                    >
                                        Employer
                                    </div>
                                    <br/>
                                    <div className={'start-footer-clickable'} onClick={() => {
                                        addGA('True Fit', '/toolbox');
                                        gotoLink('/toolbox', history)
                                    }}>
                                        True Fit
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <EmptyLine height={12}/>
                                    <div className={'start-footer-clickable'}
                                         onClick={() => {
                                            addGA('Pricing', '/pricing');
                                            gotoLink('/pricing', history)
                                         }}
                                    >
                                        Pricing
                                    </div>
                                    <br/>

                                    <div className={'start-footer-clickable'} 
                                        onClick={() => {
                                            addGA('Privacy', '/privacy');
                                            gotoLink('/privacy', history)
                                    }}>
                                        Privacy
                                    </div>

                                    <br/>
                                    <div className={'start-footer-clickable'}
                                         onClick={() => {
                                            addGA('Contact', '/terms-conditions');
                                            gotoLink('/terms-conditions', history)
                                         }}
                                    >
                                        Terms of use
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={5}>
                        <div style={{width:270}}>
                            <EmptyLine height={12}/>
                            <div>
                                <SocialMediaIcons socials={socialLinkConfig} section={'footer'}/>
                            </div>
                            <EmptyLine height={106}/>
                            <div className={'start-footer-company'}>
                                Copyright 2024
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
        </div>
    )
}

export function gotoLink(link, history) {
    if (history.location.pathname === link) {

    }
    else {
        history.push(link)
    }
}

function addGA(eventAction, pageUrl) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'custom_event',
        'eventCategory': 'footer_menu',
        'eventAction': `${eventAction}`,
        'eventlabel': `${pageUrl}`
    });
}
