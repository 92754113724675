import React, {useState} from "react";
import { Popover } from 'antd'

import "./AJPopover.less"

export default function AJPopover(props) {
    const {content, width, scheme, placement} = props

    let containerClass = props.containerClass || 'ajpopover-container'

    const [visible, setVisible] = useState(false)

    const contentDom = (
        <div className={'ajpopover'} style={{width}}>
            <div className={containerClass}>
                {content}
            </div>
        </div>
    )

    const overlayClassName = scheme ? `ajpopover-overlay-${scheme}` : 'ajpopover-overlay'

    if (!content || content.length === 0) {
        return null
    }

    return (
        <Popover content={contentDom}
                 placement={placement}
                 mouseEnterDelay={props.mouseEnterDelay || 0.5}
                overlayClassName={overlayClassName}>
            {/*<div style={{...customStyle}}>*/}
            {props.children}
            {/*</div>*/}
        </Popover>
    )

}

export function AJPopover2(props) {
    const {content, width, scheme, placement} = props

    let containerClass = props.containerClass || 'ajpopover-container'

    const contentDom = (
        <div className={'ajpopover'} style={{width}}>
            <div className={containerClass}>
                {content}
            </div>
        </div>
    )

    const overlayClassName = scheme ? `ajpopover-overlay-${scheme}` : 'ajpopover-overlay2'

    if (!content || content.length === 0) {
        return null
    }

    return (
        <Popover content={contentDom}
                 visible={true}
                 placement={placement}
                 overlayClassName={overlayClassName}>
            {/*<div style={{...customStyle}}>*/}
            {props.children}
            {/*</div>*/}
        </Popover>
    )

}

export function AJClickPopover(props) {
    const {content, width, scheme, placement} = props

    let containerClass = props.containerClass || 'ajpopover-container'

    const [visible, setVisible] = useState(true)

    const contentDom = (
        <div className={'ajpopover'} style={{width}}>
            <div className={containerClass}>
                {content}
            </div>
        </div>
    )

    const overlayClassName = scheme ? `ajpopover-overlay-${scheme}` : 'ajpopover-overlay'

    if (!content || content.length === 0) {
        return null
    }

    return (
        <Popover content={contentDom}
                 placement={placement}
                 trigger="click"
                 visible={props.visible}
                 onVisibleChange={props.onVisibleChange}
                 overlayClassName={overlayClassName}>
            {/*<div style={{...customStyle}}>*/}
            {props.children}
            {/*</div>*/}
        </Popover>
    )

}
