import React, {useContext} from "react";
import ValueModals from "./ValueModals";
import PickValuesContext from "../../context/PickValuesContext";

export default function PickValueModals(props) {

    const {updateValues, leftValueCount, containsValue, pickedValues} = useContext(PickValuesContext)

    return ValueModals({
        ...props,
        updateFun : updateValues,
        containsFun: containsValue,
        leftNumberFun: leftValueCount,
        selectedValues: pickedValues(),
        hiddenFooter : false
    })

}
