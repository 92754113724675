import React from 'react';
import ReactDOM from 'react-dom';
import "./fonts/SF-Pro/SF-Pro-Display-Semibold.otf"
import './index.less';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { getFavorite } from "./http/AJHttp";
import { getAJNoLoginUUID, setAJNoLoginUUID, clearAJNoLoginUUID, isLogged } from "./storage/AJStorage";

async function appInit () {
    if (!isLogged()) {
        // walkaround: we find some apis are bad with the uuid
        if (getAJNoLoginUUID()) {
            const response = await getFavorite();
            if (response && response.data && Array.isArray(response.data)) {
                console.log("uuid right here");
            } else {
                clearAJNoLoginUUID();
                setAJNoLoginUUID();
            }
        } else {
            setAJNoLoginUUID();
        }
    }

    ReactDOM.render(<App />, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}

appInit();
