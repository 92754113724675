import React from "react";

function SvgYoutube(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 33 23" fill="none" {...props}>
      <path
        d="M32.168 3.855c-.352-1.406-1.465-2.52-2.813-2.87C26.835.28 16.875.28 16.875.28s-10.02 0-12.54.703c-1.347.352-2.46 1.465-2.812 2.871C.82 6.316.82 11.59.82 11.59s0 5.215.703 7.734c.352 1.406 1.465 2.461 2.813 2.813 2.52.644 12.539.644 12.539.644s9.96 0 12.48-.644c1.348-.352 2.461-1.407 2.813-2.813.703-2.52.703-7.734.703-7.734s0-5.274-.703-7.735zm-18.574 12.48V6.845l8.32 4.746-8.32 4.746z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgYoutube;
