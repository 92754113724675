import React from "react";
import EmptyLine from "../ui/EmptyLine";
import "./PageWrapper.less";
import AJVideo from "../ui/AJVideo";

function PageWrapper(props) {
    const {space, backgroundImage, image, mediaHeight, videos, id, content} = props

    const body = document.getElementsByTagName('body')[0]

    if (props.background) {
        body.style.background = props.background
    }
    else if (backgroundImage) {
        body.style.background = backgroundImage
    }
    else {
        body.style.background = 'transparent'
    }
    // document.getElementsByTagName('body')[0].style['background-size'] = '100% 100%'

    let customStyle = props.customStyle || {}

    let demoVideo = videos ? (
        <video width={'100%'} muted autoPlay={true} loop={true} id={"aj-background-video"}>
            <source src={videos[0]} type="video/mp4"/>
        </video>
        // <AJVideo sources={videos}/>
    ) : null

    return (
        <div>
            <div style={{position: 'absolute', width: "100%", minWidth: 1440, zIndex: 0}}>
                {
                    content ? content :
                        (image ?
                            <img src={image} width={'100%'} height={mediaHeight} id={props.id}/>
                        : (videos ? demoVideo : null))
                }
            </div>
            <div style={{...customStyle}}>
                <EmptyLine height={space || 0}/>
                {props.children}
            </div>
        </div>
    )
}

export default PageWrapper
