import React from "react";
import "./CompanyProfileTechStack.less"
import EmptyLine from "../../ui/EmptyLine";
import {EditIcon} from "../../page/CompanyProfile2";
import {Input} from "antd";
import TechStackEditing, {TechStack, TechStack2} from "../tech-stack/TechStackEditing";

const {TextArea} = Input

export default function CompanyProfileTechStack(props) {

    const {editing, profileData} = props

    let techStack = profileData.companyStructure.technologies || {}
    if (typeof techStack === 'string') {
        techStack = {
            freeText: techStack,
            selected: []
        }
    }
    techStack.selected = techStack.selected || []
    techStack.freeText = techStack.freeText || ''

    return (
        <div>
            <div style={{paddingTop: '8px', width: '240px', whiteSpace: 'pre-line'}}>
                <div className={'company-profile2-tech-stack-title'}>
                    {editing? <EditIcon/> : null}
                    Technology
                </div>
                <EmptyLine height={16}/>
                {
                    editing ?
                        <TechStackEditing
                            selected={techStack.selected} text={techStack.freeText}
                            onChange={(newTechStack => {
                                profileData.companyStructure.technologies = newTechStack
                            })}/>
                        : <TechStack selected={techStack.selected} text={techStack.freeText}/>
                }

            </div>
        </div>
    )

}


export function CompanyProfileTechStack2(props) {
    const {profileData} = props

    let techStack = profileData.companyStructure.technologies || {}
    if (typeof techStack === 'string') {
        techStack = {
            freeText: techStack,
            selected: []
        }
    }
    techStack.selected = techStack.selected || []
    techStack.freeText = techStack.freeText || ''

    return (
        <div>
            <TechStack selected={techStack.selected} text={techStack.freeText} className={'tech-stack-editing2'} span={3}/>
        </div>
    )
}