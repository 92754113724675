const companyConfig = {
    'Truecaller' : {
        title: 'Truecaller Engineering Culture and Values⎮Genvalues',
        description: 'Engineers at Truecaller describe their values, culture, open jobs and what matters most to them.'
    },
    'Omocom' : {
        title: 'Omocom Engineering Culture and Values⎮Genvalues',
        description: 'Engineers at Omocom describe their values, culture, open jobs and how they contribute to SDG (sustainability development goals).'
    },
    'Working Group Two': {
        title: 'Working Group Two Engineering Culture and Values⎮Genvalues',
        description: 'Engineers at WGT describe their values, culture, open jobs and what it is like to be an engineer at working group two.'
    }
}

export default companyConfig
