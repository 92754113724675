import React from "react";

function SvgMenuIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 20" fill="none" {...props}>
      <path fill={props.fill} d="M0 0h24v4H0zM4 8h20v4H4zM0 16h24v4H0z" />
    </svg>
  );
}

export default SvgMenuIcon;

