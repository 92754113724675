import { configureStore } from '@reduxjs/toolkit';

import start from './slices/start';

export default configureStore({
    reducer: {
        start
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});