import React from "react";

function SvgFavorite(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill={props.boardFill} {...props}>
      <path
        d="M12 21.175l-1.45-1.32C5.4 15.185 2 12.105 2 8.325c0-3.08 2.42-5.5 5.5-5.5 1.74 0 3.41.81 4.5 2.09 1.09-1.28 2.76-2.09 4.5-2.09 3.08 0 5.5 2.42 5.5 5.5 0 3.78-3.4 6.86-8.55 11.54L12 21.175z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFavorite;
