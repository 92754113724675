import React from "react";
import "./CEOReviewCardContainer.less";
import CEOReviewCard from './CEOReviewCard';
import {Row, Col} from "antd";

export default function CEOReviewCardContainer() {
    return (
        <div className={'ceo-description-container'}>
            <div className={'ceo-title'}>What CEOs are saying</div>
            <div>
                <Row justify={'center'} style={{padding: '0 32px'}} align={'stretch'} type={'flex'}>
                    <Col span={8}>
                        <CEOReviewCard
                            avatar={'/img/home/CHARLOTTE.png'}
                            introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                                <span style={{fontWeight: 500}}>CHARLOTTE</span><br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>Co-Founder & CEO</span> <br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>of Teemyco</span>
                            </div>}
                            comment={<>"I love what Genvalues is doing. It’s
                                democratizing hiring and helping
                                teams attract talents based in team
                                values and pushing them to put light
                                on culture. We have received a lot of
                                hoc applications to Teemyco since we
                                got a Genvalues profile."</>}/>
                    </Col>
                    <Col span={8}>
                        <CEOReviewCard
                            avatar={'/img/home/STEFAN.png'}
                            introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                                <span style={{fontWeight: 500}}>STEFAN</span><br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>Founder & CEO of</span> <br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>CAKE</span>
                            </div>}
                            comment={<>
                                "We will definitely speed up our process in terms of identifying and hiring talent. Being able to scan the market with the ability of embracing specific values and directions should promote our ability to be much more efficient in terms of finding the talents."
                            </>}/>
                    </Col>
                    <Col span={8}>
                        <CEOReviewCard
                            avatar={'/img/home/elin.png'}
                            introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                                <span style={{fontWeight: 500}}>Elin</span><br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>Co-founder &</span> <br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>COO of</span> <br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>Shortcut</span> <br/>
                            </div>}
                            comment={<>
                                "It's always fun when you're in conversation with an Engineering candidate interviewing to join Shortcut, and they mention that they've read about our team on Genvalues that’s why they applied to the job."
                            </>}/>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export function TalentCardContainer() {
    return (
        <div className={'ceo-description-container'}>
            <div className={'ceo-title'}>What talents are saying</div>
            <div>
                <Row justify={'center'} style={{padding: '0 32px'}} align={'stretch'} type={'flex'}>
                    <Col span={8}>
                        <CEOReviewCard
                            avatar={'/img/home/talentOne.svg'}
                            introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                                <span style={{fontWeight: 500}}>Alexander</span><br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>Engineer</span>
                            </div>}
                            comment={<>"Genvalues has transformed my job search. Instead of just chasing titles, they match me with engineering teams that share my values, like sustainability and work principles. It's like searching for a life partner, not just a job."</>}/>
                    </Col>
                    <Col span={8}>
                        <CEOReviewCard
                            avatar={'/img/home/talentTwo.svg'}
                            introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                                <span style={{fontWeight: 500}}>James</span><br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>Engineer &</span> <br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>Product Leader</span>
                            </div>}
                            comment={<>
                                "If you are an engineer seeking a career with a huge impact, then Genvalues is the platform you need to help you find the right company."
                            </>}/>
                    </Col>
                    <Col span={8}>
                        <CEOReviewCard
                            avatar={'/img/home/talentThree.svg'}
                            introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                                <span style={{fontWeight: 500}}>Smriti</span><br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>LinkedIn Top</span> <br/>
                                <span style={{fontSize: 10, fontFamily: 'Inter'}}>Voice & Engineer</span>
                            </div>}
                            comment={<>
                                "Genvalues can shape your career in the best way possible. It not only provides you a path for professional growth, but also personal growth. That’s something Genvalues has captured so well!"
                            </>}/>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
