import React from "react";
import EmptyLine from "../ui/EmptyLine";
import StartFooter from "../component/start/StartFooter";
import "./HowItWorkCompany.less"
import AJImg from "../ui/AJImg";
import CEOReviewCardContainer from "../component/ceo-review/CEOReviewCardContainer";
import {Col, Row} from "antd";
import {AJButton4} from "../ui/AJButton3";
import { isLogged } from "../storage/AJStorage";
import {gotoLink} from "../component/start/StartFooter";
import {useHistory} from 'react-router';

export default function HowItWorkCompany(props) {
    let history = useHistory();

    return (
        <div className={'how-it-work-company'}>
            <EmptyLine height={220}/>
            <div className={'company-title'}>
                Employer
            </div>
            <EmptyLine height={420}/>

            <div className={'how-it-work-company-container'}>
                <div className={'how-it-work-company-phase'}>
                    <Row type={'flex'}>
                        <Col span={3} />
                        <Col span={9}>
                            <div className={'how-it-work-company-title'}>
                                Bring your Engineering Team To Life
                            </div>
                            <br/>
                            <div className={'how-it-work-company-body'}>
                                <div>In today's fiercely competitive and innovative tech landscape, how can your company truly stand out? The answer lies in creating an exceptional candidate experience that resonates deeply with top engineering talent. This will help you become an iconic destination for the brightest engineers.</div>
                                <EmptyLine height={17}/>
                                <div>We created Genvalues to help you be the company that everyone wants to work for in your industry by beautifully presenting how your engineering teams operate according to your specific guiding principles and values.</div>
                                <EmptyLine height={17}/>
                                <div>We help you craft an authentic narrative that showcases the unique ethos of your engineering team and company mission. Imagine a world where your company is the top destination for the brightest engineers, the place where they want to change the industry.</div>

                                
                                <div style={{marginTop: '37px'}}>
                                    <b>Current platforms don't speak to Engineers</b>
                                    <div style={{marginTop: '17px'}}>They fail to highlight your engineering team's unique strengths and culture, leaving no insight into team dynamics. Cluttered job listings create confusion, diluting the focus on engineering roles. Unappealing designs fail to capture the innovative spirit of engineering teams.</div>
                                </div>

                                <div style={{marginTop: '17px'}}>
                                    <b>There is a movement</b>
                                    <div style={{marginTop: '17px'}}>There's a movement happening that goes beyond traditional recruiting. It’s about the brightest individuals seeking work that matters, companies offering opportunities aligned with core principles, and people striving for a positive impact on our world.</div>
                                </div>

                                <div style={{marginTop: '17px'}}>
                                    <b>Seamless Integration</b>
                                    <div style={{marginTop: '17px'}}>Our platform integrates simesless with tools like Teamtailor and Jobylon, to attract the very best talent without disrupting your current methods.</div>
                                </div>
                            </div>
                            <div style={{width: '100%', textAlign: 'center', marginTop: 80}}>
                                <AJButton4
                                    title={'Contact us'}
                                    size={'mini'}
                                    width={248}
                                    type={'secondary'}
                                    height={60}
                                    titleStyle={{
                                        fontSize: 20,
                                        fontWeight: 400,
                                        fontFamily: 'ManropeBold',
                                        color: '#000000'
                                    }}
                                    customStyle={{
                                        borderRadius: '90px',
                                        background: '#FFD865',
                                    }}
                                    hoverToBlack={true}
                                    titleWithWaveAnimation={true}
                                    onClick={() => {
                                        window.open("mailto:team@genvalues.com")
                                    }}/>
                            </div>
                        </Col>
                        <Col span={9}>
                            <div style={{paddingLeft:69}}>
                                <EmptyLine height={15}/>
                                <AJImg src={'/img/how-it-works/employ-pic-1-v2.png'} width={551} alt={'Why should I hire'}/>
                                <div className={'how-it-work-company-title'} style={{marginTop: '40px'}}>
                                    Why Choose<br/>Genvalues?
                                </div>
                                <br/>
                                <div className={'how-it-work-company-body'}>
                                    Joining Genvalues means more than just exposure to thousands of Engineers, UI/UX Designers, DevOps, CTOs, and Product Managers. It means becoming part of a community that values diversity, fosters strong team unity, and clarifies product direction. It's about creating bonds that drive success.
                                    <br/>
                                    
                                    <div style={{marginTop: '17px'}}>
                                        <b>Begin Your Genvalues Journey Today</b>
                                        <div style={{marginTop: '17px'}}>Are you ready to revolutionize how you attract and retain top Engineering talent, with a special emphasis on the team and product mission of making a positive impact? Visit Genvalues.com to create your profile and join a community of forward-thinking companies.</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <EmptyLine height={200}/>
                <div className={'how-it-work-company-phase'}>
                    <Row type={'flex'}>
                        <Col span={3}/>
                        <Col span={9}>
                            {/*<EmptyLine height={40}/>*/}
                            <AJImg src={'/img/how-it-works/employ-pic-2-v2.png'} width={'100%'} alt={'How do I publish a profile'}/>
                        </Col>
                        <Col span={9}>
                            <div style={{paddingLeft:70}}>
                                <div className={'how-it-work-company-title'}>
                                    Publish a profile
                                </div>
                                <br/>
                                <div className={'how-it-work-company-body'}>
                                    <ul style={{marginLeft: -16}}>
                                        <li>Log in as a new user</li>
                                        <br/>
                                        <li>Pick 9 values</li>
                                        <br/>
                                        <li>Describe these values genuinely as you receive support after login.</li>
                                        <br/>
                                        <li>Link easily your job openings to your profile with integrations to Teamtailor, Jobylon, The Hub, or manually from any website.</li>
                                        <br/>
                                        <li>Top Engineers can easily discover your profile and will apply to job openings.</li>
                                        <br/>
                                        <li>Contact us via email at <a href='mailto:team@genvalues.com' style={{'text-decoration': 'underline'}}>team@genvalues.com</a> to go faster.</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                <br/>
                </div>
                <EmptyLine height={100}/>
            </div>
            <Row>
                <Col span={3}/>
                <Col span={18}>
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <AJImg src={'/img/how-it-works/employ-we-are-friend.svg'} width={'75%'} alt={'we are friend'}/>
                    </div>
                </Col>
            </Row>
            <EmptyLine height={158}/>

            <Row>
                <Col span={3}/>
                <Col span={9}>
                    <div className={'how-it-work-company-title'}>
                        The Power of<br/>Aligning Your Engineering Team
                    </div>
                    <br/>
                    <div className={'how-it-work-company-body'} style={{marginLeft: -24}}>
                        <ul>
                            <li>
                                <b>Boosted Motivation and Commitment:</b>
                                <li style={{marginTop: 20, marginLeft: 28}}>Aligning with both your engineering principles and team values ignites a deeper sense of purpose in People. This not only attracts top-tier professionals but also smoothens the onboarding process, as they resonate with your methodologies and ethos.</li>
                            </li>
                            <br/>

                            <li>
                                <b>Enhanced Communication and Collaborative Engineering:</b>
                                <li style={{marginTop: 20, marginLeft: 28}}>When team members share your engineering principles, they understand not just 'what' to do but 'how' and 'why' as well. This shared understanding fosters better teamwork, efficient problem-solving, and more innovative engineering solutions.</li>
                            </li>
                            <br/>

                            <li>
                                <b>Nurturing Innovation and Creative Problem-Solving:</b>
                                <li style={{marginTop: 20, marginLeft: 28}}>A team united by common principles and values becomes a powerhouse of creativity and innovation. This environment is conducive to exploring new ideas and approaches in engineering, leading to advanced solutions and cutting-edge developments.</li>
                            </li>
                            <br/>

                            <li>
                                <b>Increased Satisfaction and Employee Retention:</b>
                                <li style={{marginTop: 20, marginLeft: 28}}>Engineers who align with your principles and values experience higher job satisfaction. This alignment breeds loyalty and a strong commitment to the company, significantly enhancing employee retention and building a more robust and dedicated team.</li>
                            </li>
                            <br/>

                            <li>
                                <b>Elevating Company Reputation in the Tech Community:</b>
                                <li style={{marginTop: 20, marginLeft: 28}}>Teams that operate on shared engineering principles and values positively impact your company's reputation. This shared ethos is visible in the quality of your work and products, attracting not only talent but also clients who value your approach.</li>
                            </li>
                        </ul>
                    </div>
                    <EmptyLine height={90}/>
                    <Row style={{marginLeft: -34}}>
                        <Col span={8}>
                            <div style={{textAlign: 'center'}}>
                                <img style={{width: '60%'}} src={`/img/how-it-works/pic1.svg`}></img>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{textAlign: 'center'}}>
                                <img style={{width: '53%'}} src={`/img/how-it-works/pic2.svg`}></img>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{textAlign: 'center'}}>
                                <img style={{width: '60%'}} src={`/img/how-it-works/pic3.svg`}></img>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={9}>
                    <div style={{paddingLeft: 64}}>
                        <AJImg src={'/img/how-it-works/employ-pic-3-v3.png'} width={'100%'} alt={'Five advantages'}/>
                        {
                            !isLogged() ? 
                            <div style={{width: '100%', textAlign: 'center', marginTop: 143}} className="connect-button">
                                <AJButton4
                                    title={'Connect with top Engineers now'}
                                    size={'mini'}
                                    width={320}
                                    type={'secondary'}
                                    height={60}
                                    titleStyle={{
                                        fontSize: 15,
                                        fontWeight: 400,
                                        fontFamily: 'ManropeBold',
                                        color: '#000000'
                                    }}
                                    customStyle={{
                                        borderRadius: '90px',
                                        background: '#FFD865',
                                    }}
                                    hoverToBlack={true}
                                    titleWithWaveAnimation={true}
                                    onClick={() => {
                                        gotoLink('/register/create-account', history)
                                }}/>
                            </div> : <></>
                        }
                    </div>
                </Col>
            </Row>

            <CEOReviewCardContainer />
            <EmptyLine height={200}/>
            <StartFooter/>
        </div>
    )

}