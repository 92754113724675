import React from "react";
import Cookies from 'universal-cookie';
import uuid from 'react-uuid'

export const AJLoginInfoKey = "airjobb-login"
export const AJCompanyRegisterDataKey = "airjobb-company-register-data"
const AJUserIdKey = 'airjobb-user-id'
const AJUserTokenKey = 'airjobb-user-token'
const AJUserNameKey = 'airjobb-user-name'
const AJIsAdminKey = 'airjobb-is-admin'
const AJCompanyIdKey = 'airjobb-company-id'
const AJCompanyNameKey = 'airjobb-company-name'

const AJFilterValuesKey = 'airjobb-filter-values'

const AJNoLoginUUID = 'airjobb-no-login-uuid'
const AJJoinedCommunityKey = 'airjobb-joined-community'
const AJJoinedCommunityMailKey = 'airjobb-joined-community-mail'
const AJJoinedCommunityNameKey = 'airjobb-joined-community-name'

const AJNotFTUKey = 'airjobb-not-ftu'

const AJLoginFTU = 'airjobb-login-ftu'

const AJClapCount = 'airjobb-clap-count'

const AJFreeEducationFTU = 'airjobb-free-education-ftu'

// const cookies = new Cookies();

export function getJoinedCommunity() {
    return localStorage.getItem(AJJoinedCommunityKey)
}
export function getJoinedCommunityEmail() {
    return localStorage.getItem(AJJoinedCommunityMailKey)
}

export function getJoinedCommunityName() {
    return localStorage.getItem(AJJoinedCommunityNameKey)
}

export function setJoinedCommunity(email, name) {
    localStorage.setItem(AJJoinedCommunityKey, 'true')
    email && localStorage.setItem(AJJoinedCommunityMailKey, email)
    name && localStorage.setItem(AJJoinedCommunityNameKey, name)
}

export function getNotFTU() {
    return localStorage.getItem(AJNotFTUKey)
}

export function setNotFTU() {
    localStorage.setItem(AJNotFTUKey, 'true')
}

export function setLoginFTU(loginInfo) {
    //currentUserId() and company id
    localStorage.setItem(AJLoginFTU, JSON.stringify(loginInfo))
}

export function getLoginFTU() {
    return JSON.parse(localStorage.getItem(AJLoginFTU))
}

export function initAJNoLoginUUID() {
    if (!getAJNoLoginUUID()) {
        localStorage.setItem(AJNoLoginUUID, uuid())
    }
}

export function setAJNoLoginUUID() {
    localStorage.setItem(AJNoLoginUUID, uuid())
}

export function clearAJNoLoginUUID() {
    localStorage.removeItem(AJNoLoginUUID)
}

export function getAJNoLoginUUID() {
    return localStorage.getItem(AJNoLoginUUID)
}

function userKey(userId) {
    return `airjobbStorage-${userId}`
}

export function currentUserId() {
    return parseInt(localStorage.getItem(AJUserIdKey))
}

export function currentToken() {
    return localStorage.getItem(AJUserTokenKey)
}

export function currentUserName() {
    return localStorage.getItem(AJUserNameKey)
}

export function currentCompanyName() {
    return localStorage.getItem(AJCompanyNameKey)
}


export function currentIsAdmin() {
    return JSON.parse(localStorage.getItem(AJIsAdminKey))
}

export function setCurrentToken(token) {
    localStorage.setItem(AJUserTokenKey, token)
}

export function currentCompanyId() {
    return JSON.parse(localStorage.getItem(AJCompanyIdKey))
}

export function clearCurrentToken(token) {
    localStorage.removeItem(AJUserTokenKey)
}

export function isLogged() {
    return !!(currentUserId() && currentToken()&& currentUserName())
}

export function clearLoginInfo() {
    // localStorage.removeItem(userKey(currentUserId()))

    localStorage.removeItem(AJUserIdKey)
    localStorage.removeItem(AJUserTokenKey)
    localStorage.removeItem(AJUserNameKey)
    localStorage.removeItem(AJIsAdminKey)
    localStorage.removeItem(AJCompanyIdKey)
    localStorage.removeItem(AJCompanyNameKey)

}

export function setCurrentUserId(userId) {
    localStorage.setItem(AJUserIdKey, userId)
}

export function setCurrentUserName(userName) {
    localStorage.setItem(AJUserNameKey, userName)

}
export function setCurrentCompanyName(companyName) {
    localStorage.setItem(AJCompanyNameKey, companyName)
}

export function setCurrentIsAdmin(isAdmin) {
    localStorage.setItem(AJIsAdminKey, isAdmin)
}

export function setCurrentCompanyId(companyId) {
    localStorage.setItem(AJCompanyIdKey, companyId)
}

export function currentFilterValues() {
    // console.log(JSON.parse(sessionStorage.getItem(AJFilterValuesKey)))
    return JSON.parse(sessionStorage.getItem(AJFilterValuesKey))
}

export function setCurrentFilterValues(filterValues) {
    sessionStorage.setItem(AJFilterValuesKey, JSON.stringify(filterValues))
}

export function AJStorage() {

    const key = userKey(currentUserId())
    return JSON.parse(localStorage.getItem(key)) || {}

}


export function getFromAJStorage(key) {
    const storage = AJStorage(currentUserId())
    return storage[key]
}

export function saveToAJStorage(key, value) {

    const storage = AJStorage(currentUserId())
    storage[key] = value

    // console.log(`save ${userKey(currentUserId())} ${JSON.stringify(storage)}`)
    localStorage.setItem(userKey(currentUserId()), JSON.stringify(storage))
}

export function initAJStorage(value) {
    localStorage.setItem(userKey(currentUserId()), JSON.stringify(value))
}

export function getLoginInfo() {
    return getFromAJStorage(AJLoginInfoKey)
}

export function saveLoginInfo(loginInfo) {
    return saveToAJStorage(AJLoginInfoKey, loginInfo)
}

export function getCompanyRegisterData() {
    return getFromAJStorage(AJCompanyRegisterDataKey) || {}
}

export function saveCompanyRegisterData(companyRegisterData) {
    saveToAJStorage(AJCompanyRegisterDataKey, companyRegisterData)
}

export function getCompanyStruct() {
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    return companyRegisterData.companyStructure || {}
}

export function saveCompanyStruct(companyStruct) {
    const companyRegisterData = getCompanyRegisterData()
    companyRegisterData.companyStructure = companyStruct
    saveCompanyRegisterData(companyRegisterData)
}

export function getPickedValues() {
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    return companyRegisterData.pickedValues || {}
}

export function savePickedValues(pickedValues) {
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    companyRegisterData.pickedValues = pickedValues
    saveCompanyRegisterData(companyRegisterData)
}

export function getOrderedValues() {
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    return companyRegisterData.orderedValues || []
}

export function saveOrderedValues(orderedValues) {
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    companyRegisterData.orderedValues = orderedValues
    saveCompanyRegisterData(companyRegisterData)


}

export function getDescribeValues() {
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    return companyRegisterData.describeValues || {}
}

export function saveDescribeValues(describeValues) {
    // console.log(`saveDescribeValues to ${describeValues}`)
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    companyRegisterData.describeValues = describeValues
    saveCompanyRegisterData(companyRegisterData)

}

export function saveJobOpeningsSourceAndUrl(source, sourceUrl) {
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    companyRegisterData.sourceAndUrl = {
        source,
        sourceUrl
    }

    saveCompanyRegisterData(companyRegisterData)
}

export function getJobOpeningsSourceAndUrl() {
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    return companyRegisterData.sourceAndUrl || {}

}

export function getJobOpenings() {
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    return companyRegisterData.jobOpenings || []
}

export function saveJobOpenings(jobOpenings) {
    const companyRegisterData = getFromAJStorage(AJCompanyRegisterDataKey) || {}
    companyRegisterData.jobOpenings = jobOpenings
    saveCompanyRegisterData(companyRegisterData)

}

export function reOrderDescribeValues(orderedValues) {
    const describeValues = getDescribeValues()

    if (Object.keys(describeValues).length === 0) {
        return
    }

    const newDescribeValues = {}

    for (let index = 0; index < orderedValues.length; ++index) {
        const value = orderedValues[index]

        for (const key in describeValues) {
            const describeValue = describeValues[key]
            if (describeValue.value === value.text) {
                console.log(key)
                console.log(index)

                newDescribeValues[index+1] = {
                    ...describeValue
                }
                break
            }
        }

    }
    // console.log(newDescribeValues)
    saveDescribeValues(newDescribeValues)
}

export function isLogon() {
    return !!currentToken()
}

export function getClapCount(companyId) {
    let claps;
    try {
        claps = JSON.parse(localStorage.getItem(AJClapCount))|| {}
        if (typeof claps !== 'object') {
            claps = {}
        }
    }
    catch (e) {
        claps = {}
    }

    return claps[companyId] || 0

}

export function saveClapCount(count, companyId) {
    let claps;
    try {
        claps = JSON.parse(localStorage.getItem(AJClapCount))|| {}
        if (typeof claps !== 'object') {
            claps = {}
        }
    }
    catch (e) {
        claps = {}
    }
    claps[companyId] = count
    localStorage.setItem(AJClapCount, JSON.stringify(claps))
}

export function isFreeEducationFTU() {
    const item =  localStorage.getItem(AJFreeEducationFTU)
    if (!item) {
        localStorage.setItem(AJFreeEducationFTU, "true")
        return true
    }
    return false
}
