import React, {useContext, useState} from "react";

import DescribeValuesContext from "./DescribeValuesContext"
import {getDescribeValues, saveDescribeValues} from "../storage/AJStorage";


export default function DescribeValuesProvider(props) {
    // const [describeValues, setDescribeValues] = useState({})
    return (
        <DescribeValuesContext.Provider value={{
            describeValues: () => {
                return getDescribeValues()
            },
            updateDescribeValues: (key, content) => {
                const describeValues = getDescribeValues()
                describeValues[key] = content
                saveDescribeValues(describeValues)
            },
            clearDescribeValues: () => {
                saveDescribeValues(null)
            },
            // reOrderDescribeValues: (orderedValues) => {
            //
            // }
        }}>
            {props.children}
        </DescribeValuesContext.Provider>
    )
}