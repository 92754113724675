import React, { useState, useContext, useEffect } from 'react';
import VCenterContainer from "../ui/VCenterContainer";
import "./Menu.less"
import AJModalV2 from "../ui/AJModalV2";
import Contact from "./Contact";
import {useHistory} from 'react-router'
import SocialMediaIcons from "./SocialMediaIcons";
import { Drawer, Button } from 'antd';
import {
    clearCurrentToken,
    clearLoginInfo, currentCompanyId, currentCompanyName, currentIsAdmin,
    currentToken,
    currentUserId, isLogged,
    setCurrentToken
} from "../storage/AJStorage";
import {gotoLink} from "./start/StartFooter";
import EmptyLine from "../ui/EmptyLine";

export const socialLinkConfig = {
    linkedin:
        'https://www.linkedin.com/company/airjobb/?viewAsMember=true',

    facebook:
        'https://www.facebook.com/Genvalues',

    instagram:
        'https://www.instagram.com/genvalues/?hl=en',

    medium:
        'https://medium.com/@GenValues',

    twitter:
        'https://twitter.com/genvalues',

    youtube:
        'https://www.youtube.com/channel/UCLJjJBqhBFbNDm3ONTrXbxQ'
}

export default function Menu(props) {

    let history = useHistory();

    const {isLogon, logout, visibleChange} = props

    const onClose = props.onClose || function() {}

    const [showContact, setShowContact] = useState(false)
    let [blankHeightOne, setBlankHeightOne] = useState(123)
    let [blankHeightTwo, setBlankHeightTwo] = useState(123)
    let [blankHeightThree, setBlankHeightThree] = useState(70)

    useEffect(() => {
        if(visibleChange) {
            getBlankHeight();
        }
    }, [visibleChange])

    useEffect(() => {
        window.addEventListener('resize', getBlankHeight);
        return () => {
            window.removeEventListener('resize', getBlankHeight);
        }
    })

    function getBlankHeight() {
        setBlankHeightOne((33/108)*window.innerHeight - 177);
        setBlankHeightTwo((50/108)*window.innerHeight - 332);
        setBlankHeightThree((20/108)*window.innerHeight - 102);
    }

    const gotoCompanySignIn = (event) => {
        event.preventDefault()
        onClose()
        setTimeout(() => {
            gotoLink("/register", history)
        }, 0)
    }

    const gotoAbout = (event) => {
        event.preventDefault()
        onClose()
        setTimeout(() => {
            addGA('About us', '/about');
            gotoLink('/about', history)
        }, 0)
    }

    const gotoTalent = (event) => {
        event.preventDefault()
        onClose()
        setTimeout(() => {
            addGA('Talent', '/talent');
            gotoLink('/talent', history)
        }, 0)
    }

    const gotoEmployer = (event) => {
        event.preventDefault()
        onClose()
        setTimeout(() => {
            addGA('Employer', '/employer');
            gotoLink('/employer', history)
        }, 0)
    }

    const gotoToolbox = (event) => {
        event.preventDefault()
        onClose()
        setTimeout(() => {
            addGA('True Fit', '/toolbox');
            gotoLink('/toolbox', history)
        }, 0)
    }

    const companyLogout = (event) => {
        // TODO
        // logout
        if (props.logout) {
            props.logout()
        }
        onClose()
        clearLoginInfo()

        history.push("/")
    }

    const gotoTerms = () =>{
        onClose()
        addGA('Terms of use', '/terms-conditions');
        gotoLink("/terms-conditions", history)
    }

    const gotoPrivacy = () => {
        onClose()
        addGA('Privacy', '/privacy');
        gotoLink("/privacy", history)
    }

    const gotoInsights = () => {
        onClose()
        addGA('News & Media', '/latest');
        gotoLink("/latest", history)
    }

    const showProfile = isLogged() && !currentIsAdmin() && currentCompanyId()
    const continueProfile = isLogged() && !currentCompanyId()

    return (
        <div className={'menu-container'}>
            <div className={'menu-close'} onClick={onClose}>
                <img src={'/img/menu/close.svg'}/>
            </div>
            <EmptyLine height={blankHeightOne}/>
            <VCenterContainer className={'menu-sign-container'}>
                {
                    isLogon ? <div className={'menu-sign-text'} onClick={companyLogout}>
                        Employer sign out
                    </div>
                        :
                        <div className={'menu-sign-text'} onClick={gotoCompanySignIn}>
                            Employer sign in
                        </div>
                }
            </VCenterContainer>
            <div className={'menu-container-2'}>
                <div hidden={!showProfile}>
                    <EmptyLine height={10}/>
                </div>

                <div hidden={!showProfile} className={'menu-contact'} onClick={(e)=> {
                    onClose()
                    addGA('Profile', `/company-profile/${currentCompanyId()}`);
                    gotoLink(`/company-profile/${currentCompanyId()}`, history)
                }}>
                    Profile
                </div>

                <div hidden={!continueProfile}>
                    <EmptyLine height={10}/>
                </div>

                <div hidden={!continueProfile} className={'menu-contact'} onClick={(e)=> {
                    onClose()
                    const companyId = currentCompanyId()
                    if (companyId) {
                        addGA('Continue', `/company-profile/${companyId}`);
                        gotoLink(`/company-profile-edit/${companyId}`, history)
                    }
                }}>
                    Continue
                </div>
                <EmptyLine height={10}/>

                <div className={'menu-contact'} onClick={(e)=> {
                    onClose()
                    // setTimeout(() => {
                    //     setShowContact(true)
                    // }, 200)
                    addGA('Contact', '/contact');
                    gotoLink('/contact', history)

                }}>
                    Contact
                </div>
                <EmptyLine height={10}/>

                <AJModalV2 visible={showContact}
                           width={569}
                           top={200}>
                    <Contact onClose={()=> setShowContact(false)}/>
                </AJModalV2>

                <div className={'menu-insights'} onClick={gotoInsights}>
                    News & Media
                </div>

                <EmptyLine height={10}/>
                <div className={'menu-about'} onClick={gotoTalent}>
                    Talent
                </div>

                <EmptyLine height={10}/>
                <div className={'menu-about'} onClick={gotoEmployer}>
                    Employer
                </div>

                <EmptyLine height={39}/>
                <div className={'menu-about'} onClick={gotoToolbox}>
                    True Fit
                </div>

                <EmptyLine height={10}/>
                <div className={'menu-about'} onClick={gotoAbout}>
                    About us
                </div>

                <EmptyLine height={10}/>
                <div className={'menu-insights'} onClick={() => {
                    onClose()
                    addGA('Pricing', '/pricing');
                    gotoLink("/pricing", history)
                }}>
                    Pricing
                </div>

                <EmptyLine height={blankHeightThree}/>
                <div className={'menu-terms'} onClick={gotoTerms}>
                    Terms of use
                </div>
                <EmptyLine height={23}/>

                <div className={'menu-privacy'} onClick={gotoPrivacy}>
                    Privacy
                </div>

            </div>

            <div className='menu-container-3' style={{marginTop: `${blankHeightTwo}px`}}>
                <div className={'menu-company-icons'}>
                    <SocialMediaIcons
                        socials={socialLinkConfig}
                        version={2}
                        width={23}
                        height={23}
                        space={16}
                        section={'hamburger'}/>
                </div>

                <div className={'menu-copyright'}>
                    Copyright 2024
                </div>
            </div>

        </div>
    )
}

function addGA(eventAction, pageUrl) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'custom_event',
        'eventCategory': 'hamburger_menu',
        'eventAction': `${eventAction}`,
        'eventlabel': `${pageUrl}`
    });
}