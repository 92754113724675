import React, {useState} from "react"
import "./PriceRequestCard.less"
import AJInput2 from "../../ui/AJInput2";
import EmptyLine from "../../ui/EmptyLine";
import AJButton2 from "../../ui/AJButton2";
import {sendPriceRequest} from "../../http/AJHttp";
import {message} from 'antd'
import AJButton3 from "../../ui/AJButton3";

export default function PriceRequestCard({onSend}) {
    const [disabled, setDisabled] = useState(true)
    const [sending, setSending] = useState(false)
    const [fullName, setFullName] = useState('')
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('')
    const [companyName, setCompanyName] = useState('')
    onSend = onSend || (() => {})

    const validation = (fullName, mobileNumber, email, companyName) => {
        const emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;
        const phonePattern = /^[0-9\-\+]+$/;;

        if (emailPattern.test(email) && phonePattern.test(mobileNumber) && fullName.length > 0 && companyName.length > 0) {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }
    }

    return <div className={"price-request-card-container"}>
        <AJInput2 placeholder={'Full Name'} value={fullName} onChange={(e) => {
            setFullName(e.target.value)
            validation(e.target.value, mobileNumber, email, companyName)
        }}/>
        <EmptyLine height={56}/>
        <AJInput2 placeholder={'Mobile Number'} value={mobileNumber} onChange={(e) => {
            setMobileNumber(e.target.value)
            validation(fullName, e.target.value, email, companyName)
        }}/>
        <EmptyLine height={56}/>
        <AJInput2 placeholder={'E-mail'} value={email} onChange={(e) => {
            setEmail(e.target.value)
            validation(fullName, mobileNumber, e.target.value, companyName)
        }}/>
        <EmptyLine height={56}/>
        <AJInput2 placeholder={'Company Name'} value={companyName} onChange={(e) => {
            setCompanyName(e.target.value)
            validation(fullName, mobileNumber, email, e.target.value)
        }}/>
        <EmptyLine height={90}/>
        <div style={{textAlign: 'right'}}>
            <AJButton3
                width={300}
                height={66}
                title={'Send'}
                titleStyle={{fontSize: '27px', color: '#FFFCF0'}}
                disabled={disabled}
                loading={sending}
                onClick={async () => {
                    setSending(true)
                    const response = await sendPriceRequest(fullName, mobileNumber, email, companyName)
                    if (response) {
                        message.info("Send successfully!")
                    }
                    else {
                        message.error("Send successfully!")
                    }
                    setSending(false)
                }}
            />
        </div>

    </div>
}

