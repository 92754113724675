import React, {useEffect, useMemo, useState, useRef} from "react";
import {AutoComplete, Col, Icon, Input, Row, Popover} from 'antd';
import "./technologySelect.less";
import EmptySpan from "../../ui/EmptySpan";
import EmptyLine from "../../ui/EmptyLine";

const { Option } = AutoComplete;

const oldTechs = [
  {
      "name": "Android",
      "icon": "/img/tech-stack/_android_.png"
  },
  {
      "name": "Angular",
      "icon": "/img/tech-stack/_angular_.png"
  },
  {
      "name": "Ansible",
      "icon": "/img/tech-stack/_ansible_.png"
  },
  {
      "name": "Apache",
      "icon": "/img/tech-stack/_apache_.png"
  },
  {
      "name": "Azure Devops",
      "icon": "/img/tech-stack/_azuredevops_.png"
  },
  {
      "name": "Cloudflare",
      "icon": "/img/tech-stack/_cloudflare_.png"
  },
  {
      "name": "CoffeeScript",
      "icon": "/img/tech-stack/_coffeescript_.png"
  },
  {
      "name": "CssWizardry",
      "icon": "/img/tech-stack/_csswizardry_.png"
  },
  {
      "name": "Cypress",
      "icon": "/img/tech-stack/_cypress_.png"
  },
  {
      "name": "Dart",
      "icon": "/img/tech-stack/_dart_.png"
  },
  {
      "name": "Debian",
      "icon": "/img/tech-stack/_debian_.png"
  },
  {
      "name": "Deno",
      "icon": "/img/tech-stack/_deno_.png"
  },
  {
      "name": "Django",
      "icon": "/img/tech-stack/_django_.png"
  },
  {
      "name": "Docker",
      "icon": "/img/tech-stack/_docker_.png"
  },
  {
      "name": "Dot Net",
      "icon": "/img/tech-stack/_dot_net_.png"
  },
  {
      "name": "Elastic",
      "icon": "/img/tech-stack/_elastic_.png"
  },
  {
      "name": "Elasticsearch",
      "icon": "/img/tech-stack/_elasticsearch_.png"
  },
  {
      "name": "Electron",
      "icon": "/img/tech-stack/_electron_.png"
  },
  {
      "name": "Elixir",
      "icon": "/img/tech-stack/_elixir_.png"
  },
  {
      "name": "Expo",
      "icon": "/img/tech-stack/_expo_.png"
  },
  {
      "name": "Flutter",
      "icon": "/img/tech-stack/_flutter_.png"
  },
  {
      "name": "Gatsby",
      "icon": "/img/tech-stack/_gatsby_.png"
  },
  {
      "name": "Github",
      "icon": "/img/tech-stack/_github_.png"
  },
  {
      "name": "Go",
      "icon": "/img/tech-stack/_go_.png"
  },
  {
      "name": "Google Cloud",
      "icon": "/img/tech-stack/_googlecloud_.png"
  },
  {
      "name": "Grafana",
      "icon": "/img/tech-stack/_grafana_.png"
  },
  {
      "name": "Haskell",
      "icon": "/img/tech-stack/_haskell_.png"
  },
  {
      "name": "Html5",
      "icon": "/img/tech-stack/_html5_.png"
  },
  {
      "name": "iOS",
      "icon": "/img/tech-stack/_ios_.png"
  },
  {
      "name": "Java",
      "icon": "/img/tech-stack/_java_.png"
  },
  {
      "name": "Javascript",
      "icon": "/img/tech-stack/_javascript_.png"
  },
  {
      "name": "Jest",
      "icon": "/img/tech-stack/_jest_.png"
  },
  {
      "name": "Jira",
      "icon": "/img/tech-stack/_jira_.png"
  },
  {
      "name": "Kotlin",
      "icon": "/img/tech-stack/_kotlin_.png"
  },
  {
      "name": "Kubernetes",
      "icon": "/img/tech-stack/_kubernetes_.png"
  },
  {
      "name": "Lua",
      "icon": "/img/tech-stack/_lua_.png"
  },
  {
      "name": "Microsoft",
      "icon": "/img/tech-stack/_microsoft_.png"
  },
  {
      "name": "Mongodb",
      "icon": "/img/tech-stack/_mongodb_.png"
  },
  {
      "name": "Mysql",
      "icon": "/img/tech-stack/_mysql_.png"
  },
  {
      "name": "Next Dot Js",
      "icon": "/img/tech-stack/_next_dot_js_.png"
  },
  {
      "name": "Nim",
      "icon": "/img/tech-stack/_nim_.png"
  },
  {
      "name": "Node Js",
      "icon": "/img/tech-stack/_node_dot_js_.png"
  },
  {
      "name": "Ocaml",
      "icon": "/img/tech-stack/_ocaml_.png"
  },
  {
      "name": "Php",
      "icon": "/img/tech-stack/_php_.png"
  },
  {
      "name": "Postgresql",
      "icon": "/img/tech-stack/_postgresql_.png"
  },
  {
      "name": "Prismic",
      "icon": "/img/tech-stack/_prismic_.png"
  },
  {
      "name": "Prometheus",
      "icon": "/img/tech-stack/_prometheus_.png"
  },
  {
      "name": "Python",
      "icon": "/img/tech-stack/_python_.png"
  },
  {
      "name": "R Letter",
      "icon": "/img/tech-stack/_r_letter_.png"
  },
  {
      "name": "React",
      "icon": "/img/tech-stack/_react_.png"
  },
  {
      "name": "Reason",
      "icon": "/img/tech-stack/_reason_.png"
  },
  {
      "name": "Redis",
      "icon": "/img/tech-stack/_redis_.png"
  },
  {
      "name": "Redux",
      "icon": "/img/tech-stack/_redux_.png"
  },
  {
      "name": "Ruby",
      "icon": "/img/tech-stack/_ruby_.png"
  },
  {
      "name": "Rust",
      "icon": "/img/tech-stack/_rust_.png"
  },
  {
      "name": "Scala",
      "icon": "/img/tech-stack/_scala_.png"
  },
  {
      "name": "Serverless",
      "icon": "/img/tech-stack/_serverless_.png"
  },
  {
      "name": "Swift",
      "icon": "/img/tech-stack/_swift_.png"
  },
  {
      "name": "Tailwindcss",
      "icon": "/img/tech-stack/_tailwindcss_.png"
  },
  {
      "name": "Typescript",
      "icon": "/img/tech-stack/_typescript_.png"
  },
  {
      "name": "Umbraco",
      "icon": "/img/tech-stack/_umbraco_.png"
  },
  {
      "name": "Visual Studio Code",
      "icon": "/img/tech-stack/_visualstudiocode_.png"
  },
  {
      "name": "Vue Js",
      "icon": "/img/tech-stack/_vue_dot_js_.png"
  },
  {
      "name": "Webrtc",
      "icon": "/img/tech-stack/_webrtc_.png"
  }
];

const oldTechsMaps = {};
oldTechs.forEach(tech => {
  oldTechsMaps[tech.name] = tech.icon;
});

const techs = [
    {
        "name": "Active Directory",
        "icon": "/img/tech-stack/active-directory.png"
    },
    {
        "name": "Athena",
        "icon": "/img/tech-stack/Athena.png"
    },
    {
        "name": "Atlassian",
        "icon": "/img/tech-stack/Atlassian.png"
    },
    {
        "name": "AWS Cloud",
        "icon": "/img/tech-stack/AWS Cloud.png"
    },
    {
        "name": "Air Flow",
        "icon": "/img/tech-stack/Air Flow.png"
    },
    {
        "name": "Airflow",
        "icon": "/img/tech-stack/Airflow.png"
    },
    {
        "name": "ASP",
        "icon": "/img/tech-stack/ASP.png"
    },
    {
        "name": "Babel",
        "icon": "/img/tech-stack/Babel.png"
    },
    {
        "name": "Basic",
        "icon": "/img/tech-stack/Basic.png"
    },
    {
        "name": "Bloop",
        "icon": "/img/tech-stack/Bloop.png"
    },
    {
        "name": "Brain",
        "icon": "/img/tech-stack/Brain.png"
    },
    {
        "name": "Bootstrap",
        "icon": "/img/tech-stack/Bootstrap.png"
    },
    {
        "name": "C",
        "icon": "/img/tech-stack/C.png"
    },
    {
        "name": "CI",
        "icon": "/img/tech-stack/CI.png"
    },
    {
        "name": "Clean Code",
        "icon": "/img/tech-stack/Clean Code.png"
    },
    {
        "name": "Clojure",
        "icon": "/img/tech-stack/Clojure.png"
    },
    {
        "name": "Cloud",
        "icon": "/img/tech-stack/Cloud.png"
    },
    {
        "name": "DDD",
        "icon": "/img/tech-stack/DDD.png"
    },
    {
        "name": "Dev Ops",
        "icon": "/img/tech-stack/Dev Ops.png"
    },
    {
        "name": "Digital Ocean",
        "icon": "/img/tech-stack/Digital Ocean.png"
    },
    {
        "name": "Docker Hub",
        "icon": "/img/tech-stack/Docker Hub.png"
    },
    {
        "name": "Dockerized",
        "icon": "/img/tech-stack/Dockerized.png"
    },
    {
        "name": "Emacs",
        "icon": "/img/tech-stack/Emacs.png"
    },
    {
        "name": "Episerver",
        "icon": "/img/tech-stack/Episerver.png"
    },
    {
        "name": "F#",
        "icon": "/img/tech-stack/FSharp.png"
    },
    {
        "name": "Firebase",
        "icon": "/img/tech-stack/Firebase.png"
    },
    {
        "name": "Forth",
        "icon": "/img/tech-stack/Forth.png"
    },
    {
        "name": "Free Basic",
        "icon": "/img/tech-stack/Free Basic.png"
    },
    {
        "name": "GCP",
        "icon": "/img/tech-stack/GCP.png"
    },
    {
        "name": "Git",
        "icon": "/img/tech-stack/Git.png"
    },
    {
        "name": "Golang",
        "icon": "/img/tech-stack/Golang.png"
    },
    {
        "name": "Groovy",
        "icon": "/img/tech-stack/Groovy.png"
    },
    {
        "name": "Kaboom",
        "icon": "/img/tech-stack/Kaboom.png"
    },
    {
        "name": "LOLcode",
        "icon": "/img/tech-stack/LOLcode.png"
    },
    {
        "name": "Linux",
        "icon": "/img/tech-stack/Linux.png"
    },
    {
        "name": "Love 2d",
        "icon": "/img/tech-stack/Love 2d.png"
    },
    {
        "name": "Mapkit",
        "icon": "/img/tech-stack/Mapkit.png"
    },
    {
        "name": "Mask Group",
        "icon": "/img/tech-stack/Mask group.png"
    },
    {
        "name": "Microservice",
        "icon": "/img/tech-stack/Microservice.png"
    },
    {
        "name": "Microsoft SQL",
        "icon": "/img/tech-stack/Microsoft SQL.png"
    },
    {
        "name": "NoSQL",
        "icon": "/img/tech-stack/NoSQL.png"
    },
    {
        "name": "Objective C",
        "icon": "/img/tech-stack/Objective-C.png"
    },
    {
        "name": "Pascal",
        "icon": "/img/tech-stack/Pascal.png"
    },
    {
        "name": "Play Framework",
        "icon": "/img/tech-stack/Play Framework.png"
    },
    {
        "name": "Pyxel",
        "icon": "/img/tech-stack/Pyxel.png"
    },
    {
        "name": "QBasic",
        "icon": "/img/tech-stack/QBasic.png"
    },
    {
        "name": "R",
        "icon": "/img/tech-stack/R.png"
    },
    {
        "name": "REST API",
        "icon": "/img/tech-stack/REST API.png"
    },
    {
        "name": "Raku",
        "icon": "/img/tech-stack/Raku.png"
    },
    {
        "name": "React Active",
        "icon": "/img/tech-stack/React Active.png"
    },
    {
        "name": "ReactNative",
        "icon": "/img/tech-stack/ReactNative.png"
    },
    {
        "name": "Roy",
        "icon": "/img/tech-stack/Roy.png"
    },
    {
        "name": "SQL",
        "icon": "/img/tech-stack/SQL.png"
    },
    {
        "name": "Scheme",
        "icon": "/img/tech-stack/Scheme.png"
    },
    {
        "name": "SwiftUI",
        "icon": "/img/tech-stack/SwiftUI.png"
    },
    {
        "name": "TCL",
        "icon": "/img/tech-stack/TCL.png"
    },
    {
        "name": "Tel",
        "icon": "/img/tech-stack/Tel.png"
    },
    {
        "name": "Unlambda",
        "icon": "/img/tech-stack/Unlambda.png"
    },
    {
        "name": "Web",
        "icon": "/img/tech-stack/Web.png"
    },
    {
        "name": "WebRTC",
        "icon": "/img/tech-stack/WebRTC.png"
    },
    {
        "name": "XC Test",
        "icon": "/img/tech-stack/XC Test.png"
    },
    {
        "name": "Akka",
        "icon": "/img/tech-stack/akka.png"
    },
    {
        "name": "Angular",
        "icon": "/img/tech-stack/angular.png"
    },
    {
        "name": "Ansible",
        "icon": "/img/tech-stack/ansible.png"
    },
    {
        "name": "Apache",
        "icon": "/img/tech-stack/apache.png"
    },
    {
        "name": "Aws",
        "icon": "/img/tech-stack/aws.png"
    },
    {
        "name": "Azuredevops",
        "icon": "/img/tech-stack/azuredevops.png"
    },
    {
        "name": "Bash",
        "icon": "/img/tech-stack/bash.png"
    },
    {
        "name": "C#",
        "icon": "/img/tech-stack/c-sharp.png"
    },
    {
        "name": "C++",
        "icon": "/img/tech-stack/c++.png"
    },
    {
        "name": "Cmake",
        "icon": "/img/tech-stack/cmake.png"
    },
    {
        "name": "Cassandra",
        "icon": "/img/tech-stack/cassandra.png"
    },
    {
        "name": "Chromium",
        "icon": "/img/tech-stack/Chromium.png"
    },
    {
        "name": "Cloudflare",
        "icon": "/img/tech-stack/cloudflare.png"
    },
    {
        "name": "Cocoa Touch",
        "icon": "/img/tech-stack/Cocoa-Touch.png"
    },
    {
        "name": "Coffeescript",
        "icon": "/img/tech-stack/coffeescript.png"
    },
    {
        "name": "Concur",
        "icon": "/img/tech-stack/Concur.png"
    },
    {
        "name": "Confluence",
        "icon": "/img/tech-stack/Confluence.png"
    },
    {
        "name": "Core",
        "icon": "/img/tech-stack/core.png"
    },
    {
        "name": "Cosmos DB",
        "icon": "/img/tech-stack/Cosmos-DB.png"
    },
    {
        "name": "CSS",
        "icon": "/img/tech-stack/css.png"
    },
    {
        "name": "Crystal",
        "icon": "/img/tech-stack/crystal.png"
    },
    {
        "name": "Csswizardry",
        "icon": "/img/tech-stack/csswizardry.png"
    },
    {
        "name": "Cython",
        "icon": "/img/tech-stack/Cython.png"
    },
    {
        "name": "Cypress",
        "icon": "/img/tech-stack/cypress.png"
    },
    {
        "name": "D3",
        "icon": "/img/tech-stack/D3.png"
    },
    {
        "name": "Dart",
        "icon": "/img/tech-stack/dart.png"
    },
    {
        "name": "Debian",
        "icon": "/img/tech-stack/debian.png"
    },
    {
        "name": "Delphi",
        "icon": "/img/tech-stack/delphi.png"
    },
    {
        "name": "Deno",
        "icon": "/img/tech-stack/deno.png"
    },
    {
        "name": "Django",
        "icon": "/img/tech-stack/django.png"
    },
    {
        "name": "Docker",
        "icon": "/img/tech-stack/docker.png"
    },
    {
        "name": "Dot Net",
        "icon": "/img/tech-stack/dot net.png"
    },
    {
        "name": "Elastic",
        "icon": "/img/tech-stack/elastic.png"
    },
    {
        "name": "Elasticsearch",
        "icon": "/img/tech-stack/elasticsearch.png"
    },
    {
        "name": "Electron",
        "icon": "/img/tech-stack/electron.png"
    },
    {
        "name": "Elixir",
        "icon": "/img/tech-stack/elixir.png"
    },
    {
        "name": "Erlang",
        "icon": "/img/tech-stack/erlang.png"
    },
    {
        "name": "Expo",
        "icon": "/img/tech-stack/expo.png"
    },
    {
        "name": "Express",
        "icon": "/img/tech-stack/Express.png"
    },
    {
        "name": "Figma",
        "icon": "/img/tech-stack/Figma.png"
    },
    {
        "name": "Flask",
        "icon": "/img/tech-stack/Flask.png"
    },
    {
        "name": "Flutter",
        "icon": "/img/tech-stack/flutter.png"
    },
    {
        "name": "Gatsby",
        "icon": "/img/tech-stack/gatsby.png"
    },
    {
        "name": "Github",
        "icon": "/img/tech-stack/github.png"
    },
    {
        "name": "Go",
        "icon": "/img/tech-stack/go.png"
    },
    {
        "name": "Gola",
        "icon": "/img/tech-stack/Gola.png"
    },
    {
        "name": "Google Cloud Platform",
        "icon": "/img/tech-stack/google-cloud-platform.png"
    },
    {
        "name": "Google Cloud",
        "icon": "/img/tech-stack/googlecloud.png"
    },
    {
        "name": "Gradle",
        "icon": "/img/tech-stack/Gradle.png"
    },
    {
        "name": "Grafana",
        "icon": "/img/tech-stack/grafana.png"
    },
    {
        "name": "Hadoop",
        "icon": "/img/tech-stack/hadoop.png"
    },
    {
        "name": "Haskell",
        "icon": "/img/tech-stack/haskell.png"
    },
    {
        "name": "Html5",
        "icon": "/img/tech-stack/html5.png"
    },
    {
        "name": "iOS",
        "icon": "/img/tech-stack/ios.png"
    },
    {
        "name": "Java",
        "icon": "/img/tech-stack/java.png"
    },
    {
        "name": "Javascript",
        "icon": "/img/tech-stack/javascript.png"
    },
    {
        "name": "Jest",
        "icon": "/img/tech-stack/jest.png"
    },
    {
        "name": "Jira",
        "icon": "/img/tech-stack/jira.png"
    },
    {
        "name": "Julia",
        "icon": "/img/tech-stack/julia.png"
    },
    {
        "name": "Kotlin",
        "icon": "/img/tech-stack/kotlin.png"
    },
    {
        "name": "Kubernetes",
        "icon": "/img/tech-stack/kubernetes.png"
    },
    {
        "name": "Lua",
        "icon": "/img/tech-stack/lua.png"
    },
    {
        "name": "Memcached",
        "icon": "/img/tech-stack/memcached.png"
    },
    {
        "name": "Meteor",
        "icon": "/img/tech-stack/meteor.png"
    },
    {
        "name": "Microsoft",
        "icon": "/img/tech-stack/microsoft.png"
    },
    {
        "name": "Mongodb",
        "icon": "/img/tech-stack/mongodb.png"
    },
    {
        "name": "Mysql",
        "icon": "/img/tech-stack/mysql.png"
    },
    {
        "name": "Next Dot Js",
        "icon": "/img/tech-stack/next dot js.png"
    },
    {
        "name": "Nim",
        "icon": "/img/tech-stack/nim.png"
    },
    {
        "name": "Node Js",
        "icon": "/img/tech-stack/node dot js.png"
    },
    {
        "name": "Ocaml",
        "icon": "/img/tech-stack/ocaml.png"
    },
    {
        "name": "Perl",
        "icon": "/img/tech-stack/perl.png"
    },
    {
        "name": "Php",
        "icon": "/img/tech-stack/php.png"
    },
    {
        "name": "Postgresql",
        "icon": "/img/tech-stack/postgresql.png"
    },
    {
        "name": "Prismic",
        "icon": "/img/tech-stack/prismic.png"
    },
    {
        "name": "Prometheus",
        "icon": "/img/tech-stack/prometheus.png"
    },
    {
        "name": "Pygame",
        "icon": "/img/tech-stack/pygame.png"
    },
    {
        "name": "Python",
        "icon": "/img/tech-stack/python.png"
    },
    {
        "name": "React",
        "icon": "/img/tech-stack/react.png"
    },
    {
        "name": "Reason",
        "icon": "/img/tech-stack/reason.png"
    },
    {
        "name": "Redis",
        "icon": "/img/tech-stack/redis.png"
    },
    {
        "name": "Redux",
        "icon": "/img/tech-stack/redux.png"
    },
    {
        "name": "Rest",
        "icon": "/img/tech-stack/rest.png"
    },
    {
        "name": "Ruby",
        "icon": "/img/tech-stack/ruby.png"
    },
    {
        "name": "Rust",
        "icon": "/img/tech-stack/rust.png"
    },
    {
        "name": "Spark",
        "icon": "/img/tech-stack/spark.png"
    },
    {
        "name": "Swift",
        "icon": "/img/tech-stack/swift.png"
    },
    {
        "name": "Tailwindcss",
        "icon": "/img/tech-stack/tailwindcss.png"
    },
    {
        "name": "Typescript",
        "icon": "/img/tech-stack/typescript.png"
    },
    {
        "name": "Umbraco",
        "icon": "/img/tech-stack/umbraco.png"
    },
    {
        "name": "Visual Studio Code",
        "icon": "/img/tech-stack/visual-studio-code.png"
    },
    {
        "name": "Vue Dot Js",
        "icon": "/img/tech-stack/vue dot js.png"
    },
    {
        "name": "Android",
        "icon": "/img/tech-stack/Android.png"
    },
    {
        "name": "Scala",
        "icon": "/img/tech-stack/Scala.png"
    },
    {
        "name": "Serverless",
        "icon": "/img/tech-stack/serverless.png"
    }
];

const techsMaps = {
    "Android": "/img/tech-stack/Android.png",
    "Scala": "/img/tech-stack/Scala.png",
    "Serverless": "/img/tech-stack/serverless.png",
    "AWS Cloud": "/img/tech-stack/AWS Cloud.png",
    "Air Flow": "/img/tech-stack/Air Flow.png",
    "Airflow": "/img/tech-stack/Airflow.png",
    "Basic": "/img/tech-stack/Basic.png",
    "Bloop": "/img/tech-stack/Bloop.png",
    "Brain": "/img/tech-stack/Brain.png",
    "C": "/img/tech-stack/C.png",
    "CI": "/img/tech-stack/CI.png",
    "Clean Code": "/img/tech-stack/Clean Code.png",
    "Clojure": "/img/tech-stack/Clojure.png",
    "Cloud": "/img/tech-stack/Cloud.png",
    "DDD": "/img/tech-stack/DDD.png",
    "Dev Ops": "/img/tech-stack/Dev Ops.png",
    "Digital Ocean": "/img/tech-stack/Digital Ocean.png",
    "Docker Hub": "/img/tech-stack/Docker Hub.png",
    "Dockerized": "/img/tech-stack/Dockerized.png",
    "Emacs": "/img/tech-stack/Emacs.png",
    "Episerver": "/img/tech-stack/Episerver.png",
    "F#": "/img/tech-stack/FSharp.png",
    "Firebase": "/img/tech-stack/Firebase.png",
    "Forth": "/img/tech-stack/Forth.png",
    "Free Basic": "/img/tech-stack/Free Basic.png",
    "GCP": "/img/tech-stack/GCP.png",
    "Git": "/img/tech-stack/Git.png",
    "Golang": "/img/tech-stack/Golang.png",
    "Groovy": "/img/tech-stack/Groovy.png",
    "Kaboom": "/img/tech-stack/Kaboom.png",
    "LOLcode": "/img/tech-stack/LOLcode.png",
    "Linux": "/img/tech-stack/Linux.png",
    "Love 2d": "/img/tech-stack/Love 2d.png",
    "Mapkit": "/img/tech-stack/Mapkit.png",
    "Mask Group": "/img/tech-stack/Mask group.png",
    "Microservice": "/img/tech-stack/Microservice.png",
    "Microsoft SQL": "/img/tech-stack/Microsoft SQL.png",
    "NoSQL": "/img/tech-stack/NoSQL.png",
    "Objective C": "/img/tech-stack/Objective-C.png",
    "Pascal": "/img/tech-stack/Pascal.png",
    "Play Framework": "/img/tech-stack/Play Framework.png",
    "Pyxel": "/img/tech-stack/Pyxel.png",
    "QBasic": "/img/tech-stack/QBasic.png",
    "R": "/img/tech-stack/R.png",
    "REST API": "/img/tech-stack/REST API.png",
    "Raku": "/img/tech-stack/Raku.png",
    "React Active": "/img/tech-stack/React Active.png",
    "ReactNative": "/img/tech-stack/ReactNative.png",
    "Roy": "/img/tech-stack/Roy.png",
    "SQL": "/img/tech-stack/SQL.png",
    "Scheme": "/img/tech-stack/Scheme.png",
    "SwiftUI": "/img/tech-stack/SwiftUI.png",
    "TCL": "/img/tech-stack/TCL.png",
    "Tel": "/img/tech-stack/Tel.png",
    "Unlambda": "/img/tech-stack/Unlambda.png",
    "Web": "/img/tech-stack/Web.png",
    "WebRTC": "/img/tech-stack/WebRTC.png",
    "XC Test": "/img/tech-stack/XC Test.png",
    "Akka": "/img/tech-stack/akka.png",
    "Angular": "/img/tech-stack/angular.png",
    "Ansible": "/img/tech-stack/ansible.png",
    "Apache": "/img/tech-stack/apache.png",
    "Aws": "/img/tech-stack/aws.png",
    "Azuredevops": "/img/tech-stack/azuredevops.png",
    "Bash": "/img/tech-stack/bash.png",
    "C#": "/img/tech-stack/c-sharp.png",
    "C++": "/img/tech-stack/c++.png",
    "Cassandra": "/img/tech-stack/cassandra.png",
    "Cloudflare": "/img/tech-stack/cloudflare.png",
    "Coffeescript": "/img/tech-stack/coffeescript.png",
    "Core": "/img/tech-stack/core.png",
    "Crystal": "/img/tech-stack/crystal.png",
    "Csswizardry": "/img/tech-stack/csswizardry.png",
    "Cypress": "/img/tech-stack/cypress.png",
    "Dart": "/img/tech-stack/dart.png",
    "Debian": "/img/tech-stack/debian.png",
    "Delphi": "/img/tech-stack/delphi.png",
    "Deno": "/img/tech-stack/deno.png",
    "Django": "/img/tech-stack/django.png",
    "Docker": "/img/tech-stack/docker.png",
    "Dot Net": "/img/tech-stack/dot net.png",
    "Elastic": "/img/tech-stack/elastic.png",
    "Elasticsearch": "/img/tech-stack/elasticsearch.png",
    "Electron": "/img/tech-stack/electron.png",
    "Elixir": "/img/tech-stack/elixir.png",
    "Erlang": "/img/tech-stack/erlang.png",
    "Expo": "/img/tech-stack/expo.png",
    "Express": "/img/tech-stack/Express.png",
    "Flutter": "/img/tech-stack/flutter.png",
    "Gatsby": "/img/tech-stack/gatsby.png",
    "Github": "/img/tech-stack/github.png",
    "Go": "/img/tech-stack/go.png",
    "Google Cloud Platform": "/img/tech-stack/google-cloud-platform.png",
    "Google Cloud": "/img/tech-stack/googlecloud.png",
    "Grafana": "/img/tech-stack/grafana.png",
    "Hadoop": "/img/tech-stack/hadoop.png",
    "Haskell": "/img/tech-stack/haskell.png",
    "Html5": "/img/tech-stack/html5.png",
    "iOS": "/img/tech-stack/ios.png",
    "Java": "/img/tech-stack/java.png",
    "Javascript": "/img/tech-stack/javascript.png",
    "Jest": "/img/tech-stack/jest.png",
    "Jira": "/img/tech-stack/jira.png",
    "Julia": "/img/tech-stack/julia.png",
    "Kotlin": "/img/tech-stack/kotlin.png",
    "Kubernetes": "/img/tech-stack/kubernetes.png",
    "Lua": "/img/tech-stack/lua.png",
    "Memcached": "/img/tech-stack/memcached.png",
    "Meteor": "/img/tech-stack/meteor.png",
    "Microsoft": "/img/tech-stack/microsoft.png",
    "Mongodb": "/img/tech-stack/mongodb.png",
    "Mysql": "/img/tech-stack/mysql.png",
    "Next Dot Js": "/img/tech-stack/next dot js.png",
    "Nim": "/img/tech-stack/nim.png",
    "Node Js": "/img/tech-stack/node dot js.png",
    "Ocaml": "/img/tech-stack/ocaml.png",
    "Perl": "/img/tech-stack/perl.png",
    "Php": "/img/tech-stack/php.png",
    "Postgresql": "/img/tech-stack/postgresql.png",
    "Prismic": "/img/tech-stack/prismic.png",
    "Prometheus": "/img/tech-stack/prometheus.png",
    "Pygame": "/img/tech-stack/pygame.png",
    "Python": "/img/tech-stack/python.png",
    "React": "/img/tech-stack/react.png",
    "Reason": "/img/tech-stack/reason.png",
    "Redis": "/img/tech-stack/redis.png",
    "Redux": "/img/tech-stack/redux.png",
    "Rest": "/img/tech-stack/rest.png",
    "Ruby": "/img/tech-stack/ruby.png",
    "Rust": "/img/tech-stack/rust.png",
    "Spark": "/img/tech-stack/spark.png",
    "Swift": "/img/tech-stack/swift.png",
    "Tailwindcss": "/img/tech-stack/tailwindcss.png",
    "Typescript": "/img/tech-stack/typescript.png",
    "Umbraco": "/img/tech-stack/umbraco.png",
    "Visual Studio Code": "/img/tech-stack/visual-studio-code.png",
    "Vue Dot Js": "/img/tech-stack/vue dot js.png",
    "Gradle": "/img/tech-stack/Gradle.png",
    "Active Directory": "/img/tech-stack/active-directory.png",
    "Atlassian": "/img/tech-stack/Atlassian.png",
    "Babel": "/img/tech-stack/Babel.png",
    "Bootstrap": "/img/tech-stack/Bootstrap.png",
    "Cmake": "/img/tech-stack/cmake.png",
    "Cocoa Touch": "/img/tech-stack/Cocoa-Touch.png",
    "Concur": "/img/tech-stack/Concur.png",
    "Confluence": "/img/tech-stack/Confluence.png",
    "Cosmos DB": "/img/tech-stack/Cosmos-DB.png",
    "CSS": "/img/tech-stack/css.png",
    "Cython": "/img/tech-stack/Cython.png",
    "D3": "/img/tech-stack/D3.png",
    "Figma": "/img/tech-stack/Figma.png",
    "Flask": "/img/tech-stack/Flask.png",
    "Gola": "/img/tech-stack/Gola.png",
    "Athena": "/img/tech-stack/Athena.png",
    "ASP": "/img/tech-stack/ASP.png",
    "Chromium": "/img/tech-stack/Chromium.png"
};

function renderOption(tech) {
  return <div style={{display: 'flex', alignItems: 'center', height: 20, width: '100%'}}>
      <div>
        <img src={tech.icon} alt={tech.name} width={16} height={16}/>
      </div>
      <EmptySpan width={12}/>
      <div style={{fontSize: 18}}>
          {tech.name}
      </div>
  </div>
}

export default function TechnologySelector({selected, disabled, onChange}) {
  const [selectedTechs, setSelectedTechs] = useState(selected || []);
  const [searchKey, setSearchKey] = useState('');
  const [visible, setVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    selected = selected || []
    setSelectedTechs([...selected])
  }, [selected])

  const leftTechs = useMemo(() => {
    return techs.filter(tech => {
        return selectedTechs.filter(st => st.name === tech.name).length === 0;
    })
  }, [selectedTechs])

  const options = useMemo(() => {
    return leftTechs.filter(tech => tech.name.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1)
      .map(tech => {
        return <Option key={tech.name} value={tech.name}>
              {renderOption(tech)}
            </Option>
      })
  }, [searchKey, leftTechs])

  const addSelectTech = (selectTechName) => {
    const list = techs.filter(tech => tech.name === selectTechName)
    if (list.length === 1) {
      selectedTechs.push(list[0])
      setSelectedTechs([...selectedTechs])
      // onChange && onChange({
      //     // freeText: freeText,
      //     selected: selectedTechs
      // })
    }
  }

  const removeSelectedTech = (selectedTech) => {
    const list = selectedTechs.filter(tech => tech.name !== selectedTech.name)
    setSelectedTechs([...list])
    // onChange && onChange({
    //   // freeText: freeText,
    //   selected: selectedTechs
    // })
  }

  function handleVisibleChange(visible) {
    if (!visible) {
      onChange(selectedTechs);
    }
    setVisible(visible);
  };

  return (
    <Popover
      overlayClassName="matched-companies-popover technology-popover"
      autoAdjustOverflow={true}
      content={
        <div className={'tech-stack-editing'}>
          <AutoComplete
            style={{ width: '100%'}}
            className="certain-category-search"
            dropdownClassName="certain-category-search-dropdown"
            defaultActiveFirstOption={false}
            dataSource={options}
            onSearch={(key) => {
              setSearchKey(key)
            }}
            onSelect={(item) => {
              addSelectTech(item)
              setTimeout(() => {
                setSearchKey('')
              })
            }}
            optionLabelProp="value"
          >
            <Input
              prefix={<Icon type="search" className="certain-category-icon" />}
              placeholder="Search for technologies"
              value={searchKey}
              style={{'height': '40px'}}
            />
          </AutoComplete>

          {selectedTechs.length > 0 ? <EmptyLine height={21}/> : null}
          <SelectedTechGroup group={selectedTechs} onDelete={(tech) => {
            removeSelectedTech(tech)
          }}/>
        </div>
      }
      placement="bottomLeft"
      trigger="click"
      visible={visible && !isDisabled}
      onVisibleChange={handleVisibleChange}
    >
      <button className={`matched-company-button ${selectedTechs && selectedTechs.length > 0 ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`} 
        style={{'width': '320px', 'position': 'absolute', 'right': '196px'}}>
        <div className={'tech-button-title'}>
            <img src={`/img/matched-companies/technology${isDisabled ? '-grey' : ''}.svg`}></img>
            <span>Technology</span>
        </div>
        {
          selectedTechs && selectedTechs.length > 0 ? 
          <img className={'dropdown'} src={`/img/matched-companies/close${isDisabled ? '-grey' : ''}.svg`}></img>
          : <img className={'dropdown'} src={`/img/matched-companies/narrow-down${isDisabled ? '-grey' : ''}.svg`}></img>
        }
      </button>
    </Popover>
  );
}

function SelectedTechGroup({group, onDelete, readonly}) {
  return (
    <div className={"selected-technology-container"}>
      <Row type={'flex'}>
        {group.map((item, index) => {
          return <Col key={index} span={8} style={{paddingBottom: 43}}>
            <SelectedTechItem item={item} onDelete={onDelete} readonly={readonly}/>
          </Col>
        })}
    </Row>
    </div>
  );

}

function SelectedTechItem({item, onDelete, readonly}) {
  const name = namePatch[item.name] || item.name
  const icon = techsMaps[name] || oldTechsMaps[item.name] || item.icon

  return <div style={{textAlign: 'center'}}>
    <div style={{position: 'relative', width: '100%', color: '#181414'}}>
      <img src={icon} alt={item.name} height={28} width={26}/>
      <div
        hidden={readonly}
        style={{position: 'absolute', width: 20, height: 20, right: 6, top: -10, cursor: 'pointer'}}
        onClick={() => {
          onDelete && onDelete(item)
        }}>
        <Icon type="close-circle" />
      </div>
    </div>

    <div className={"technology-name"}>
      {name}
    </div>
  </div>
}

const namePatch = {
  "Node Dot Js" : "Node Js"
}
