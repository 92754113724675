import React, {useContext} from "react";
import CompanyProfileEditorContext from "../../context/CompanyProfileEditorContext";
import AJUpload, {AJUpload2, AJUpload3} from "../../ui/AJUpload";
import {AJImageUrl} from "../../http/AJHttpConst";
import "./CompanyProfileImageContainer.less"

export default function CompanyProfileImageContainer(props) {
    const {editing, profileData} = props
    let width = props.width || '100%'
    let height = props.height || '537'
    const imageUrl = profileData.companyStructure.imageUrl

    let onUploadedCompanyImage = props.onUploadedCompanyImage || (()=>{})

    const editIcon = (
        <div style={{position: "absolute", bottom: 27, right: 50}} hidden={!editing}
             onClick={(e)=>{
                 document.getElementById('company-profile-image-container').click()
             }}>
            <img src={'/img/company-profile/edit-white.svg'}/>
        </div>
    )

    return (
        <div className={'company-profile-image-container'} style={{width, minHeight: height, position: 'relative'}}>
            <div hidden={!editing}>
                <AJUpload3 imageUrl={AJImageUrl(imageUrl)}
                           imageWidth={width}
                           loadingSize={80}
                           placeholderImage={'/img/company-profile/company-profile-default.png'}
                           id={'company-profile-image-container'}
                           beforeUpload={() => {
                               //console.log("beforeUpload")
                           }}
                           onUploaded={(file, imageUrlNew) => {
                               const imageUrl = file.response.image
                               profileData.companyStructure.imageUrl = imageUrl
                           }}
                />
                {editIcon}
            </div>

            <div
                style={{minHeight: 160}}
                 hidden={editing} >
                <img src={AJImageUrl(imageUrl) || '/img/company-profile/company-profile-default.png'} width={'100%'}
                     // height={height}
                    onLoad={(event)=>{
                        if (props.onImageLoaded) {
                            props.onImageLoaded()
                        }
                    }}
                />
            </div>
        </div>
    )
}
