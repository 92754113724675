import React, {useEffect, useState} from 'react';
import {isLogged} from "../storage/AJStorage";
import {getAllCompanies, getJobVisitors, getOpenJobs} from "../http/AJHttp";
import {Button, Table, DatePicker} from "antd";
import { Base64 } from 'js-base64';
import CsvExportor from "csv-exportor"
const { RangePicker } = DatePicker;
export default function JobVisitors()
{
    // if (!isLogged()){
    //     return <>Login please!</>;
    // }

    return <div>
        <JobVisitorsBody/>
    </div>

}

function JobVisitorsBody()
{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Promise.all([getJobVisitors(), getOpenJobs(), getAllCompanies()])
            .then(([d1, d2, d3]) => {
                const jobVisitor = d1.data;
                const openJobs = d2.data;
                const companies = d3.data;
                const jobIdToCompanyName = {};
                companies.forEach(company => {
                    const jobOpenings = company.jobOpenings
                    jobOpenings.forEach(jobId => {
                        jobIdToCompanyName[jobId] = company.name
                    })
                })
                // console.log(jobIdToCompanyName)

                const jobLinkToCompanyName = {}
                openJobs.forEach(openJob => {
                    jobLinkToCompanyName[openJob.url] = jobIdToCompanyName[openJob.id]
                    // if (!jobLinkToCompanyName[openJob.url])
                    // {
                    //     console.log(openJob.url)
                    // }
                });
                console.log(jobLinkToCompanyName)
                jobVisitor.forEach(visitor => {
                    visitor.company = visitor.company || jobLinkToCompanyName[visitor.jobOpening] || ""
                })
                // console.log(jobVisitor)
                setData(jobVisitor)
                setLoading(false)
            });

    }, [])
//[{"id":1,"jobOpening":"https://careers.29k.org/jobs/1426171-interns-2022","email":"1234@12.com","timestamp":"2022-11-18T11:34:49.166931Z"},
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Visitor',
            dataIndex: 'email',
            key: 'email',
            render: text => <a href={`mailto:${text}`}>{text}</a>,
            sorter: (a, b) => {
                return a.email.localeCompare(b.email)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: text => {
                if (text) {
                    text = text.replace(/^b'/, '').replace(/\\n'$/, '')
                    return Base64.decode(text)
                }
                return ''
            },
            // sorter: (a, b) => {
            //     return a.email.localeCompare(b.email)
            // }
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            sorter: (a, b) => {
                return a.company.localeCompare(b.company)
            }
        },
        {
            title: 'JobOpening Url',
            dataIndex: 'jobOpening',
            key: 'jobOpening',
            render: text => <a href={text}>{text}</a>,
            sorter: (a, b) => a.jobOpening.localeCompare(b.jobOpening)
        },

        {
            title: 'Timestamp',
            key: 'timestamp',
            dataIndex: 'timestamp',
            render: (text, record) => {
                const date = new Date(text)
                return <div>
                    {date.toLocaleString()}
                </div>
            },
            sorter: (a, b) => {

                return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
            }

        },
    ];

    const csvHeaders = ['ID', 'Email', 'Name', 'Company', 'url', 'date']

    return <div>
        <br/><br/>
        <div style={{padding: '0 32px'}}>
            <span>
                <Button onClick={() => {
                    CsvExportor.downloadCsv(data, csvHeaders, 'jobopenings-visitor.csv')
                }}>
                    Export as CSV
                </Button>
            </span>

            <span style={{float: 'right'}}>
                <RangePicker onChange={(dates, dateString) => {
                    const start = dates[0]
                    const end = dates[1];
                    const newData = data.filter(d => {
                        const date = new Date(d.timestamp || null)
                        console.log(date)
                        return date >= start.toDate() && date <= end.toDate();
                    })
                    setData(newData)
                }} />
            </span>

        </div>
        <br/><br/>
        <Table loading={loading} columns={columns} dataSource={data} pagination={{pageSize: 20}}/>
    </div>



}