import React from "react";

function SvgRightNarrow(props) {
  return (
    <svg width="7" height="12" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.856 15.488L0.0640001 13.712L5.952 7.92L0.0640001 2.128L1.856 0.351999L9.424 7.92L1.856 15.488Z" fill={props.fill ? props.fill : 'black'}/>
    </svg>
  );
}

export default SvgRightNarrow;
