import React, {useState} from "react";
import "./scrollTab.less";

export default function ScrollTab({onIndexChange}) {
	const tabList = [
		"Team culture",
		"Guiding principles",
		"Career development",
		"Workplace culture",
		"Strategy",
		"Health and Wellbeing",
		"Sustainability"
	]

	let [currentIndex, setCurrentIndex] = useState(0);
	const [isHintHovered, setIsHintHovered] = useState(false);
	let [typeHintSx, setTypeHintSx] = useState({});

	function handleArrowClick(direction) {
		let tempData = direction === "left" ? currentIndex - 1 : currentIndex + 1;
		setCurrentIndex(tempData);
		onIndexChange(tempData);
	}

	function handleHintPicPosition(index) {
		switch (index) {
			case 0: {
				return [-216, 232];
			}
			case 1: {
				return [-250, 254];
			}
			case 2: {
				return [-216, 254];
			}
			case 3: {
				return [-250, 254];
			}
			case 4: {
				return [-214, 254];
			}
			case 5: {
				return [-214, 254];
			}
			case 6: {
				return [-250, 275];
			}
		}
	}

	return (
		<div>
			<div className={`switch-button ${currentIndex <= 0 ? 'not-show' : ''}`} onClick={() => handleArrowClick('left')}>
				<img style={{'width': '70px'}} src={'/img/value-select/chevron-left.svg'}/>
			</div>
			
			<img
				id={`hint-${currentIndex}`}
				className={`type-hint ${!isHintHovered ? 'not-show' : ''}`}
				style={typeHintSx}
				src={`/img/value-select/value-hint-text-${currentIndex + 1}.svg`}/>
			<img
				id={"question-img"}
				onMouseOver={()=>{
					const img = document.getElementById("question-img").getBoundingClientRect();					
					const position = handleHintPicPosition(currentIndex);
					setTypeHintSx({...typeHintSx, 'top': `${position[0]}px`, 'left': `${img.x - 305}px`});
					setIsHintHovered(true);
				}}
				onMouseLeave={()=>{setIsHintHovered(false)}}
				style={{'width': '34px', 'marginLeft': '30px'}}
				src={'/img/value-select/question-mark.svg'}>
			</img>			

			<div className="tab-title">
				<span style={{'marginRight': '15px'}}>{tabList[currentIndex]}</span>
				<div style={{fontFamily: 'InterSemiBold', display: "inline"}}>
				<span style={{'color': '#CCC'}}>{'('}</span>
				<span>{currentIndex+1}</span>
				<span style={{'color': '#CCC'}}>{'/'}{tabList.length}{')'}</span>
				</div>
			</div>

			<div className={`switch-button ${currentIndex >= tabList.length-1 ? 'not-show' : ''}`} onClick={() => handleArrowClick('right')}>
				<img style={{'width': '70px'}} src={'/img/value-select/chevron-right.svg'}/>
			</div>
		</div>
	);
};
