import React from "react";
import ReactDOM from 'react-dom';

import AJModal from "../ui/AJModal";
import VCenterContainer from "../ui/VCenterContainer";

export function showModal(top, bodyRender) {

    let body = document.body;
    let showDom = document.createElement("div");

    showDom.style.position = 'absolute';
    showDom.style.top = '0px';
    showDom.style.left = '0px';
    showDom.style.zIndex = 9999;
    body.appendChild(showDom);

    let close = () => {
        ReactDOM.unmountComponentAtNode(showDom);
        body.removeChild(showDom);
    }

    ReactDOM.render((
            <AJModal top={top} visible={true}>
                { bodyRender() }
            </AJModal>
        ), showDom)
}

export function isSafari() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
        return ua.indexOf('chrome') <= -1;
    }
    return false
}
