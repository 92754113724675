import React, {useState} from "react";
import AJModalV2 from "../ui/AJModalV2";
import AJCard from "../ui/AJCard";
import {Col, Icon, Input, Row} from "antd";
import EmptyLine from "../ui/EmptyLine";
import AJButton3 from "../ui/AJButton3";
import AJVideoPlayer from "../ui/AJVideoPlayer";
import {AJUpload2, AJUpload3} from "../ui/AJUpload";
import {AJImageUrl} from "../http/AJHttpConst";

import "./ValueMediaUpload.less"
import AJInput2 from "../ui/AJInput2";
import EmptySpan from "../ui/EmptySpan";

export default function ValueMediaUpload(props) {
    const {
        value,
        height,
        width,
        onVideoChange,
        onVideoSave,
        onImageChange,
        readonly,
        footer,
        placeholderImage,
        hiddenVideo} = props
    const uploadId = `aj-upload-image-${value.value}`
    const inputId =  `aj-link-video-${value.value}`
    const [linkVideoVisible, setLinkVideoVisible] = useState(false)
    const [uploadImage, setUploadImage] = useState(false)

    const [currentImage, setCurrentImage] = useState(value.image)
    const [currentVideo, setCurrentVideo] = useState(value.video)

    const LinkVideo = () => {
        return (
            <AJModalV2 visible={linkVideoVisible}
                       width={750}
                       closable={true}
                       centered={true}
                       onClose={()=> {
                           setLinkVideoVisible(false)
                       }}>
                <AJCard width={750}
                        height={166}
                        borderRadius={22}
                        type={'wide'}
                        hideClose={true}
                >
                    <Row type={'flex'} align="middle" style={{height:'100%', padding: '0 31px'}} >
                        <Col span={24}>
                            <div className={'value-media-upload-video-title'}>
                                Link a Video
                            </div>
                            <EmptyLine height={17}/>
                            <div style={{width: 512, display: "inline-block"}}>
                                <AJInput2
                                    id={inputId}
                                    placeholder={'Url to Video, (Youtube, Vimeo)'}
                                    onChange={(e)=> {
                                        if (onVideoChange) {
                                            onVideoChange(e.target.value)
                                        }
                                    }}/>
                            </div>
                            <EmptySpan width={28}/>
                            <div style={{textAlign: 'right', display: "inline-block"}}>
                                <AJButton3
                                    size={'small'}
                                    title={'Save'}
                                    titleStyle={{'color': 'white'}}
                                    width={103}
                                    height={38}
                                    onClick={()=>{
                                        const video = document.getElementById(inputId).value
                                        if (video) {
                                            setCurrentVideo(video)
                                            setCurrentImage(null)
                                            if (onVideoSave)
                                                onVideoSave(video)
                                            setLinkVideoVisible(false)
                                        }

                                    }}/>
                            </div>
                        </Col>
                    </Row>
                </AJCard>
            </AJModalV2>
        )
    }

    return (
        <>
            <div hidden={(!readonly) || hiddenVideo}>
                <ValueMedia {...props}/>
            </div>

            <div hidden={readonly}>
                <div style={{position: "relative", width: width}}>
                    <div hidden={!currentVideo || uploadImage}>
                        <AJVideoPlayer
                            height={height}
                            width={width}
                            url={currentVideo}/>
                    </div>

                    <div hidden={currentVideo}>
                        <AJUpload3
                            imageWidth={width}
                            imageHeight={height}
                            imageUrl={AJImageUrl(value.image) || placeholderImage || `/img/common/img-place-holder.png`}
                            id={uploadId}
                            loadingSize={40}
                            beforeUpload={()=>{
                                setUploadImage(true)
                            }}
                            onUploaded={(file) => {
                                const url = file.response.image
                                setCurrentImage(url)
                                setCurrentVideo(null)
                                if (onImageChange)
                                    onImageChange(url)
                                setUploadImage(false)
                            }}/>
                    </div>
                </div>

                <EmptyLine height={16}/>
                {
                    footer ? footer({
                        openUploadingImage : () => {
                            const upload = document.getElementById(uploadId)
                            upload.click()
                        },
                        showLinkVideoModel: setLinkVideoVisible
                    })
                        : null
                }

                <LinkVideo/>
            </div>
        </>
    )
}


function ValueMedia(props) {
    const {value, height, width} = props

    if (value.video) {
        return (
            <AJVideoPlayer
                height={height}
                width={width}
                url={value.video}/>
        )
    }
    else if (value.image) {
        return (
            <img width={width} src={AJImageUrl(value.image) || props.placeholderImage} onError={() => {}}/>
        )
    }

    return null
}



