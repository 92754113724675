import React, {useContext} from 'react'
import { useDrag, useDrop } from 'react-dnd'
import ItemTypes from './ItemTypes'

import "./DNDCard.css"
import PickValuesContext from "../../context/PickValuesContext";

const DndCard = ({ index, id, text, moveCard, findCard, onEnd }) => {
    const originalIndex = findCard(id).index

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id, originalIndex },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        end: (dropResult, monitor) => {
            const { id: droppedId, originalIndex } = monitor.getItem()
            const didDrop = monitor.didDrop()
            if (!didDrop) {
                moveCard(droppedId, originalIndex)
            }
            else {
                if (onEnd) {
                    onEnd()
                }
            }
        },
    })
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id)
                moveCard(draggedId, overIndex)
            }
        },
    })
    const opacity = isDragging ? 0 : 1
    const display = "inline-block"
    return (
        <div>
            <div className={'dnd-card-index'}>
                {index+1} &nbsp;&nbsp;
            </div>
            <div ref={node => drag(drop(node))} style={{ opacity, display }}>
                <div className={'dnd-card'}>
                    {/*<div className={'dnd-card-index'}>{index}</div>*/}
                    {/*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                    <div className={'dnd-card-text'}>{text}</div>
                </div>
                <br/>
            </div>
        </div>
    )
}
export default DndCard
