import React from "react";

function SvgLinkedin(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 27" fill="none" {...props}>
      <path
        d="M24.375.406H1.816C.82.406 0 1.286 0 2.34V24.78c0 1.055.82 1.875 1.816 1.875h22.559c.996 0 1.875-.82 1.875-1.875V2.34c0-1.055-.879-1.934-1.875-1.934zM7.91 22.906H4.043v-12.48H7.91v12.48zM5.977 8.668c-1.29 0-2.286-.996-2.286-2.227 0-1.23.997-2.285 2.286-2.285 1.23 0 2.226 1.055 2.226 2.285 0 1.23-.996 2.227-2.226 2.227zM22.5 22.906h-3.926v-6.093c0-1.407 0-3.282-1.992-3.282-2.05 0-2.344 1.582-2.344 3.223v6.152h-3.867v-12.48h3.691v1.699h.06c.526-.996 1.816-2.05 3.69-2.05 3.926 0 4.688 2.636 4.688 5.976v6.855z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgLinkedin;
