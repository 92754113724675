import React, {useState} from "react";
import FilterValuesContext from "./FilterValuesContext";
import {arrayContains} from "../utils/arrayUtil";
import {currentFilterValues, setCurrentFilterValues} from "../storage/AJStorage";

export default function FilterValuesProvider(props) {
    // const [filterValues, setFilterValues] = useState({})

    return (
        <FilterValuesContext.Provider value={{
            filterValues: () => {
                return currentFilterValues()
            },
            updateValues: (selected, card) => {
                const filterValues = currentFilterValues() || {}
                let valueC = filterValues[card.index] || []
                if (selected) {
                    valueC.push(card.title)
                }
                else {
                    valueC = valueC.filter(e => e !== card.title)
                }
                filterValues[card.index] = valueC

                setCurrentFilterValues(filterValues)
            },
            containsValue: (card, title) => {
                const filterValues = currentFilterValues()|| {}

                let valueC = filterValues[card.index] || []
                return arrayContains(valueC, title)
            },
            leftValueCount: () => {
                return 999999
            },
            totalValueCount: () => {
                const filterValues = currentFilterValues()|| {}

                let total = 0
                // console.log(filterValues)

                for (const item in filterValues) {
                    total += (filterValues[item] ? filterValues[item].length : 0)
                }
                return total
            }
        }}>
            {props.children}
        </FilterValuesContext.Provider>
    )


}