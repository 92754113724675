import React, {useMemo} from "react";
import "./AJButton3.less"
import {Icon} from "antd";
import VCenterContainer from "./VCenterContainer";

export default function AJButton3(props) {
    let {shadow,
        title,
        disabled,
        type,
        size,
        scheme,
        onClick,
        icon,
        width,
        background,
        height,
        font,
        loading,
        titleStyle,
    href} = props

    type = type || 'primary'
    size = size || 'normal'
    scheme = scheme || 'dark'
    onClick = onClick || (() => {})

    const paddingLeft = size === 'normal' ? 32 : 22
    const paddingRight = size === 'normal' ? 32 : 22

    let customStyle = { }
    if (width) {
        customStyle = {
            ...customStyle,
            width: width,
        }
    }
    else {
        customStyle = {
            ...customStyle,
            paddingLeft,
            paddingRight,
        }
    }

    if (height) {
        customStyle = {
            ...customStyle,
            height,
        }
    }

    if (background) {
        customStyle = {
            ...customStyle,
            background,
        }
    }

    if (font) {
        customStyle = {
            ...customStyle,
            ...font,
        }
    }

    if (loading) {
        // disable click event ...
        onClick = () => {}
        customStyle = {
            ...customStyle,
            cursor: "not-allowed",
        }
    }

    if (href) {
        customStyle = {
            ...customStyle,
            href,
        }
    }

    const iconFontSize = size === 'normal' ? 18 : 12

    const className = `aj-btn-3 
        aj-btn-${size} 
        ${scheme.length === 0? `aj-btn-${type}-container` : `aj-btn-${scheme}-${type}-container`}
        ${shadow ? 'aj-btn-box-shadow' : ''}`

    return (
        <button className={className}
                style={customStyle}
                disabled={disabled}
                onClick={onClick}>
            <div>
                {loading? (<Icon type="loading"/>) : null}
                {loading? <span>&nbsp;&nbsp;</span> : null}
                <div style={{display: 'inline-block', fontFamily: 'ManropeRegular', fontSize: '17px', color: 'black', ...titleStyle}}>{title}</div>
                {icon? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : null}
                {icon? (<Icon type={icon} style={{fontSize: iconFontSize}}/>) : null}
            </div>
        </button>
    )
}

export function AJButton4(props) {
    let {shadow,
        title,
        disabled,
        type,
        size,
        scheme,
        onClick,
        icon,
        width,
        height,
        font,
        loading,
        titleStyle,
        customStyle,
        href,
        onMouseEnter,
        onMouseLeave,
        hoverToBlack,
        titleWithWaveAnimation} = props
    const buttonAnimationGap = useMemo(()=>{return calcGap(title)}, [title]);
    const titleWordNum = useMemo(()=>{return title.split(' ').length}, [title]);

    type = type || 'primary'
    size = size || 'normal'
    scheme = scheme || 'dark'
    onClick = onClick || (() => {})
    onMouseEnter = onMouseEnter || (() => {})
    onMouseLeave = onMouseLeave || (() => {})
    hoverToBlack = hoverToBlack || false
    titleWithWaveAnimation = titleWithWaveAnimation || false

    const paddingLeft = size === 'normal' ? 32 : 22
    const paddingRight = size === 'normal' ? 32 : 22

    customStyle = {...customStyle || { }, position: 'relative'}
    if (width) {
        customStyle = {
            ...customStyle,
            width: width,
        }
    }
    else {
        customStyle = {
            ...customStyle,
            paddingLeft,
            paddingRight,
        }
    }

    if (height) {
        customStyle = {
            ...customStyle,
            height,
        }
    }

    if (font) {
        customStyle = {
            ...customStyle,
            ...font,
        }
    }

    if (loading) {
        // disable click event ...
        onClick = () => {}
        customStyle = {
            ...customStyle,
            cursor: "not-allowed",
        }
    }

    if (href) {
        customStyle = {
            ...customStyle,
            href,
        }
    }

    const iconFontSize = size === 'normal' ? 18 : 12

    const className = `aj-btn-3 
        ${hoverToBlack ? 'aj-btn-hover-to-black' : ''} 
        aj-btn-${size} 
        ${scheme.length === 0? `aj-btn-${type}-container` : `aj-btn-${scheme}-${type}-container`}
        ${shadow ? 'aj-btn-box-shadow' : ''}`

    return (
        <button className={className}
                style={customStyle}
                disabled={disabled}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>
            <div>
                {loading? (<Icon type="loading"/>) : null}
                {loading? <span>&nbsp;&nbsp;</span> : null}
                {!titleWithWaveAnimation ? <div style={{...titleStyle}} className="button-title">{title}</div> : 
                    title && title.split(' ').map((item, index) => {
                        const gap = buttonAnimationGap[index];
                        return [...Array.from(item).map((letter, index1) => {
                          return <span key={index1} style={{animationDelay: `${(index1+gap) * 0.02}s`, display: 'inline-block', marginTop: 3, ...titleStyle }} className='title-letter'>{letter}</span>
                        }), index !== (titleWordNum - 1) ? <span style={titleStyle}>{' '}</span> : <></>]
                    })
                }
                {icon? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : null}
                {icon? (<Icon type={icon} style={{fontSize: iconFontSize}}/>) : null}
            </div>
        </button>
    )
}

export function AJCenterButton(props) {
    return (
        <VCenterContainer>
            {AJButton3(props)}
        </VCenterContainer>
    )
}

export function calcGap(targetStr) {
    const strArr = targetStr.split(' ');
    let gap = [];
    for (let i=0; i<strArr.length; i++) {
      if (i === 0) {
        gap.push(0);
      } else {
        let tmp = 0;
        for (let j=0; j<i; j++) {
          tmp += strArr[j].length;
        }
        gap.push(tmp);
      }
    }
    return gap;
  }
