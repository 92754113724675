import React, {useLayoutEffect, useRef, useState} from "react";

import "./JoinOurTalent.less";
import AJInput2, {AJEmailInput} from "../../ui/AJInput2";
import EmptyLine from "../../ui/EmptyLine";
import AJModalV2 from "../../ui/AJModalV2";
import LinkedinLogin from "./LinkedinLogin";
import {message} from "antd";
import {jobOpeningVisitor, sendCommunityMail} from "../../http/AJHttp";
import {setJoinedCommunity} from "../../storage/AJStorage";
import * as url from "url";
import {useOnClickOutside} from "../../hooks/common";
import uuid from "react-uuid";
import AJButton2 from "../../ui/AJButton2";
import AJButton3 from "../../ui/AJButton3";


export default function JoinOurTalent({emailWidth, onClose, url}) {
    emailWidth = emailWidth || 300;
    const [sending, setSending] = useState(false);
    const ref = useRef();
    const uuidRef = useRef(uuid());
    useOnClickOutside(ref, [], () => {
        onClose && onClose(true);
    });
    useLayoutEffect(() => {
        document.getElementById(uuidRef.current).focus();
    }, []);
    return <div className={'join-our-talent-container'} ref={ref}>
        <EmptyLine height={40}/>
        <div className={'join-our-talent-container-title'}>
            Join Our Talent Network
        </div>
        {/*<EmptyLine height={40}/>*/}
        <div style={{textAlign: 'center', padding: '8px 0 6px 0'}} >
            <img src={'/img/join-talent.png'} height={52} width={83}/>
        </div>
        <div style={{width: emailWidth}} onClick={() => {
            document.getElementById(uuidRef.current).focus();
        }}>
            <AJEmailInput placeholder={'Enter your email'} id={uuidRef.current}/>
        </div>
        <EmptyLine height={18}/>
        <div className={'join-our-talent-container-faster'}>
            <AJButton3 title={'Send'}
                       size={'mini'}
                       width={80}
                       type={'secondary'}
                       loading={sending}
                       height={30}
                       onClick={
                            async () => {
                                const email = document.getElementById(uuidRef.current).value
                                if (!email) {
                                    message.warn('Please enter your email!')
                                }
                                else {
                                    const response = await sendCommunityMail(email)
                                    await jobOpeningVisitor(email, url)
                                    if (response) {
                                        setJoinedCommunity(email)
                                    }
                                    onClose && onClose();
                                }
                           }
                       }/>
        </div>
        <EmptyLine height={12}/>
        <div className={'join-our-talent-container-linkedin'}>
           <LinkedinLogin redirectUrl={url} onClose={onClose}/>
        </div>
        <EmptyLine height={24}/>
        <div className={'join-our-talent-container-privacy'} onClick={() => {
            window.open('/privacy')
        }}>
            Privacy
        </div>

    </div>
}

export function JoinOurTalentV2({emailWidth, onClose, url, company}) {
    emailWidth = emailWidth || 300;
    const [sending, setSending] = useState(false);
    const ref = useRef();
    const uuidRef = useRef(uuid());
    const uuidRef2 = useRef(uuid());

    const [disable, setDisable] = useState(true);

    function inputChanged() {
        const id = uuidRef.current
        const email = document.getElementById(id).value
        const id2 = uuidRef2.current
        const name = document.getElementById(id2).value
        const pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;
        if (pattern.test(email) && name.length > 0) {
            setDisable(false);
        }
        else {
            setDisable(true);
        }

    }

    useOnClickOutside(ref, [], () => {
        onClose && onClose(true);
    });
    useLayoutEffect(() => {
        document.getElementById(uuidRef2.current).focus();
    }, []);
    return <div className={'join-our-talent-container-2'} ref={ref}>
        <div className={'join-our-talent-header-2'}>
            <img src={'/img/join-community-header.png'} alt={'Join Community'} width={'100%'} height={'100%'}/>
        </div>

        <EmptyLine height={13}/>

        <div className={'join-our-talent-title-2'}>
            Join Our Talent <br/>Network
        </div>

        <EmptyLine height={25}/>

        <div style={{textAlign: 'center'}}>
            <div className={'join-our-talent-email-2'} onChange={(e) => {
                inputChanged()

            }} onClick={() => {
                document.getElementById(uuidRef2.current).focus();
            }}>
                <AJInput2 placeholder={'Your name'} id={uuidRef2.current}/>
            </div>
            <EmptyLine height={16}/>
            <div className={'join-our-talent-email-2'} onChange={(e) => {
                inputChanged()

            }} onClick={() => {
                document.getElementById(uuidRef.current).focus();
            }}>
                <AJInput2 placeholder={'Your email'} id={uuidRef.current}/>
            </div>
        </div>

        <EmptyLine height={30}/>

        <div className={'join-our-talent-button-2'}>
            <AJButton3 title={'Send'}
                       size={'mini'}
                       width={202}
                       type={'secondary'}
                       loading={sending}
                       disabled={disable}
                       height={38}
                       onClick={
                           async () => {
                               const id = uuidRef.current
                               const email = document.getElementById(id).value
                               const id2 = uuidRef2.current
                               const name = document.getElementById(id2).value

                               setSending(true)
                               const response = await sendCommunityMail(email, name)
                               await jobOpeningVisitor(email, name, url, company)

                               if (response) {
                                   setJoinedCommunity(email, name);
                               }
                               else {
                               }
                               setSending(false)
                               document.getElementById(id).value = ''
                               onClose && onClose();
                           }
                       }/>
        </div>
        <EmptyLine height={22}/>
        <div className={'join-our-talent-fast-signup'}>
            Fast Signup
        </div>
        <EmptyLine height={6}/>
        <div className={'join-our-talent-linkedin-2'}>
            <LinkedinLogin redirectUrl={url} onClose={onClose} company={company}/>
        </div>
        <EmptyLine height={16}/>
        <div className={'join-our-talent-privacy-2'} onClick={() => {
            window.open('/privacy')
        }}>
            Privacy
        </div>

    </div>
}


export function JoinOurTalentModal({emailWidth, modalWidth, onClose, show}) {
    modalWidth = modalWidth || 367;
    return <AJModalV2 visible={true}
                      width={modalWidth}
                      closable={false}
                      centered={true}
                      >
        <JoinOurTalent emailWidth={emailWidth} onClose={() => {
            onClose && onClose();
        }}/>
    </AJModalV2>
}