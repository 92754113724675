import React from "react";
import AJModalV2 from "../../ui/AJModalV2";
import {showModal} from "../../utils/modalUtil";
import {ValuesSelector} from "./PickValues";

export const defaultTitleStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#181414'
}

export default function ValueModals(props) {

    let {updateFun, containsFun, leftNumberFun, valuesConfig, hiddenFooter, selectedValues, onCloseModal, onCancel} = props
    updateFun = updateFun || (() => {})
    leftNumberFun = leftNumberFun || (() => 1)
    valuesConfig = valuesConfig || []
    containsFun = containsFun || (() => true)

    const renderValueCard = (card) => {
        if (!card) return null
        return (
            <ValuesSelector colorStyle={'light'}
                            key={leftNumberFun()}
                            card={card}
                            titleStyle={defaultTitleStyle}
                            onClose={props.onCancel}
                            updateValues={updateFun}
                            leftValueCount={leftNumberFun}
                            containsValue={containsFun}
                            hiddenFooter={hiddenFooter}
                            hPadding={card.hPadding}
            />
            )
    }

    return (
        <div>
            {
                valuesConfig.map((card, index) => {
                    return(
                        <AJModalV2 key={index} visible={card.showModal} closable={false} maskClosable={true}
                                   onClose={() => {
                                       if (onCloseModal) {
                                           onCloseModal(card)
                                       }
                                   }}>
                            { renderValueCard(card) }
                        </AJModalV2>
                        )

                })
            }
        </div>
    )
}
