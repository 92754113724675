import React, {useRef, useState} from "react";
import "./CompanyProfileValues.less"
import EmptyLine from "../../ui/EmptyLine";
import {EditIcon} from "../../page/CompanyProfile2";
import {DndProvider} from "react-dnd";
import Backend from "react-dnd-html5-backend";
import {AJDraggableContainer, AJDraggableContainerV2} from "../../ui/dnd2/DNDContainer2";
import ChangeValue from "./ChangeValue";
import AJModalV2 from "../../ui/AJModalV2";
import AJButton3, {AJCenterButton} from "../../ui/AJButton3";
import AJPopover from "../../ui/AJPopover";
import ValueChanging from "./ValueChanging";
import {valuePatch} from "../values/ValuesConfig";

// let version = 0

export default function CompanyProfileValues(props) {
    const {profileData, editing} = props

    const topValues = profileData.describeValues
    const orderedValues = getOrderedValues(profileData)

    return (
        <div>
            <div>
                <div>
                    <EmptyLine height={48}/>
                    <div className={'company-profile2-value-title'}>
                        {editing?
                                <>
                                    <AJPopover
                                        placement={'right'}
                                        content={<>To Change order<br/> re-arrange your values by<br/> drag and drop<br/><br/> To edit value<br/> Doubleclick the value</>}
                                        width={120}
                                        scheme={'dark'}>
                                        <EditIcon/>
                                        Team values
                                    </AJPopover>
                                </>
                            : <>
                                Principles & values
                            </>}
                    </div>
                    <EmptyLine height={16}/>
                    {editing ?
                        <div>
                            <EditableValueContainer {...props}/>
                        </div>
                        : <>
                            {orderedValues.map((value, index)=>{
                                return (<div className={'company-profile2-value-text'} key={index}>
                                    {index+1}&nbsp;&nbsp;{valuePatch(value)}
                                </div>)
                            })}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

function findValueFromOrderedValues(orderedValues, value) {

    for (let index = 0; index < orderedValues.length; ++index) {
        if (orderedValues[index] && orderedValues[index].text === value) {
            return index
        }
    }

    return -1
}

function findValueFromDescribeValues(describeValues, value) {
    for (const index in describeValues) {
        if (describeValues[index].value === value) {
            return index
        }
    }

    return -1
}

function updateValue(profileData, savedProfile, oldValue, newValue) {
    if (oldValue === newValue) {
        return
    }

    const orderedValues = profileData.orderedValues
    const describeValues = profileData.describeValues
    const savedDescribeValues = savedProfile.describeValues


    const index = findValueFromOrderedValues(orderedValues, oldValue)
    const key = findValueFromDescribeValues(describeValues, oldValue)
    const savedIndex = findValueFromDescribeValues(savedDescribeValues, newValue)
    let savedImage = null
    let savedText = null

    if (savedIndex !== -1) {
        savedImage = savedDescribeValues[savedIndex].image
        savedText = savedDescribeValues[savedIndex].text
    }

    orderedValues[index].text = newValue
    describeValues[key] = {
        image: savedImage,
        text: savedText,
        value: newValue
    }

}

export function getOrderedValues(profileData) {
    const orderedValues = profileData.orderedValues
    const describeValues = profileData.describeValues
    const values = Object.keys(describeValues)
        .map((key, index) => describeValues[key].value)
    return values
}

const EditableValueContainer = (props) => {
    const {profileData, version, onChangedValue, savedProfileData} = props

    const orderedValues = profileData.orderedValues
    const describeValues = profileData.describeValues

    const currentOrderedValues = getOrderedValues(profileData)

    const [values, setValues] = useState(currentOrderedValues)
    // for DNDContainer!!
    const [cards, setCards] = useState(currentOrderedValues
        .map((value, index)=>{
            return {id: index+1, content: value}
        }))

    // const [originalValue, setOriginalValue] = useState(null)
    //const [selectedValue, setSelectedValue] = useState(null)

    const RenderEditableValue = (value) => {
        // const [reload, setReload] = useState(0)
        // const changeValueRef = useRef()
        const [showValueSelector, setShowValueSelector] = useState(false)
        // console.log(value)

        // const ValueSelector = (props) => {
        //     const [selectedValue, setSelectedValue] = useState(value)
        //     return (
        //         <AJModalV2 visible={showValueSelector}
        //                    width={580}
        //                    top={50}
        //                    closable={true}
        //                    onClose={(newValue) => {
        //                        setShowValueSelector(false)
        //                    }}>
        //             <ChangeValue value={value}
        //                          selectedValue={selectedValue}
        //                          width={580}
        //                          excludeSet={values}
        //                          onChanged={(newValue) => {
        //                              setSelectedValue(newValue)
        //                          }}
        //             />
        //             <AJCenterButton
        //                 title={'Save'}
        //                 width={200}
        //                 size={'small'}
        //                 onClick={()=>{
        //                     setShowValueSelector(false)
        //                     updateValue(profileData, savedProfileData, value, selectedValue)
        //                     onChangedValue(version + 1)
        //                     // setReload(reload+1)
        //                     setValues(getOrderedValues(profileData))
        //                     setCards(getOrderedValues(profileData)
        //                         .map((value, index)=>{
        //                         return {id: index+1, content: value}
        //                     }))
        //                 }}/>
        //             <EmptyLine height={24}/>
        //         </AJModalV2>
        //     )
        // }

        const ValueSelector = (props) => {
            const [selectedValue, setSelectedValue] = useState(value)

            return (
                <ValueChanging value={selectedValue}
                               showModal={showValueSelector}
                               exclude={profileData.orderedValues.map(v => v.text)
                                   .filter(v => v !== selectedValue)}
                               onClose={() => {
                                   setShowValueSelector(false)
                               }}
                                onSave={(newValue) => {
                                    console.log(newValue)
                                    setShowValueSelector(false)
                                    updateValue(profileData, savedProfileData, value, newValue)
                                    onChangedValue(version + 1)
                                    setValues(getOrderedValues(profileData))
                                    setCards(getOrderedValues(profileData)
                                        .map((value, index)=>{
                                            return {id: index+1, content: value}
                                        }))

                                }}/>
            )
        }
        return (
            <>
                <div className={'company-profile2-value-text'}>
                    <div className={'company-profile2-value-tag'}
                         onDoubleClick={() => {
                             setShowValueSelector(true)
                             // selectedValue = value
                             //setOriginalValue(value)
                         }}>
                        {valuePatch(value)}
                        <ValueSelector key={value}/>
                    </div>

                </div>


            </>
        )


    }

    const onEnd = (items) => {
        // update describeValues and orderedValues

        const newDescribeValues = {}
        const newOrderedValues = []

        //{id: 1, content: "Lunch together"}
        items.forEach((item, index) => {
            const value = item.content
            for (const key in describeValues) {
                if (describeValues[key].value === value) {
                    newDescribeValues[index+1] = describeValues[key]
                    break
                }
            }

            for (const v of orderedValues) {
                if (v.text === value) {
                    newOrderedValues.push(v)
                    break
                }
            }
        })

        profileData.orderedValues = newOrderedValues
        profileData.describeValues = newDescribeValues

        onChangedValue(version + 1)
    }

    return (
        <>

            <DndProvider backend={Backend}>
                <AJDraggableContainerV2
                    cards={cards}
                    setCards={setCards}
                    onEnd={onEnd}
                    renderContent={RenderEditableValue}
                    renderHeader={(({index}) =>
                            <span className={'company-profile-small-text'}>
                            {index+1}&nbsp;&nbsp;
                        </span>
                    )}
                />
            </DndProvider>
        </>
    )
}

