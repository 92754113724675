import React from "react";

function Logo(props) {
    let {schema} = props;

    return (
        <svg width="140" height="38" viewBox="0 0 186 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21.7966" cy="39.9587" r="4.50363" fill={schema}/>
            <circle cx="21.889" cy="28.2503" r="2.43196" fill={schema}/>
            <circle cx="21.8862" cy="20.8648" r="1.35109" fill={schema}/>
            <circle cx="14.7718" cy="16.9906" r="2.43196" fill={schema}/>
            <circle cx="29.0921" cy="16.9906" r="2.43196" fill={schema}/>
            <circle cx="4.50363" cy="25.1306" r="4.50363" fill={schema}/>
            <circle cx="39.156" cy="25.1306" r="4.50363" fill={schema}/>
            <circle cx="11.3474" cy="4.59543" r="4.50363" fill={schema}/>
            <circle cx="32.2419" cy="4.59543" r="4.50363" fill={schema}/>
            <path d="M175.977 18.9287C175.977 16.5279 177.876 14.7132 181.226 14.6016H181.924C183.208 14.6574 184.464 14.9924 185.497 15.4391L184.995 17.7562C183.878 17.3375 182.817 17.0862 181.868 17.0862C180.388 17.0862 179.523 17.7841 179.523 18.7054C179.523 18.8171 179.551 18.9008 179.579 18.9846C179.969 20.9667 185.692 21.0784 185.972 24.8472C186 25.0426 186 25.238 186 25.4614C185.972 25.6568 185.944 25.8522 185.888 26.0197H182.259C182.342 25.7685 182.37 25.5172 182.314 25.2101C181.812 22.9767 175.977 22.9488 175.977 18.9287ZM180.277 27.0806H185.469C184.799 28.4764 183.208 29.4535 180.891 29.5652C180.639 29.5931 180.388 29.5931 180.137 29.5931C178.49 29.5094 176.787 28.9789 175.754 28.3927L176.34 26.0756C177.596 26.6618 178.964 27.0806 180.277 27.0806Z" fill={schema}/>
            <path d="M172.607 28.5602C171.267 29.1744 169.369 29.621 167.694 29.621C163.283 29.621 160.547 26.606 160.547 22.1113C160.547 20.5479 160.882 19.18 161.496 18.1191H164.818C164.399 18.7054 164.092 19.5708 163.981 20.7154L169.704 20.6596C169.871 18.9008 169.313 17.0583 167.275 17.0583H162.222C163.367 15.467 165.237 14.6016 167.415 14.6016C171.965 14.6016 173.584 18.3146 172.803 22.3626C171.128 22.8372 166.772 22.9488 164.037 22.893C164.316 25.8801 166.13 26.9131 168.308 26.9131C169.592 26.9131 171.044 26.606 172.105 26.2431L172.607 28.5602Z" fill={schema}/>
            <path d="M149.439 26.7735C150.165 26.7735 150.863 26.5781 151.505 26.2431C151.533 27.1085 151.757 27.8623 152.036 28.3368C150.835 29.2023 149.412 29.5931 148.072 29.5931C145.475 29.5931 143.828 28.1693 143.828 25.0984V15.2716L147.29 14.6016V24.1213C147.29 25.7685 147.709 26.7735 149.439 26.7735ZM156.223 29.5931C153.934 29.5931 152.65 28.0298 152.65 25.5451V14.9366H156.112V25.3218C156.112 26.3826 156.586 27.0247 157.508 27.0247C157.787 27.0247 158.038 26.9968 158.345 26.941V29.0627C157.815 29.3698 157.061 29.5931 156.223 29.5931Z" fill={schema}/>
            <path d="M139.372 29.2573H135.91V8.65439L139.372 7.98438V29.2573Z" fill={schema}/>
            <path d="M125.233 14.6016C129.058 14.6016 130.37 16.3603 130.37 19.9617V20.3804H126.964C126.824 18.51 126.35 17.3654 124.395 17.3654C123.362 17.3654 121.967 17.6166 120.208 18.2029L119.705 15.6624C121.743 15.0203 123.335 14.6016 125.233 14.6016ZM124.2 27.2202C124.73 27.2202 125.345 27.0806 125.903 26.8852C126.015 27.5831 126.21 28.1973 126.517 28.6998C125.4 29.2581 124.172 29.5931 123.027 29.5931C120.543 29.5931 118.84 28.1135 118.84 25.7405C118.84 23.1442 120.85 21.8042 124.786 21.553C125.233 21.525 125.68 21.4971 126.126 21.4971H126.35L128.025 21.4692H130.37V24.4284C130.37 24.8751 130.398 25.2939 130.453 25.6568C130.537 26.5501 130.984 27.0247 131.849 27.0247C132.101 27.0247 132.38 26.9968 132.659 26.9689V29.0627C132.268 29.286 131.738 29.4815 131.096 29.5373C130.705 29.5652 130.314 29.5652 129.951 29.5373C128.443 29.3419 127.494 28.4485 127.187 26.7735C127.075 25.7964 126.992 24.7355 126.992 23.5351H125.624C125.317 23.5351 125.037 23.563 124.814 23.563C122.86 23.6747 122.134 24.3726 122.134 25.4893C122.134 26.6339 122.916 27.2202 124.2 27.2202Z" fill={schema}/>
            <path d="M106.633 14.6016L111.686 29.2581H108.308L103.395 15.2437L106.633 14.6016ZM117.214 14.9366L112.635 28.2252L111.1 23.5072L113.975 14.9366H117.214Z" fill={schema}/>
            <path d="M91.5828 14.6016V29.2581H88.1211V15.2716L91.5828 14.6016ZM100.656 19.0962V29.2581H97.1942V20.4363C97.1942 18.4262 96.7475 17.4491 94.9887 17.4491C94.2071 17.4491 93.4254 17.6166 92.6995 17.9237V15.4949C93.7883 14.9087 95.0446 14.6016 96.2729 14.6016C98.8972 14.6016 100.656 16.0253 100.656 19.0962Z" fill={schema}/>
            <path d="M83.7985 28.5602C82.4585 29.1744 80.5601 29.621 78.8851 29.621C74.4742 29.621 71.7383 26.606 71.7383 22.1113C71.7383 20.5479 72.0733 19.18 72.6875 18.1191H76.0096C75.5909 18.7054 75.2838 19.5708 75.1721 20.7154L80.8951 20.6596C81.0626 18.9008 80.5043 17.0583 78.4663 17.0583H73.4133C74.5579 15.467 76.4284 14.6016 78.6059 14.6016C83.1564 14.6016 84.7756 18.3146 83.9939 22.3626C82.3189 22.8372 77.9638 22.9488 75.2279 22.893C75.5071 25.8801 77.3217 26.9131 79.4993 26.9131C80.7835 26.9131 82.2352 26.606 83.296 26.2431L83.7985 28.5602Z" fill={schema}/>
            <path d="M61.1927 29.7606C60.4668 29.7048 59.7968 29.649 59.0989 29.5652C58.4289 30.1515 58.0101 30.9052 58.0101 31.8823C58.0101 31.9661 58.0101 32.0778 58.038 32.1615C58.1776 33.4736 59.3781 34.2832 61.3602 34.2832C62.5327 34.2832 63.5936 34.0599 64.3194 33.7528V36.1258C63.454 36.4049 62.3652 36.5724 61.2485 36.5724C57.2843 36.5724 55.023 34.9532 54.8555 32.7478V32.2732C54.9671 30.8215 55.8884 29.7885 57.3401 28.8673C56.5305 28.3089 56.0001 27.4435 56.0001 26.4664C56.0001 26.3826 56.0001 26.3268 56.028 26.2431C56.1117 25.2101 56.7538 24.4564 57.6193 23.8143C56.3351 22.9488 55.5813 21.5809 55.5813 19.7104C55.5813 19.6267 55.5813 19.5429 55.6092 19.4592C55.7209 16.3045 58.2893 14.6016 61.6114 14.6016C62.784 14.6016 63.8448 14.8249 64.7382 15.2157L69.0653 14.6016V17.142H62.9515C62.6444 17.1141 62.2815 17.0862 61.9464 17.0862C61.6114 17.0024 61.2764 16.9745 60.9135 17.0304C59.6293 17.2258 58.8476 18.2587 58.8476 19.7104C58.8476 19.9338 58.8756 20.1292 58.9314 20.3246C59.1547 21.6646 60.1318 22.5021 61.5277 22.5021C63.1748 22.5021 64.2636 21.4413 64.2636 19.7663C64.2636 19.1521 64.0961 18.6496 63.8727 18.2308H67.2228C67.4182 18.6775 67.502 19.18 67.502 19.7663C67.502 23.0605 64.8778 24.8193 61.4439 24.8193H61.2206C60.4948 24.7914 59.8527 24.7076 59.2664 24.5122C58.9872 24.8193 58.8197 25.1543 58.8197 25.573C58.8197 25.7685 58.8476 25.936 58.9314 26.0756C59.071 26.5781 59.5735 26.8851 60.2435 26.9689C60.4389 26.9689 60.6343 26.9968 60.8298 26.9968L63.7332 27.1085C63.8448 27.1085 63.9286 27.1364 64.0123 27.1364C66.8599 27.3318 68.5349 28.7835 68.6745 31.1286C68.6745 31.3798 68.6745 31.6032 68.6466 31.8544C68.4791 33.6132 67.3066 34.9253 65.4082 35.6512V32.6361C65.4082 31.994 65.3244 31.4077 65.1011 30.9052C64.7661 30.2073 63.9844 29.8723 62.7002 29.8165L61.1927 29.7606Z" fill={schema}/>
        </svg>
    );
}

export default Logo;
