import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import EmptyLine from "../ui/EmptyLine";
import {client} from "../http/AJHttp";
import "./BlogPage.less";
import AJLoading from "../ui/AJLoading";
import StartFooter from "../component/start/StartFooter";
import {removeH1Content, SocialShare, InfiniteScrollBlogs} from "./InsightsPage";
import {formatDate} from "../component/BlogCard";

export default function BlogPage(props) {
    let [latestArticle, setLatestArticle] = useState(null);
    let [isLoading, setIsLoading] = useState(true);
    let { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const latestArticle = await client.getArticle(id);
                setLatestArticle(latestArticle);
                setIsLoading(false);
            } catch (error) {
                console.log("error here");
            }
        };
        
        fetchData();
    }, []);

    return (
        <>
            {
                isLoading ? <div style={{transform: `translateY(${window.innerHeight/2 - 60}px)`}}><AJLoading size={50} /></div> : 
                <>
                    <div className={'single-blog-container'}>

                        <div className="single-blog-metadata-container">
                            <div className="single-blog-title">{latestArticle?.headline}</div>
                            
                            <div className="single-blog-metadata">
                                <div className="single-blog-metadata-author">Genvalues</div>
                                <div className="single-blog-metadata-date">
                                    <div className="read-time">{latestArticle?.readingTime} min read</div>
                                    <div className="create-time">{latestArticle?.createdAt && formatDate(latestArticle?.createdAt)}
                                        <div style={{position: 'relative', position: 'relative', left: 14, bottom: 4, display: 'inline'}}>
                                            <SocialShare article={latestArticle} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <EmptyLine height={80}/>
                        <img src="/img/blog/blog-pic1.svg" style={{width: '100%'}} />
                        <EmptyLine height={25}/>
                        <div className="photo-owner">Photo by Claire</div>
                        <EmptyLine height={85}/>
   
                        <div className="single-blog-content" dangerouslySetInnerHTML={{__html: removeH1Content(latestArticle?.html ?? '')}}></div>

                        <EmptyLine height={80}/>
                        <img src="/img/blog/blog-pic2.svg" style={{paddingLeft: '10%', width: '60%'}} />
                        <EmptyLine height={25}/>
                        <div className="photo-owner" style={{paddingLeft: '10%'}}>Photo by Carwan</div>
                        <EmptyLine height={85}/>
                    </div>

                    <div className="more-blogs-container">
                        <EmptyLine height={40}/>
                        <div className="more-blog-title">More Interesting Topics</div>
                        <EmptyLine height={80}/>
                        <InfiniteScrollBlogs ignoreBlogSlug={id}></InfiniteScrollBlogs>
                    </div>

                    <StartFooter/>
                </>
            }
        </>
    )
}
