import React, { useState, useContext } from 'react';
import './KeyCard.less'
import {formatElements} from "../utils/arrayUtil";
import {Col, Row} from "antd";
import EmptyLine from "../ui/EmptyLine";
import PositionsCard from "./PositionsCard";
import VCenterContainer from "../ui/VCenterContainer";
import {alignVMiddle} from "../utils/textUtil";

function KeyCard(props) {

    const [selected, setSelected] = useState(props.selected || false)
    const textLines = props.textLines || ["line1", "line2"]
    const onClick = props.onClick
    const card = props.card
    let cardContainerStyle = props.cardContainerStyle || {}
    const cardTextStyle = props.cardTextStyle || {}
    const disSelectable = props.disSelectable || false
    const highlight = props.highlight

    if (highlight) {
        cardContainerStyle = {...cardContainerStyle}
        cardContainerStyle.background = disSelectable ? (highlight ? '#181414' : "#FFFFFF") : (!selected ? '#FFFFFF' : '#F5F5F5')
    }
    return (
        <div className={'key-card-container'}
             style={{...cardContainerStyle}}
             onClick={() => {
                if (onClick) {
                    if (disSelectable) {
                        onClick(card, true)
                    }
                    else {
                        onClick(card, !selected)
                        setSelected(!selected)
                    }
                }

            }}>

                <div className={'text-lines'}>
                    {
                        alignVMiddle(()=>{
                            return (
                                <div>
                                    {
                                        textLines.map((text, index) => {
                                            return (
                                                <div key={index} className={'disable-user-select'}
                                                     style={{...cardTextStyle}}>
                                                    {text}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }

                </div>

        </div>
    )
}

export function SelectableKeyCard(props) {

    const {onClick, valueKey, selected, textLines, cardTextStyle} = props
    // const [selected, setSelected] = useState(props.selected)
    const selectedClass = selected ? 'selectable-key-card-container-selected' : ''
    return (
        <div
            className={`selectable-key-card-container ${selectedClass}`}
            onClick={()=>{
                if (onClick) {
                    onClick(valueKey)
                }

            }}>
            {
                alignVMiddle(()=>{
                    return (
                        <div className={'text-lines'}>
                            {
                                textLines.map((text, index) => {
                                    return (
                                        <div className={'disable-user-select'}
                                             style={{...cardTextStyle}}>
                                            {text}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )

}

export function formatKeyCards(cards, count, spaceHeight, onClick, cardContainerStyle, cardTextStyle) {
    return formatElements(cards,
        count,
        spaceHeight,
        (card) => <KeyCard
            selected={card.selected}
            card={card}
            textLines={card.textLines}
            cardContainerStyle={cardContainerStyle}
            cardTextStyle={cardTextStyle}
            onClick={(card, selected)=>{
                if (onClick) {
                    onClick(card, selected)
                }
            }}/> )

}

export function formatNoneSelectableKeyCards(cards, count, spaceHeight, onClick, cardContainerStyle, cardTextStyle, highlight, toolTips) {

    return formatElements(cards,
        count,
        spaceHeight,
        (card, row, col) => {
        const keyCard = <KeyCard
            disSelectable={true}
            highlight={highlight ? highlight(card) : null}
            card={card}
            textLines={card.textLines}
            cardContainerStyle={cardContainerStyle}
            cardTextStyle={cardTextStyle}
            onClick={(card, selected)=>{
                if (onClick) {
                    onClick(card, true)
                }
            }}/>
        if (toolTips && row === 0 && col === 0) {
            return toolTips(keyCard)
        }
        else {
            return <>{keyCard}</>
        }
    } )

}

export default KeyCard;
