'use strict';

const React = require('react'),
    PropTypes = require('prop-types'),
    withSideEffect = require('react-side-effect');

function reducePropsToState(propsList) {
    const innermostProps = propsList[propsList.length - 1];
    if (innermostProps) {
        return [innermostProps.title, innermostProps.description, innermostProps.image, innermostProps.url];
    }
}

function handleStateChangeOnClient([title, description, image, url]) {
    const nextTitle = title || 'Genvalues: Find Engineering teams based on your values';
    const nextDescription = description || 'Engineering teams describe their values & mission and how it reflects into daily practices to attract top talents.'
    const nextImage = image || `${window.location.origin}/img/home-page.jpg`
    const nextUrl = url || window.location.href;
    const metas = document.getElementsByTagName('meta')
    const update = (meta, content) => {
        if (meta && content !== meta.getAttribute('content')) {
            meta.setAttribute('content', content)
        }
    }
    for (const m in metas) {
        try {
            const meta = metas[m]
            const metaName = meta.getAttribute('name') || meta.getAttribute('property')
            switch (metaName) {
                case 'og:title':
                    update(meta, nextTitle)
                    break
                case 'description':
                case 'og:description':
                case 'twitter:description':
                    update(meta, nextDescription)
                    break;
                case 'og:url':
                    update(meta, nextUrl)
                    break
                case 'og:image':
                    update(meta, nextImage)
                    break

            }
        }
        catch (e) {
            // console.log(m)
            // console.log(metas[m])
        }

    }
    if (nextTitle !== document.title) {
        document.title = nextTitle;
    }
}

function DocumentTitle() {}
DocumentTitle.prototype = Object.create(React.Component.prototype);

DocumentTitle.displayName = 'DocumentTitle';
DocumentTitle.propTypes = {
    title: PropTypes.string.isRequired
};

DocumentTitle.prototype.render = function() {
    if (this.props.children) {
        return React.Children.only(this.props.children);
    } else {
        return null;
    }
};

export default withSideEffect(
    reducePropsToState,
    handleStateChangeOnClient
)(DocumentTitle);
