import React, {useState} from "react";
import {Icon, Input} from "antd"
import fs from "less/lib/less-node/fs";
import EmptySpan from "./EmptySpan";
import "./AJInput2.less"


export default function AJInput2(props) {
    const placeholder = props.placeholder || props.label
    const newProps = {
        ...props,
        placeholder
    }
    return (
        <div className={'aj-input-2'}>
            <Input {...newProps} />
        </div>
    )
}

export function AJMandatoryInput(props) {
    const placeholder = props.placeholder || props.label
    const style = {
        color : props.color || '#FF3572',
        textAlign: 'right'
    }
    return (
        <div className={'aj-input-2'}>
            <Input {...props} placeholder={placeholder}
                   suffix={<span style={style}>*</span>}/>
        </div>
    )
}

export function AJAutoCheckInput2(props) {
    const placeholder = props.placeholder || props.label
    const style = {
        color : props.color || '#FF3572',
        textAlign: 'right'
    }

    const onChangeOrig = props.onChange || (()=>{})

    const verifyFun = props.verifyFun || (() => true)
    const defaultValue = props.defaultValue

    const [showIcon, setShowIcon] = useState(defaultValue? verifyFun(defaultValue) : false)
    const onChange = (e) => {
        const value = e.target.value
        if (value && value.length > 0 && verifyFun(value)) {
            setShowIcon(true)
            onChangeOrig(value)
        }
        else {
            setShowIcon(false)
            onChangeOrig(null)
        }
    }

    return (
        <div className={'aj-input-2'}>
            <Input {...props}
                   placeholder={placeholder}
                   addonAfter={<div style={{opacity: showIcon ? 1.0 : 0}}>
                       <Icon type="check-circle" style={{color: 'green'}}/>
                   </div>}
                   suffix={<>
                       {
                           props.mandatory ? <>
                               <EmptySpan width={4}/>
                               <span style={style}>*</span>
                               </>
                               :<span/>
                       }
                   </>}
                   onChange={onChange}/>
        </div>
    )
}

export function AJMandatoryAutoCheckInput(props) {
    return (<AJAutoCheckInput2 {...props} mandatory={true}/>)
}

export function AJEmailInput(props) {
    return <AJAutoCheckInput2 {...props} verifyFun={(value)=>{
        const pattern = props.regex || /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;
        return pattern.test(value)
    }}/>

}

export function AJPhoneInput(props) {
    return <AJAutoCheckInput2 {...props} verifyFun={(value)=>{
        const pattern = props.regex || /^[0-9\-\+]+$/;
        return pattern.test(value)
    }}/>

}

///^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
export function AJUrlInput(props) {
    return <AJAutoCheckInput2 {...props} verifyFun={(value)=>{
        const pattern = props.regex || /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i;
        return pattern.test(value)
    }}/>

}
export function AJValidateInput(props) {

    const {validate, onChange} = props
    const placeholder = props.placeholder || props.label

    const onChangeWrapper = (e) => {
        if (validate(e.target.value)) {
            // show right icon
        }
        if (onChange) onChange(e)
    }

    return (
        <div className={'aj-input-2'}>
            <Input {...props} onChange={onChange} placeholder={placeholder}/>
        </div>
    )

}

export function AJPasswordInput2(props) {
    const placeholder = props.placeholder || props.label
    const newProps = {
        ...props,
        placeholder
    }
    return (
        <div className={'aj-input-2'}>
            <Input.Password {...newProps} />
        </div>
    )

}


export function AJCheckPasswordInput2(props) {

    const onChangeOrig = props.onChange || (()=>{})
    const placeholder = props.placeholder || props.label

    const verifyFun = props.verifyFun || (() => true)
    const defaultValue = props.defaultValue
    const showIcon = props.showIcon || false


    // const [showIcon, setShowIcon] = useState(defaultValue? verifyFun(defaultValue) : false)
    // const onChange = (e) => {
    //     const value = e.target.value
    //     if (value && value.length > 0 && verifyFun(value)) {
    //         console.log(value)
    //         setShowIcon(true)
    //         onChangeOrig(value)
    //     }
    //     else {
    //         setShowIcon(false)
    //         onChangeOrig(null)
    //     }
    // }

    return (
        <div className={'aj-input-2'}>
            <Input.Password {...props}
                   placeholder={placeholder}
                   addonAfter={<div style={{opacity: showIcon ? 1.0 : 0}}>
                       <Icon type="check-circle" style={{color: 'green'}}/>
                   </div>}
                   onChange={onChangeOrig}/>
        </div>
    )
}
