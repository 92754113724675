import React, {useState} from "react";
import {Input} from "antd";
const { TextArea } = Input;

export default function AJEditableDiv(props) {

    const {editable,
        style,
        className,
        text,
        onChange,
        onPressEnter,
        isInput,
        fontSize,
        autoSize,
        disablePreLine,
        } = props

    const input = (text) => {
        return (<Input
            // id={`aj-input-${value}`}
            defaultValue={text}
            onChange={onChange}
            onPressEnter={onPressEnter}
            style={{fontSize}}
            />)
    }

    const textArea = (text) => {

        return (<TextArea
                // id={`aj-text-area-${Math.random()}`}
                defaultValue={text}
                autoSize={props.autoSize || { minRows: 3 }}
                onChange={onChange}
                onPressEnter={onPressEnter}
                style={{fontSize}}
                />
        )
    }


    return (
        <div style={{...style}} className={className}>
            {
                !editable ?
                    <div style={!disablePreLine ? {whiteSpace: 'pre-line'} : null}> {text} </div>
                    : isInput ? input(text)
                    : textArea(text)
            }
        </div>
    )
}