import React from "react";
import EmptyLine from "../ui/EmptyLine";
import StartFooter from "../component/start/StartFooter";

import "./ContactPage.less"
import AJCard from "../ui/AJCard";
import {Col, Row} from "antd";


export default function ContactPage(props) {

    const minTextStyle = {
        fontFamily: 'InterMedium',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 19,
        lineHeight: '48px'
    }

    const middleTextStyle = {
        fontFamily: 'InterRegular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 34,
        lineHeight: '48px',
        cursor: 'pointer'
    }


    return <div className={'contact-page'}>
        <div className={'contact-page-container'}>
            {/*<div className={'contact-page-title'}>*/}
            {/*    Contact Genvalues*/}
            {/*</div>*/}
            <div className={'contact-page-title-container'}>
                <Row type={'flex'} style={{height: '100%'}}>
                    <Col span={5} style={{padding: '31px 40px'}}>
                        <div style={{
                            fontFamily: 'InterSemiBold',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: 35,
                            lineHeight: '45px'
                        }}>
                            Contact
                        </div>
                    </Col>
                    <Col span={8}>
                        <EmptyLine height={39}/>
                        <div style={minTextStyle}>
                            Write to us
                        </div>
                        <EmptyLine height={22}/>

                        <div style={middleTextStyle}>
                            <div onClick={() => {
                                window.open("mailto:team@genvalues.com")
                            }}>
                                team@genvalues.com
                            </div>
                        </div>
                    </Col>
                    <Col span={2}/>
                    <Col span={8}>
                        {/*<EmptyLine height={39}/>*/}
                        {/*<div style={minTextStyle}>*/}
                        {/*    Talk to us*/}
                        {/*</div>*/}
                        {/*<EmptyLine height={22}/>*/}

                        {/*<div style={middleTextStyle}>*/}
                        {/*    <div onClick={() => {*/}
                        {/*        window.open("tel:+46(0)732802261")*/}
                        {/*    }}>*/}
                        {/*        +46(0) 732 80 22 61*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Col>
                </Row>
            </div>
            <EmptyLine height={149}/>
            <div>
                <div className={'contact-page-description-container'}>
                    <Row type={'flex'}>
                        <Col span={12}>
                            <div className={'contact-page-description-title'}>
                                Showcase your<br/>product mission,<br/>guiding principles,<br/>and team values to<br/>the modern talent.
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={'contact-page-description-body'}>
                                Genvalues revolutionizes the way talent is attracted, reshaping the interaction between engineering teams and prospective candidates. Our innovative platform enables engineering teams to articulate and exhibit their core principles, values, and the fundamental goals of their product mission, thereby creating a significant impact. This approach transcends mere job descriptions; it's about vividly illustrating the team's culture and everyday working environment.<br/><br/>
                                For engineers, understanding how they can integrate into an organization is crucial. The ability of companies to effectively communicate this vision grants them a considerable advantage in the highly competitive arena of talent acquisition.<br/><br/>
                                Upon visiting Genvalues.com, engineers engage in a process of selecting factors that are important to them. This selection process encompasses not just Engineering principles and values but also their preferred technological environments.<br/><br/>
                                We will love to hear from you if you have any questions about our platform, our team, or if you want to list your engineering team on Genvalues. 
                            </div>
                        </Col>
                    </Row>
                </div>


            </div>
        </div>
        <StartFooter/>
    </div>
}
