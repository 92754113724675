import React, {useState} from "react";

import './AJCheckbox.css'
import EmptySpan from "./EmptySpan";
import SvgCheckboxChecked from "../icons/SvgCheckboxChecked";
import SvgCheckboxNone from "../icons/SvgCheckboxNone";

function AJCheckbox(props) {

    let {title, onClick, titleClass, space, onChange, titleStyle} = props

    onChange = onChange || function () {}
    titleClass = titleClass || 'default-ajcheckbox-text'
    space = space || 20

    const [checked, setChecked] = useState(props.checked)

    function toggleChecked() {
        setChecked(!checked)
        onChange(!checked, title)
    }


    return (
        <div className={'ajcheckbox-container'}>
            <div className={'default-ajcheckbox-icon-container'} onClick={toggleChecked}>
                { !checked ? <SvgCheckboxNone height={16} width={16}/> : <SvgCheckboxChecked height={16} width={16}/>}
            </div>
            <EmptySpan width={space}/>
            <div className={`${titleClass} disable-user-select`} style={{...titleStyle}}>
                {title}
            </div>
        </div>
    )
}

export default AJCheckbox