import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';


function VCenterContainer(props) {

    return (
        <Row type="flex" justify="space-around" align="middle" className={props.className || ''}>
            <Col>
                { props.children }
            </Col>
        </Row>
    )
}

export function HCenterContainer(props) {
    return (
        <Row type="flex" justify="center" align="middle" style={{height: '100%'}}>
            <Col>
                { props.children }
            </Col>
        </Row>
    )

}

export default VCenterContainer
