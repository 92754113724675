import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
    values: [],
    matches: [],
    unfilteredMatches: [],
    page: 1,
    done: false,

    allCompaniesValues: [],
    allCompaniesMatches: [],
    allCompaniesUnfilteredMatches: [],
    allCompaniesPage: 1,
    allCompaniesDone: false,

    topMatches: true,
    topMatchesNumber: null
};

export const slice = createSlice({
    name: 'start',
    initialState: initialState,
    reducers: {
        setMatches: {
            reducer(state, action) {
                state.matches = action.payload.matches;
            },
            prepare(matches) {
                return {
                    payload: { matches }
                };
            }
        },
        setUnfilteredMatches: {
            reducer(state, action) {
                state.unfilteredMatches = action.payload.unfilteredMatches;
            },
            prepare(unfilteredMatches) {
                return {
                    payload: { unfilteredMatches }
                };
            }
        },
        setPage: {
            reducer(state, action) {
                state.page = action.payload.page;
            },
            prepare(page) {
                return {
                    payload: { page }
                };
            }
        },
        setDone: {
            reducer(state, action) {
                state.done = action.payload.done;
            },
            prepare(done) {
                return {
                    payload: { done }
                };
            }
        },

        setAllCompaniesMatches: {
            reducer(state, action) {
                state.allCompaniesMatches = action.payload.allCompaniesMatches;
            },
            prepare(allCompaniesMatches) {
                return {
                    payload: { allCompaniesMatches }
                };
            }
        },
        setAllCompaniesUnfilteredMatches: {
            reducer(state, action) {
                state.allCompaniesUnfilteredMatches = action.payload.allCompaniesUnfilteredMatches;
            },
            prepare(allCompaniesUnfilteredMatches) {
                return {
                    payload: { allCompaniesUnfilteredMatches }
                };
            }
        },
        setAllCompaniesPage: {
            reducer(state, action) {
                state.allCompaniesPage = action.payload.allCompaniesPage;
            },
            prepare(allCompaniesPage) {
                return {
                    payload: { allCompaniesPage }
                };
            }
        },
        setAllCompaniesDone: {
            reducer(state, action) {
                state.allCompaniesDone = action.payload.allCompaniesDone;
            },
            prepare(allCompaniesDone) {
                return {
                    payload: { allCompaniesDone }
                };
            }
        },
        setTopMatches: {
            reducer(state, action) {
                state.topMatches = action.payload.topMatches;
            },
            prepare(topMatches) {
                return {
                    payload: { topMatches }
                };
            }
        },
        setTopMatchesNum: {
            reducer(state, action) {
                state.topMatchesNumber = action.payload.topMatchesNumber;
            },
            prepare(topMatchesNumber) {
                return {
                    payload: { topMatchesNumber }
                };
            }
        }

    }
});

export const selectMatchesDone = state => state.start.done;
export const selectMatches = state => state.start.matches;
export const selectUnfilteredMatches = state => state.start.unfilteredMatches;
export const selectMatchesPage = state => state.start.page;

export const selectAllCompaniesMatchesDone = state => state.start.allCompaniesDone;
export const selectAllCompaniesMatches = state => state.start.allCompaniesMatches;
export const selectAllCompaniesUnfilteredMatches = state => state.start.allCompaniesUnfilteredMatches;
export const selectAllCompaniesMatchesPage = state => state.start.allCompaniesPage;

export const selectTopMatches = state => state.start.topMatches;
export const selectTopMatchesNum = state => state.start.topMatchesNumber;
export const { setDone, setMatches, setUnfilteredMatches, setPage, setAllCompaniesDone, setAllCompaniesMatches, setAllCompaniesUnfilteredMatches, setAllCompaniesPage, setTopMatches, setTopMatchesNum } = slice.actions;

export default slice.reducer;