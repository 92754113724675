import React from "react";
import "./Contact.less"
import {Row, Col} from "antd";

export default function Contact(props) {

    const onClose = props.onClose || (()=>{})

    return (
        <div className={'airjobb-contact'}>
            <div className={'airjobb-contact-container'}>
                <Row>
                    <div className={'airjobb-contact-font-1'}>
                        Contact us
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div className={'airjobb-contact-font-2'}>
                        Phone
                    </div>
                    <div className={'airjobb-contact-font-1'}>
                        +46 73 280 22 61
                    </div>
                    <br/>
                    <div className={'airjobb-contact-font-2'}>
                        Mail
                    </div>
                    <div className={'airjobb-contact-font-1'}>
                        team@genvalues.com
                    </div>
                    <div className={'airjobb-contact-mail'}>
                        <img src={'/img/login/mail.svg'} height={245} width={245}/>
                    </div>
                    <div className={'airjobb-contact-close'} onClick={onClose}>
                        <img src={'/img/login/close.svg'} height={20} width={20}/>
                    </div>
                </Row>
            </div>
        </div>
    )
}
