import React, {useState} from "react";
import JobOpeningsContext from "./JobOpeningsContext";
import {getJobOpeningsSourceAndUrl, saveJobOpeningsSourceAndUrl} from "../storage/AJStorage";


export default function JobOpeningsProvider(props) {
    const [jobOpeningsSource, setJobOpeningsSource] = useState(null)
    const [careerPageUrl, setCareerPageUrl] = useState(null)
    const [jobOpenings, setJobOpenings] = useState([])
    return (
        <JobOpeningsContext.Provider value={{
            jobOpeningsSource: () => {
                return getJobOpeningsSourceAndUrl().source
            },
            setJobOpeningsSource: (source) => {

                const savedSource = getJobOpeningsSourceAndUrl().source
                if (savedSource !== source) {
                    saveJobOpeningsSourceAndUrl(source, null)
                }

                //return saveJobOpeningsSourceAndUrl(source, getJobOpeningsSourceAndUrl().sourceUrl)
            },
            careerPageUrl : () => {
                return getJobOpeningsSourceAndUrl().sourceUrl
            },
            setCareerPageUrl: (sourceUrl) => {
                return saveJobOpeningsSourceAndUrl(getJobOpeningsSourceAndUrl().source, sourceUrl)

            },
            jobOpenings : jobOpenings,
            setJobOpenings : setJobOpenings
        }}>
            {props.children}
        </JobOpeningsContext.Provider>
    )
}