import React from "react";

function SvgTwitter(props) {
  let {width = 30, height = 30, scheme = 'white'} = props;
  
  return (
    <svg style={{'width': `${width}px`, 'height': `${height}px`, 'color': 'black'}} width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.2181 15.6336L34.6869 2.51562H31.7332L20.902 13.9035L12.2575 2.51562H2.28467L15.3596 19.7377L2.28467 33.4923H5.23834L16.669 21.4637L25.8002 33.4923H35.773M6.30439 4.53156H10.8421L31.731 31.5754H27.1922" fill={scheme} />
    </svg>
  );
}

export default SvgTwitter;
