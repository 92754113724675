import React, {useEffect, useState} from "react";

import AJModalV2 from "../ui/AJModalV2";
import AJCard from "../ui/AJCard";
import EmptyLine from "../ui/EmptyLine";
import {Col, Row} from "antd";
import AJButton3 from "../ui/AJButton3";

import "./HowItWorks.less"
import {getJoinedCommunity, getNotFTU, isLogged, isLogon, setNotFTU} from "../storage/AJStorage";


export default function HowItWorks (props) {

    const offset = window.innerWidth - 260
    const [cardClass, setCardClass] = useState('how-it-works')
    useEffect(() => {
        // if (!getNotFTU()) {
        //     setNotFTU()
        //     setTimeout(()=>{
        //         setCardClass('how-it-works-show')
        //         setTimeout(() => {
        //             setCardClass('how-it-works')
        //         }, 10000)
        //     }, 5000)
        // }

    }, [])
    return (
        <div className={cardClass}>
            <HowItWorksContent onClose={() => {
                setCardClass('how-it-works')
            }}/>
        </div>
    )
}

export function HowItWorksContent(props) {
    const onClose = props.onClose || (() => {})

    const worksContent = [
        'Create an account',
        'Pick your most important 9 values among 7 categories',
        'Describe genuinely about each value',
        'Upload pictures/videos that relate to your values',
        'Link job openings easily to your profile',
        'Talents find you based on your values and apply to openings'
    ]

    const worksContentTalent = [
        'Pick your most important values',
        'Be matched with companies that share your values',
        'Apply to the job meaningful for you'
    ]

    const content = props.talent ?  worksContentTalent : worksContent

    return (
        <AJCard width={935}
                height={'infinity'}
                borderRadius={22}
                type={'wide'}
                hideClose={true}>
            <div className={'how-it-works-container'}>
                <div className={'how-it-works-title'}>
                    How it works
                </div>
                <EmptyLine height={33}/>
                {content.map((content, index) => {
                    return (<><Row type="flex" align="middle">
                        <Col span={2}>
                            <div className={'how-it-works-index'}>
                                <Row type="flex" justify="space-around" align="middle" style={{height: '100%'}}>
                                    <Col>
                                        {index + 1}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={20}>
                            <div className={'how-it-works-step'}>
                                {content}
                            </div>
                        </Col>


                    </Row>
                        <EmptyLine height={16}/>
                    </>)
                })}
                <EmptyLine height={8}/>
                <div className={'how-it-works-button-container'}>
                    <AJButton3
                        size={'small'}
                        title={"I got it!"}
                        titleStyle={{'color': 'white'}}
                        scheme={'dark-purple'}
                        width={105} height={38}
                        onClick={onClose}/>
                </div>
            </div>
        </AJCard>
    )
}
