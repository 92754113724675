import React from "react";
import "./AJAvatar.less"
import {HCenterContainer} from "./VCenterContainer";

export default function AJAvatar(props) {
    const {size, image} = props
    return (
        <div className={'aj-avatar'} style={{
            width : size,
            height: size,
        }}>
            <HCenterContainer>
                {props.children}
            </HCenterContainer>
        </div>
    )
}