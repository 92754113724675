import React, {useState, useCallback} from 'react';
import EmptyLine from "../ui/EmptyLine";
import {formatElements} from "../utils/arrayUtil";
import "./PositionsCard.less";
import {AJButton4} from "../ui/AJButton3";
import {AJImageUrl} from "../http/AJHttpConst";
import {useHistory} from "react-router";
import {isSafari} from "../utils/modalUtil";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';

function PositionsCard(props) {
    const {number, image, data} = props

    const [buttonName, setButtonName] = useState('Visit profile')
    const [background, setBackground] = useState('#FFF')
    const [textColor, setTextColor] = useState('#000')

    let additionalClass = ''
    if (!isSafari()) {
        additionalClass = 'positions-card-hover'
    }

    const companyLogo = useCallback((props) => {
        const {imgUrl, alt} = props
        return (
            <div className={'company-logo'}>
                <img src={imgUrl} alt={alt}/>
            </div>
        )
    }, [image]);

    const history = useHistory()
    return (
        <div key={data.company_id} className={`positions-card ${additionalClass}`} id={`position-card-${data.company_id}`} onClick={() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'custom_event',
                'eventCategory':'profile',
                'eventAction': 'click',
                'eventlabel': data.company_name
            });
            history.push(`/company-profile/${data.company_id}`)
        }}>
            <EmptyLine height={20}/>
            {image ? companyLogo({imgUrl: image, alt: `${data.company_name}'s logo`})
                : <div className={'positions-card-title'}>
                      {data.company_name}
                </div>}
            <EmptyLine height={9}/>
            <div className={'positions-card-info-number'}>
                {number}</div>
            <div className={'positions-card-info-position'}>Open positions</div>
            <EmptyLine height={18}/>
            <div style={{width: '100%', textAlign: 'center'}}>
                <AJButton4 title={buttonName}
                           size={'mini'}
                           width={269}
                           type={'secondary'}
                           height={46}
                           titleStyle={{
                               fontSize: 24,
                               fontWeight: 400,
                               fontFamily: 'Helvetica',
                               color: textColor
                           }}
                           customStyle={{
                               borderRadius: 0,
                               background: background
                           }}
                           onMouseEnter={() => {
                               setBackground('#000')
                               setTextColor('#FFF')
                           }}
                           onMouseLeave={() => {
                               setBackground('#FFF')
                               setTextColor('#000')
                           }}
                           onClick={() => {
                           }}/>
            </div>
        </div>
    )
}

export function formatPositionsCard(cards, count, spaceHeight) {

    return formatElements(cards,
        count,
        spaceHeight,
        (card, index1, index2) => {
            const image = card.logoUrl
            if (card.type === 'skeleton') {
                return <div key={`${index1} - ${index2}`}>
                    <PositionsCardSkeleton index={`${index1} - ${index2}`}/>
                </div>
            }
            return (
                <div key={card.company_id}>
                    <PositionsCard data={card} number={card.job_count} image={AJImageUrl(image)}/>
                </div>
            )
        }, 'start')

}

export function PositionsCardSkeleton({index}) {
    let additionalClass = isSafari() ? '' : 'positions-card-hover';
    return <div key={index} className={`positions-card ${additionalClass}`}>
        <EmptyLine height={20}/>
        <div className={'company-logo'} style={{boxShadow: 'none'}}>
            <Skeleton
                circle
                height="100%"
                containerClassName="avatar-skeleton"
            />
        </div>
        <EmptyLine height={9}/>
        <div className={'positions-card-info-number'}>
            <Skeleton width={60} />
        </div>
        <div className={'positions-card-info-position'}>
            <Skeleton width={140} />
        </div>
        <EmptyLine height={18}/>
        <div style={{textAlign: 'center'}}>
                <Skeleton width={269} height={46} />

        </div>
    </div>
}

export default PositionsCard;
