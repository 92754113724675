import React, { Component } from "react";
import RichTextEditor from "react-rte";
import "./AJRichEditor.less";

class AJRichEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: RichTextEditor.createValueFromString(props.defaultValue || '', 'html')
        }
    }

    onChange = value => {
        this.setState({ value });
        if (this.props.onChange) {
            this.props.onChange(value.toString("html"));
        }
    };

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                {label: 'Italic', style: 'ITALIC'},
                {label: 'Underline', style: 'UNDERLINE'}
            ],
            BLOCK_TYPE_DROPDOWN: [
                {label: 'Normal', style: 'unstyled'},
                {label: 'Heading Large', style: 'header-one'},
                {label: 'Heading Medium', style: 'header-two'},
                {label: 'Heading Small', style: 'header-three'}
            ],
            BLOCK_TYPE_BUTTONS: [
                {label: 'BLOCKQUOTE', style: 'blockquote'},
                {label: 'UL', style: 'unordered-list-item'},
                {label: 'OL', style: 'ordered-list-item'},
                // {label: 'PRE', style: 'code-block'}
            ]
        };

        return (
            <div style={{width: this.props.width || '100%', minHeight: this.props.minHeight || 200}}>
                <RichTextEditor
                    className={'text-editor'}
                    //style={{minHeight: this.props.minHeight || 200}}
                    placeholder={this.props.placeholder || 'Give concrete examples of how your tech team or company practices this value and reflects the day-to-day work.'}
                    value={this.state.value}
                    onChange={this.onChange}
                    toolbarConfig={toolbarConfig}
                />
            </div>
        );
    }
}


export function AJEditableDiv2(props) {

    const {readonly, defaultValue, width} = props

    if (readonly) {
        return <div className={'aj-rich-editor'} style={{width}}
                           dangerouslySetInnerHTML={{__html: defaultValue}}/>
    }

    return (
        <AJRichEditor {...props} />
    )

}

export default AJRichEditor;
