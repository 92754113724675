import React from "react"
import Logo from "../icons/Logo";

export default function AJLogo(props) {
    let {schema} = props;
    schema = schema || 'white';
    const logo = <Logo schema={schema} />;

    return <>{logo}</>
}
