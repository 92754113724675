import React from 'react';
import './imageScrollPlayer.less';

export default function Slider({ children, durationInMs = '2000', start = 0, customContainerStyle = {} }) {
  const count = React.Children.toArray(children).length;

  const sx = {
    animationDuration: `${durationInMs * count}ms`,
    left: start,
  }

  const containerStyle = {
    'padding-top': '4px',
    'padding-bottom': '4px',
    ...customContainerStyle,
  }

  return (
    <div className="container" style={containerStyle}>
      <div className="content" style={sx}>
        {children}
      </div>
      <div className="content" style={sx}>
        {children}
      </div>
    </div>
  )
}
