import React, {useState} from "react";
import CreateAccountContext from "./CreateAccountContext";


function CreateAccountProvider(props) {

    const accountInfoField = [
        // "companyName",
        "username",
        "email",
        // 'org.Nr',
        'phone',
        'password1',
        'password2',
        'agree'
    ]

    const [accountInfo, setAccountInfo] = useState({});

    return (
        <CreateAccountContext.Provider value={{
            accountInfo : accountInfo,
            updateAccountInfo : function (key, value) {
                // console.log(`update ${key} to ${value}`)
                accountInfo[key] = value
                setAccountInfo(accountInfo)
            },
            setAccountInfo : setAccountInfo,
            checkAccountInfoCompleted : function (checkFun) {
                checkFun = checkFun || (() => true)
                for(const k of accountInfoField) {
                    if (k === 'phone') {
                        continue
                    }
                    if (!accountInfo[k] || accountInfo[k] === '' || !checkFun(accountInfo, k)) {
                        console.log(`${k} is not set`)
                        return false
                    }
                }
                return true
            }
        }}>
            {props.children}
        </CreateAccountContext.Provider>
    )
}

export default CreateAccountProvider
