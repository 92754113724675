import React from "react"
import EmptyLine from "../../ui/EmptyLine";
import AJModalV2 from "../../ui/AJModalV2";
import StartFooter from "../../component/start/StartFooter";

import "./PrisePage.less"
import FunctionsCard from "./FunctionsCard";
import PriceRequestCard from "./PriceRequestCard";
import {Col, Row} from "antd";
import CEOReviewCard from "../../component/ceo-review/CEOReviewCard";


export default function PricePage() {
    return <>
        <div className={'price-page-container'}>
            <div className={'price-page-header-container'}>
                <div className={'price-page-header-title'}>
                    Showcase your product mission, guiding principles, and team values to the modern talent.
                </div>
            </div>
            <div className={'price-page-content-container'}>
                <div className={'price-page-content-left'}>
                    <div>
                        <div className={'price-page-content-title'}>
                            List of functions
                        </div>
                        <EmptyLine height={30}/>

                        <div className={'price-page-content-description'}>
                            Get in touch - book your free demo now.
                        </div>
                        <EmptyLine height={40}/>
                        <FunctionsCard/>
                    </div>
                </div>
                <div className={'price-page-content-right'}>
                    <div>
                        <div className={'price-page-content-title'}>
                            Price request
                        </div>
                        <EmptyLine height={30}/>
                        <div className={'price-page-content-description'}>
                            We'd like to offer you a plan that suits you best!
                        </div>
                        <EmptyLine height={40}/>
                        <PriceRequestCard/>

                    </div>
                </div>
            </div>
            <div className={'price-page-content-ceo-say'}>
                <EmptyLine height={180}/>
                <div className={'price-page-content-ceo-say-title'}>
                    What CEO’s says about us
                </div>
                <EmptyLine height={64}/>
                <Row justify={'center'} align={'stretch'} type={'flex'} style={{padding: '0 32px'}}>
                    <Col span={8} style={{alignSelf: 'stretch'}}>
                        <CEOReviewCard
                            avatar={'/img/home/CHARLOTTE.png'}
                            introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                                <span style={{fontWeight: 500}}>CHARLOTTE</span><br/>
                                <span style={{fontSize: 10}}>Co-Founder & CEO</span> <br/>
                                <span style={{fontSize: 10}}><i>of Teemyco</i></span>
                            </div>}
                            comment={<>"I love what Genvalues is doing. It’s
                                democratizing hiring and helping
                                teams attract talents based in team
                                values and pushing them to put light
                                on culture. We have received a lot of
                                hoc applications to Teemyco since we
                                got a Genvalues profile."</>}/>
                    </Col>
                    <Col span={8} style={{alignSelf: 'stretch'}}>
                        <CEOReviewCard
                            avatar={'/img/home/STEFAN.png'}
                            introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                                <span style={{fontWeight: 500}}>STEFAN</span><br/>
                                <span style={{fontSize: 10}}>Founder & CEO of</span> <br/>
                                <span style={{fontSize: 10}}><i>CAKE</i></span>
                            </div>}
                            comment={<>
                                "We will definitely speed up our process in terms of identifying and hiring talent. Being able to scan the market with the ability of embracing specific values and directions should promote our ability to be much more efficient in terms of finding the talents."
                            </>}/>
                    </Col>
                    <Col span={8} style={{alignSelf: 'stretch'}}>
                        <CEOReviewCard
                            avatar={'/img/home/RAMZI.png'}
                            introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                                <span style={{fontWeight: 500}}>MIKAELA</span><br/>
                                <span style={{fontSize: 10}}>Founder of</span> <br/>
                                <span style={{fontSize: 10}}><i>Din Psykolog</i></span>
                            </div>}
                            comment={<>
                                "Genvalues helped us shed light on what's unique about our engineering team values & company culture. Their platform made it simple for us to showcase it to potential candidates and future team members. It also made us think twice about what is actually unique about working at Din Psykolog."
                            </>}/>
                    </Col>
                </Row>
                <EmptyLine height={170}/>
            </div>

        </div>

        <StartFooter/>
    </>
}