import React, {
  useMemo,
  useState,
  useRef,
  useLayoutEffect,
  useEffect
} from "react";
import './sliderContainer.less';

const LEFT = "left";
const RIGHT = "right";
const SliderContainer = React.memo(({children, width = "inherit"}, ref) => {
  const listRef = useRef(null);
  const containerRef = useRef(null);
  const containerWidth = useResizeObserver(containerRef);
  const [listWidth, setListWidth] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const cache = useRef(containerWidth);
  const prevChildrenRef = useRef(children);

  useLayoutEffect(() => {
    setListWidth(listRef.current.clientWidth);
    
    const originalTmp = prevChildrenRef.current;
    prevChildrenRef.current = children;
    if (originalTmp.props.children.length === children.props.children.length) {return;}
    setTranslateX(0);
  }, [children]);

  const [leftArrowVisible, rightArrowVisible] = useMemo(() => {
    let leftArrowVisible, rightArrowVisible = false;
    const leftSpaceWidth = Math.abs(translateX);
    const rightSpaceWidth = listWidth - Math.abs(translateX) - containerWidth;
    if (leftSpaceWidth) {
      leftArrowVisible = true;
    }
    if (rightSpaceWidth > 0) {
      rightArrowVisible = true;
    }
    return [leftArrowVisible, rightArrowVisible];
  }, [listWidth, translateX, containerWidth]);

  const handleArrowClick = (direction) => {
    if (direction === LEFT) {
      const leftSpaceWidth = Math.abs(translateX);
      if (leftSpaceWidth > containerWidth) {
        setTranslateX((cur) => cur + containerWidth -400);
      } else {
        setTranslateX((cur) => cur + leftSpaceWidth);
      }
    }
    if (direction === RIGHT) {
      const rightSpaceWidth = listWidth - Math.abs(translateX) - containerWidth;
      if (rightSpaceWidth > containerWidth) {
        setTranslateX((cur) => {
          return cur - containerWidth + 400;});
      } else {
        setTranslateX((cur) => {
          return cur - rightSpaceWidth;});
      }
    }
  };

  return (
    <div ref={containerRef} style={{ width: width }} className="container">
      {leftArrowVisible && (
        <>
          <img src={'/img/value-select/chevron-left-white.svg'}
            className="leftArrow btn"
            onClick={() => handleArrowClick(LEFT)} />
        </>
      )}
      <div
        ref={listRef}
        className="list"
        style={{
          transform: `translateX(${translateX}px)`,
          transition: "transform 2s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
        }}
      >
        {children}
      </div>
      {rightArrowVisible && (
        <>
          <img src={'/img/value-select/chevron-right-white.svg'}
            className="rightArrow btn"
            onClick={() => handleArrowClick(RIGHT)} />
        </>
      )}
    </div>
  );
});

export default SliderContainer;

const useResizeObserver = (ref) => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setWidth(ref.current.clientWidth);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return width;
};
