import React from "react";

function SvgFacebook(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 29" fill="none" {...props}>
      <path
        d="M29.531 14.531C29.531 6.504 23.027 0 15 0 6.973 0 .469 6.504.469 14.531c0 7.266 5.273 13.301 12.246 14.356V18.75H9.023v-4.219h3.692v-3.164c0-3.633 2.168-5.683 5.45-5.683 1.64 0 3.28.293 3.28.293V9.55H19.63c-1.817 0-2.402 1.113-2.402 2.285v2.695h4.043l-.645 4.219h-3.398v10.137c6.972-1.055 12.304-7.09 12.304-14.356z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFacebook;
