import React, {useContext, useState} from "react";

import "./CompanyStructure.less"
import EmptyLine from "../../ui/EmptyLine";
import AJCheckbox from "../../ui/AJCheckbox";
import VCenterContainer, {HCenterContainer} from "../../ui/VCenterContainer";
import {useHistory} from 'react-router'
import {AJCenterButton} from "../../ui/AJButton3";
import CompanyRegisterContext from "../../context/CompanyRegisterContent";
import CompanyConfigureContainer, {FooterButtons} from "./CompanyConfigureContainer"
import "./LoginCommon.css"
import {Col, Row, Input} from "antd";
import {companyStructureItems} from "../../context/CompanyStructureProvider";
import CompanyStructureContext from "../../context/CompanyStructureContext";
import CompanyRegisterTemplate from "./CompanyRegisterTemplate";
const { TextArea } = Input;


function CompanyStructure(props) {

    let history = useHistory();
    // const {companyRegister, updateCompanyRegister} = useContext(CompanyRegisterContext)

    const {companyStructure, setCompanyStructure, updateCompanyStructure, setTechnologies, technologies} = useContext(CompanyStructureContext)

    // TODO ....
    const {getSavedCompanyRegister} = useContext(CompanyRegisterContext)
    const savedCompanyRegisterData = getSavedCompanyRegister()

    if (savedCompanyRegisterData) {
        // setCompanyStructure(savedCompanyRegisterData.companyStructure)
    }

    console.log(companyStructure())
    console.log(technologies())


    const header = 'Company Structure'
    const body = (
        <div>
            <EmptyLine height={12}/>
            <div className={'login-common-sub-title'}>
                Select all that apply
            </div>
            <EmptyLine height={48}/>
            <Row>

                <Col span={10}>
                    <EmptyLine height={20}/>
                    {
                        companyStructureItems.map((item, index) => {
                            return (
                                <div>
                                    <AJCheckbox
                                        title={item}
                                        checked={companyStructure()[item]}
                                        index={index}
                                        onChange={(checked, title)=>{

                                        updateCompanyStructure(checked, title)
                                    }}/>
                                    <EmptyLine height={20}/>
                                </div>
                            )
                        })
                    }
                </Col>
                <Col span={2}/>
                <Col span={10}>
                    <div>
                        <div className={'company-structure-description-title'}>
                            Technologies in use
                        </div>
                        <br/>
                            <TextArea placeholder={'Our core application tech is built in Python/Django/DRF, Backbone.js/Marionette, React/Redux, Node.js/ShareDB, WebRTC and websockets. We deploy to AWS with EKS, Docker, Terraform, and Chef.'}
                                      defaultValue={technologies()}
                                      className={'company-structure-description'}
                                      autoSize={{ minRows: 8, maxRows: 8 }}
                                      onChange={(e) => {
                                          setTechnologies(e.target.value)
                                          // console.log(technologies)
                                      }}
                            />

                    </div>
                </Col>
            </Row>
        </div>
    )

    const footer = <FooterButtons onNext={(e) => history.push('/register/filter-key-values')}/>
    return (
        <CompanyRegisterTemplate tips={'Values & Purpose Attracts Top Talent'}>
            <CompanyConfigureContainer header={header} body={body} footer={footer}/>
        </CompanyRegisterTemplate>
    )

}


export default CompanyStructure
