import React, {useContext, useState} from "react";
import PickValuesContext from "./PickValuesContext"
import {arrayContains} from "../utils/arrayUtil"
import {
    getOrderedValues,
    getPickedValues, reOrderDescribeValues,
    saveDescribeValues,
    saveOrderedValues,
    savePickedValues
} from "../storage/AJStorage";
import DescribeValuesContext from "./DescribeValuesContext";

export const requirePickedValueCount = 9


function PickValuesProvider(props) {
    // const [orderedValues, setOrderedValues] = useState({});

    const maxValueCount = requirePickedValueCount

    // const {reOrderDescribeValues} =  useContext(DescribeValuesContext)

    return (
        <PickValuesContext.Provider value={{
            pickedValues : () => {
                return getPickedValues()
            },
            orderedValues: () => {
                return getOrderedValues()
            },
            updateValues: (selected, card) => {
                const pickedValues = getPickedValues()
                let valueC = pickedValues[card.index] || []
                if (selected) {
                    valueC.push(card.title)
                }
                else {
                    valueC = valueC.filter(e => e !== card.title)
                }
                pickedValues[card.index] = valueC

                savePickedValues(pickedValues)

                // clear ordered values!
                saveOrderedValues(null)
                // clear values description!
                // saveDescribeValues(null)
                // console.log(values)
            },
            containsValue: (card, title) => {
                const pickedValues = getPickedValues()
                let valueC = pickedValues[card.index] || []
                return arrayContains(valueC, title)
            },
            totalValueCount: () => {
                let total = 0
                const pickedValues = getPickedValues()
                for (const index in pickedValues) {
                    total += pickedValues[index].length
                }
                return total
            },
            leftValueCount: () => {
                let total = 0
                const pickedValues = getPickedValues()
                for (const index in pickedValues) {
                    total += pickedValues[index].length
                }

                // console.log("left " + (maxValueCount - total))

                return maxValueCount - total
            },
            setOrderedValues: (orderedValues) => {
                saveOrderedValues(orderedValues)
                // TODO
                // does not need to clear all!
                // clear values description!
                // reOrderDescribeValues(orderedValues)

            },
            clearAllPickedValues: () => {
                savePickedValues(null)
            },

            clearAllOrderedValues: () => {
                saveOrderedValues(null)
            },
            clearValuesByType:(card) => {
                const pickedValues = getPickedValues()
                pickedValues[card.index] = []
                card.tags.forEach(tag => tag.selected = false)
                savePickedValues(pickedValues)
            }}}>
            {props.children}
        </PickValuesContext.Provider>
    )
}

export default PickValuesProvider