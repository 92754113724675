import React from "react";
import "./CompanyProfileNextEvent.less";
import EmptyLine from "../../ui/EmptyLine";
import {EditIcon} from "../../page/CompanyProfile2";
import AJButton3 from "../../ui/AJButton3";
import ValueMediaUpload from "../ValueMediaUpload";
import EmptySpan from "../../ui/EmptySpan";
import {AJEditableDiv2} from "../../ui/AJRichEditor";
import { QuestionsClickPopOver } from "./CompanyProfileTopValues";

const questions = <>

1. Describe your company's next event.
</>

export default function CompanyProfileNextEvent(props) {
    return (
        <div className="next-event-container">
            <div>
                <EditIcon/>
                <span className="title">Sync Next Event</span>
            </div>

            <NextEventDescription {...props} />
        </div>
    )
}

function NextEventDescription(props) {
    const {profileData, version} = props;
    if (!profileData.companyStructure.misc) {
        profileData.companyStructure.misc = {};
        profileData.companyStructure.misc.nextEvent = {image: null, text: null, value: null, video: null};
    }
    let value = profileData.companyStructure.misc.nextEvent;

    return (
        <div className={'company-profile2-value-desc'}>
            <div className={'company-profile2-value-desc-title'}>
                <div style={{textAlign: 'right'}}>
                    <QuestionsClickPopOver questions={questions} />
                </div>
            </div>
            <EmptyLine height={12}/>

            <div className={'company-profile2-value-desc-text'}>
                <AJEditableDiv2 readonly={false}
                                placeholder={"Describe your company's next event."}
                                version={version}
                                defaultValue={value.text ?? ''}
                                onChange={(newValue) => {
                                    value.text = newValue
                                }}
                                width={570}/>
            </div>
            <EmptyLine height={27}/>

            <div>
                <ValueMediaUpload
                    readonly={false}
                    hiddenVideo={false}
                    value={value}
                    height={375}
                    width={570}
                    onVideoChange={()=>{}}
                    onVideoSave={(video) => {
                        value.video = video
                        value.image = null
                    }}
                    onImageChange={(image) => {
                        value.video = null
                        value.image = image
                    }}
                    footer={
                        ({openUploadingImage, showLinkVideoModel}) =>
                            <>
                                <div style={{width: 578, textAlign: 'right', paddingRight: 20}}>
                                    <AJButton3
                                        title={'Upload Image'}
                                        titleStyle={{fontSize: '10px'}}
                                        size={'mini'}
                                        width={127}
                                        type={'secondary'}
                                        height={30}
                                        onClick={() => {
                                            openUploadingImage()
                                        }}/>
                                    <EmptySpan width={12}/>
                                    <AJButton3 title={'Link Video'}
                                        titleStyle={{fontSize: '10px'}}
                                        size={'mini'}
                                        width={127}
                                        type={'secondary'}
                                        height={30}
                                        onClick={()=>{
                                            showLinkVideoModel(true)
                                        }}/>
                                </div>
                            </>
                    }/>
            </div>
        </div>
    )
}
