import React, {useRef, useState} from "react";
import "./NFTPage.less"
import {Col, Row, message} from "antd";
import EmptyLine from "../ui/EmptyLine";
import AJCard from "../ui/AJCard";
import StartFooter from "../component/start/StartFooter";
import NFTPage from "./NFTPage";
import AJInput from "../ui/AJInput";
import AJInput2, {AJEmailInput} from "../ui/AJInput2";
import {AJCenterButton} from "../ui/AJButton3";
import {sendNFTEmail} from "../http/AJHttp";
export default function (props) {

    return <div className={'ntf-page'}>
        <div className={'ntf-page-header'}>NFT That Matters</div>
        <div className={'ntf-page-container'}>
            <Row>
                <Col span={12}>
                    <div>
                        {/*<img src={'/img/common/nft-1.png'} width={496} height={526} alt={'nft pic'}/>*/}
                        <video autoPlay={'autoplay'} controls="" width={496} style={{borderRadius: '12px'}}>
                            <source src="https://openseauserdata.com/files/2746bea284835b1d9c3a4baa181b2b26.mp4#t=0.001" type="video/mp4" />
                        </video>
                    </div>
                    <EmptyLine height={188}/>
                    <NFTCard/>
                    <EmptyLine height={545}/>
                </Col>
                <Col span={12}>
                    <div style={{paddingLeft: 32, marginTop: -16}}>
                        <div className={'ntf-page-title'}>
                            Genvalues first <br/>NFT collection
                        </div>
                        <br/>
                        <div className={'ntf-page-body'}>
                            Genvalues was created with the vision to inspire the next generation of value-driven talents do work align with their values and drive innovations for good.<br/>
                            We believe that humans are creative species craving to explore and have the power to overcome any challenge. We are many who wants to make a difference in the world. And in the spirit of all that, we are launching a unique giveaway <a href={'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/1025043156764980159683949676761650636587266656588422543951746458399807635473'} target="_blank">NFT</a>  project that symbolizes the urgency and importance of this matter.<br/><br/>
                            The inspiration behind this art also inspired by the the Netflix movie "Don't Look Up."<br/><br/>
                            There are 17 unique NFT:s, which referes to the 17 Sustianable Development Goals.<br/>
                            You will get exclusive access to the coming NFT project on Genvalues related to the subject if you hold a specific NFT number.
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
        <StartFooter/>
    </div>;


}

const NFTCard = () => {
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [loading, setLoading] = useState(false);
    return <div className={'nft-card'}>
        <div className={'nft-card-title'}>
            Win an NFT art
        </div>
        <EmptyLine height={60}/>
        <AJInput2 label={'Full Name'} width={230} value={name} onChange={(e) => {
            setName(e.target.value);
        }}/>
        <EmptyLine height={16}/>
        <AJInput2 label={'Email'} value={email} onChange={(e) => {
            setEmail(e.target.value);
        }}/>
        <EmptyLine height={39}/>
        <AJCenterButton title={'Send'}
                        size={'small'}
                        scheme={'white'}
                        loading={loading}
                        height={38}
                        width={230}
                        disabled={!email || !name}
                        onClick={async () => {
                            setLoading(true);
                            const response  = await sendNFTEmail(name, email)
                            if (response) {
                                message.success('Send NFT mail successfully!')
                            }
                            else {
                                message.error('Something wrong with sending NFT mail.')
                            }
                            setLoading(false);
                        }
        }/>
    </div>
}