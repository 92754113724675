import React, {useState} from "react";
import SocialLinksContext from "./SocialLinksContext";

export default function SocialLinksProvider(props) {

    const [socialLinks, setSocialLinks] = useState({})

    return (
        <SocialLinksContext.Provider value={{
            socialLinks: socialLinks,
            updateSocialLink: (name, url) => {
                socialLinks[name] = url
                setSocialLinks(socialLinks)
            },
            socialLinksIsEmpty: () => {
                for (const key in socialLinks) {
                    if (socialLinks[key] && socialLinks[key].length > 0) {
                        return false
                    }
                }
                return true
            }
        }}>
            {props.children}
        </SocialLinksContext.Provider>
    )

}