import React from "react";
import {useDrag, useDrop} from "react-dnd";
import ItemTypes2 from "./ItemTypes2";


export default function DndCard2(props) {
    const {index, id, text, moveCard, findCard, renderHeader, onEnd} = props

    const originalIndex = findCard(id).index
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes2.CARD, id, originalIndex },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        end: (dropResult, monitor) => {
            const { id: droppedId, originalIndex } = monitor.getItem()
            const didDrop = monitor.didDrop()
            if (!didDrop) {
                moveCard(droppedId, originalIndex)
            }
            else {
                if (onEnd) {
                    onEnd()
                }
            }
        },
    })
    const [, drop] = useDrop({
        accept: ItemTypes2.CARD,
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id)
                moveCard(draggedId, overIndex)
            }
        },
        drop: item => {
            console.log(item)
        }
    })
    const opacity = isDragging ? 0 : 1
    const display = renderHeader ? "inline-block" : null
    return (
        <div style={{whiteSpace: 'nowrap'}}>
            {
                renderHeader ? renderHeader(props) : null
            }
            <div ref={node => drag(drop(node))} style={{ opacity, display }}>
                {props.children}
            </div>
        </div>
    )
}

// const DndCard = ({ index, id, text, moveCard, findCard }) => {
// }

