import React, {useEffect, useState} from "react";
import { Table, Divider, Tag } from 'antd';
import {useHistory} from 'react-router'

import EmptyLine from "../ui/EmptyLine";
import {HCenterContainer} from "../ui/VCenterContainer";
import "./CompanySavedPage.less"
import {getFavorite, removeFavorite} from "../http/AJHttp";
import {Link} from "react-router-dom";
import ReactGA from 'react-ga';


/*
id: 3
uuid: ""
ipaddress: "117.89.77.219"
matchedvaluecnt: 1
companyname: ""
company: 6
 */


export default function CompanySavedPage(props) {
    const history = useHistory()

    const removeSavedCompany = async (companyData) => {
        console.log(companyData)
        let response = await removeFavorite(companyData.company)
        if (response) {
            let leftFavoriteData = favoriteData.filter(data =>
                data.id !== companyData.id
            )
            setFavoriteData(leftFavoriteData)
            if (props.refreshFavorite) {
                props.refreshFavorite()
            }
        }
    }
    const columns = [
        {
            title: 'COMPANY',
            dataIndex: 'companyname',
            key: 'name',
            // render: text => <a>{text}</a>,
            render: (text, record) =>
                <Link to={`/company-profile/${record.company}`}>{text}</Link>,
        },
        {
            title: 'VALUE MATCH',
            dataIndex: 'matchedvaluecnt',
            key: 'match',
            render: text =>
                <div>{text} of 9</div>,

        },
        {
            title: 'LOCATION',
            dataIndex: 'companylocation',
            key: 'location',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <span>
        <a onClick={()=>{
            removeSavedCompany(record)
        }}>Delete</a>
      </span>
            ),
        },
    ];

    const [favoriteData, setFavoriteData] = useState(null)

    useEffect(()=>{
        fetchFavorite()
    }, [])

    const fetchFavorite = async () => {
        const response = await getFavorite()
        if (response) {
            if (response.data && Array.isArray(response.data)) {
                setFavoriteData(response.data)
            } else {
                setFavoriteData([])
            }
        }
        else {
            ReactGA.pageview(`/company-profile/`)
            setFavoriteData([])
            // setFavoriteData([{
            //     id: 3,
            //     uuid: "",
            //     ipaddress: "117.89.77.219",
            //     matchedvaluecnt: 1,
            //     companyname: "Company",
            //     company: 6,
            // }])
        }
        // console.log(response.data)
        // return response.data
    }

    return (
        <div className={'company-saved-page'}>
            <EmptyLine height={436}/>
            <div className={'company-saved-page-title'}>
                <div style={{paddingLeft: 240}}>
                    Saved<br/> Companies
                </div>
            </div>
            <HCenterContainer>
            <div className={'company-saved-page-container'}>
                <div className={'company-saved-page-table-title'}>

                </div>
                <EmptyLine height={369}/>
                <Table
                    loading={!favoriteData}
                    columns={columns}
                    dataSource={favoriteData}
                    />
            </div>
            </HCenterContainer>
        </div>
    )
}
