import React, {useContext, useState} from "react";
import './PickValues.css'
import EmptyLine from "../../ui/EmptyLine";
import AJTag from "../../ui/AJTag";
import SelectableIcon from "../SelectableIcon";
import VCenterContainer, {HCenterContainer} from "../../ui/VCenterContainer";
import AJPopover from "../../ui/AJPopover";
import number from "less/lib/less/functions/number";
import {valuePatch} from "./ValuesConfig";
import AJButton3 from "../../ui/AJButton3";


export function formatValues(layout, values) {
    if (!layout) {
        // alert(`please set layout for ${keyData.toString()}`)
        return [values]
    }

    const formattedValues = []
    let originalValues = [...values]
    for (let index = 0; index < layout.length; ++index) {
        const number = layout[index]
        formattedValues.push(originalValues.slice(0, number))
        originalValues = originalValues.slice(number)
        if (originalValues.length === 0) {
            break
        }
    }

    return formattedValues
}

export function ValuesSelector(props) {
    const onClose = props.onClose || (()=>{})
    const card = props.card
    let values = card.values
    let formattedValues = formatValues(card.layout, values)
    let {colorStyle, titleStyle} = props
    colorStyle = colorStyle || 'dark'
    titleStyle = titleStyle || {}
    const index = card.index || 0
    const hidden = props.hidden
    const hPadding = props.hPadding
    const type = card.type

    const {updateValues, leftValueCount, containsValue, selectedValues} = props
    const onClick = props.onClick ||  function (selected, title) {
        updateValues(selected, {
            index : index,
            title: title
        })
        setTotalLeft(leftValueCount())
        return true
    }

    const [totalLeft,  setTotalLeft] = useState(leftValueCount())

    const renderTags = (tag, changeTitle) => {
        return (

                <div style={{paddingRight: '14px', paddingBottom: '15px', display: 'inline-block'}}>
                    <AJPopover placement={'right'} content={tag.tips || tag.value} width={140} scheme={'dark'}>
                        <span>
                            <AJTag
                                title={valuePatch(tag.value)}
                                data={tag}
                                onClick={onClick}
                                colorStyle={colorStyle}
                                checkSelected={ (title) => {
                                    return containsValue(card, title)
                                }}
                                toggleHover={(on, title) => {
                                    //console.log(`${tag.tips} ${on}`)
                                    // changeTitle(on, title)
                                }}
                                allowedSelect={()=>{
                                    return leftValueCount()>0
                                }}/>
                        </span>

                    </AJPopover>

                </div>

        )
    }

    const renderIcons = (value, changeTitle) =>{
        const icon = value.value
        const tips = value.tips
        return (
            <AJPopover content={tips} placement={'right'} width={140} scheme={'dark'}>
            <div style={{display: "inline-block", marginRight: "16px", marginBottom: "8px"}}>

                <SelectableIcon
                    icon={`/img/value/${value.image}.svg`}
                    data={value}
                    onClick={(selected, title) => {
                        const v = icon.replace(/-/g, ' ')
                        onClick(selected, v)
                    }}
                    checkSelected={ (title) => {
                        // console.log(title)
                        // console.log(card)
			const v = title.replace(/-/g, ' ')

                        return containsValue(card, v)
                    }}
                    allowSelect={()=>{
                        return leftValueCount()>0
                    }}
                />
            </div>
            </AJPopover>
        )
    }

    const titleHeight = 50

    const [title, setTitle] = useState(
        <div style={{height: titleHeight}}>
            {card.cardTitle ? card.cardTitle : `Pick your most important values`}
    </div>
    )


    return (
        <div className={'pick-values'} hidden={hidden}>
            <div className={'pick-values-container'}>
                {/*<EmptySpan width={1100}/>*/}
                <div style={{marginLeft: 'auto', marginRight: 0, width: '20px', height: '20px'}}>
                    <img src={'/img/login/close.svg'} onClick={() => {
                        onClose(card)
                    }}/>
                </div>
                <div className={'pick-values-title'} style={{...titleStyle}}>
                    {title}
                </div>
                <EmptyLine height={28}/>
                    {
                        formattedValues.map((values, index) => {
                            return <div className={'pick-values-tag-container'} key={index}>
                                {
                                    values.map((value, i) => {
                                        return (
                                            <span key={i}>
                                                {type === 'icon' ? renderIcons(value) : renderTags(value, (on)=>{
                                                    setTitle((<div style={{height: titleHeight}}>
                                                        Pick your most important values
                                                    </div>))
                                                })}
                                            </span>

                                        )
                                    })
                                }
                            </div>
                        })
                    }
                {/*</div>*/}
                <EmptyLine height={3}/>
                <div className={'pick-values-foot'} hidden={props.hiddenFooter}>
                    Total values left <b>{totalLeft}</b>
                </div>

                <div style={{position: 'absolute', right: 26, bottom: 20}} hidden={!props.hiddenFooter}>
                    <AJButton3 size={'small'}
                               title={<div style={{fontSize: 12, fontWeight: 600}}>Confirm</div>}
                               width={95}
                               height={24}
                               onClick={() => {
                                   onClose(card)
                               }}/>
                </div>
            </div>
        </div>
    )
}


