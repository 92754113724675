import React from "react";

function SvgCheckboxNone(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <rect x={0.5} y={0.5} width={15} height={15} rx={1.5} stroke="#BDBDBD" />
        </svg>
    );
}

export default SvgCheckboxNone;
