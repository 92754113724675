import React from "react";

function SvgLeftIcon(props) {
    let {state=1} = props;

    switch(state) {
        case 0:
            return null;
        case 1: // normal
            return (
                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17" cy="17" r="16.25" transform="matrix(-1 0 0 1 34 0)" stroke="black" stroke-width="1.5"/>
                    <path d="M19 10L12.6799 15.5183C12.4669 15.7001 12.2957 15.9269 12.1783 16.1829C12.0608 16.4388 12 16.7177 12 17C12 17.2823 12.0608 17.5612 12.1783 17.8171C12.2957 18.0731 12.4669 18.2999 12.6799 18.4817L19 24" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
            );
        case 2: // click
            return (
                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17" cy="17" r="16.25" fill="black" transform="matrix(-1 0 0 1 34 0)" stroke="black" stroke-width="1.5"/>
                    <path d="M19 10L12.6799 15.5183C12.4669 15.7001 12.2957 15.9269 12.1783 16.1829C12.0608 16.4388 12 16.7177 12 17C12 17.2823 12.0608 17.5612 12.1783 17.8171C12.2957 18.0731 12.4669 18.2999 12.6799 18.4817L19 24" stroke="white" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
            );
    }
}

export default SvgLeftIcon;
