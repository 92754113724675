import React from "react";

import "./CompanyRegisterTemplate.less"
import {Col, Row} from "antd";
import HammerComponent from "react-hammerjs";
import {HCenterContainer} from "../../ui/VCenterContainer";

export default function CompanyRegisterTemplate(props) {

    const {content, tips} = props
    return (
        <HCenterContainer>
        <div className={'company-register-template'}>
            <div className={'company-register-template-container'}>
                <div className={'company-register-template-tips-container'}>
                    <Row type={'flex'} align={'middle'} style={{height: '100%'}}>
                        <Col span={24}>
                            <HCenterContainer>
                            <div className={'company-register-template-tips'}>
                                {tips}
                            </div>
                            </HCenterContainer>

                        </Col>
                    </Row>

                </div>
                <div className={'company-register-template-content'}>
                    {props.children}
                </div>
            </div>
        </div>
        </HCenterContainer>
    )

}

export function CompanyRegisterTemplate2(props) {

    const {leftElement, rightElement} = props
    let width = props.width || '100%'
    let height = props.height || '100%'
    return (
        <HCenterContainer>
            <div className={'company-register-template2'} style={{width, height}}>
                <div className={'company-register-template-right'}>
                    {rightElement}
                </div>
                <div className={'company-register-template-left'}>
                    {leftElement}
                </div>

            </div>
        </HCenterContainer>
    )

}