import React from "react";

function SvgCheckboxChecked(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 2a2 2 0 012-2h16a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z"
                fill="#474A63"
            />
            <path
                d="M7.73 14.781a.726.726 0 001.012 0l8.04-8.039a.726.726 0 000-1.012l-.985-.984a.693.693 0 00-.985 0L8.25 11.31 5.16 8.246a.693.693 0 00-.984 0l-.985.984a.726.726 0 000 1.012l4.54 4.54z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgCheckboxChecked;
