import React, { useState, useContext } from 'react';
import {message} from 'antd';
import EmptyLine from "../../ui/EmptyLine";
import "./CompanyLogin.less";
import VCenterContainer, {HCenterContainer} from "../../ui/VCenterContainer";
import {useHistory, useLocation} from 'react-router';
import AJButton3, {AJCenterButton} from "../../ui/AJButton3";
import {changePassword, companyLogin, resetEmailPassword} from "../../http/AJHttp";
import AJInput2, {AJPasswordInput2} from "../../ui/AJInput2";
import AJCard from "../../ui/AJCard";
import "./LoginCommon.css";
import AppContext from "../../context/AppContext";
import ReactGA from 'react-ga';
const queryString = require('query-string');


export function CompanyLoginLayout(props) {
    const {leftComponent, rightComponent} = props
    const leftWidth = 500
    const rightWidth = 565
    const overlapWidth = 565 - 505

    return (
        <div className={'login-container'}>
            <HCenterContainer>
                <div style={{width: leftWidth + rightWidth - overlapWidth, position: "relative", height: 625}}>
                    <div style={{position: 'absolute',
                        width: rightWidth,
                        right: 0,
                        top: 39,
                        borderRadius: '50%',
                        height: 565}}>
                        {rightComponent ? rightComponent : (<VCenterContainer>
                            <div className={'login-desc-text'}>
                            We put<br/>Values and<br/> Purpose first <br/>– Always.
                            </div>
                            </VCenterContainer>)}
                    </div>
                    <div style={{position: 'absolute',
                        width: leftWidth,
                        left: 0,
                        top: 0,
                        height: 625}}>
                        {leftComponent}
                    </div>
                </div>
            </HCenterContainer>
        </div>
    )
}

function CompanyLogin(props) {
    let history = useHistory();
    const onChange = props.onChange || function () {}
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [onLogin, setOnLogin] = useState(false)
    const {setCurrentAccount} = useContext(AppContext)

    const login = async () => {
        setOnLogin(true)
        const res = await companyLogin(email, password)
        if (res) {
            const data = res.data
            const token = res.data.token
            const userId = res.data.userid
            const isAdmin = res.data.is_admin
            const companyId = res.data.companyid
            const companyName = res.data.companyname

            setCurrentAccount(res.data)
            props.login()

            if (isAdmin) {
                history.push(`/`)
            }
            else if (!companyId) {
                history.push(`/register/rank-values`)
            }
            else {
                history.push(`/company-profile/${companyId}`)

                ReactGA.event({
                    userId:userId,
                    isAdmin:isAdmin,
                    category: "company",
                    action: `/company-profile/${companyId}`,
                });

                ReactGA.pageview(`/company-profile/login`)
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'custom_event',
                'eventCategory':'sign_in',
                'eventAction': 'click',
                'eventlabel': isAdmin? `#admin# : ${userId}` : (companyName || userId),
                'lastLogin': new Date().toUTCString()
            });
        }
        else {
            message.error("Login failed!")
        }
        setOnLogin(false)
    }

    const leftComponent = <div  style={{height: "100%", float: 'right'}}>
        <AJCard width={500} height={623} borderRadius={44} hideClose={false} onClose={(e) => {
            history.goBack()
        }}>
            <div className={'login-form'}>
                <div className={'login-common-title'}>
                    Welcome
                </div>
                <EmptyLine height={57}/>
                <AJInput2 label={'Email'}
                          width={301}
                          onChange={onEmailChange}
                          onPressEnter={login}
                />

                <EmptyLine height={7}/>
                <EmptyLine height={38}/>
                <AJPasswordInput2 label={'Password'}
                                  width={301}
                                  onChange={onPasswordChange}
                                  onPressEnter={login}
                />

                <EmptyLine height={7}/>
                <EmptyLine height={61}/>

                <AJCenterButton
                    title={'Sign in'}
                    titleStyle={{fontFamily: 'ManropeBold', fontSize: '22px'}}
                    type={'secondary'}
                    height={60}
                    width={303}
                    loading={onLogin}
                    onClick={login}
                    onEnter={login}/>
                <EmptyLine height={27}/>
                <div className={'forgot-label'} style={{cursor: "pointer"}} onClick={async ()=>{
                    history.push('/register/reset-password')
                }}>Forgot Password?</div>
                <EmptyLine height={67}/>
                <AJCenterButton
                    title={'New user'}
                    titleStyle={{color: 'white', fontFamily: 'ManropeBold', fontSize: '22px'}}
                    height={60}
                    width={303}
                    onClick={() => {
                        history.push('/register/create-account')
                    }}
                />
            </div>

        </AJCard>
    </div>

    const rightComponent = <VCenterContainer>
        <div className={'login-desc-text'}>
            We put<br/>Values and<br/> Purpose first <br/>– Always.
        </div>
    </VCenterContainer>

    return (
        <CompanyLoginLayout leftComponent={leftComponent}/>

    )

    function onEmailChange(event) {
        const value = event.target && event.target.value
        onChange({
            email : value,
            password : password
        })
        setEmail(value)
    }

    function onPasswordChange(event) {
        const value = event.target && event.target.value
        onChange({
            email : email,
            password : value
        })
        setPassword(value)
    }
}

export function ResetPassword(props) {
    let history = useHistory();
    const [email, setEmail] = useState(null)
    const [loading, setLoading] = useState(false)

    const leftComponent = (
        <div  style={{height: "100%", float: 'right'}}>
            <AJCard width={500} height={623} borderRadius={44}
                    onClose={(e) => {
                history.goBack()
            }}>
                <div className={'login-form'}>
                    <div className={'login-common-title'}>
                        Reset Password
                    </div>
                    <div className={'login-container-prompt'}>
                        <br/>
                        Submit Email to reset <br/>your password
                    </div>
                    <EmptyLine height={85}/>
                    <AJInput2 label={'Email'} onChange={(e) => {
                        setEmail(e.target.value)
                    }}/>
                    <div style={{position: 'absolute', bottom: 67}}>
                        <AJButton3 title={'Send'}
                                   width={303}
                                   height={60}
                                   shadow={'0px 10px 20px rgba(0, 0, 0, 0.15)'}
                                   loading={loading}
                                   onClick={async ()=>{
                                       setLoading(true)
                                       const response = await resetEmailPassword(email)
                                       setLoading(false)
                                       if (response) {
                                           message.info("Please check change password email in your box!")
                                           history.push("/")
                                       }
                                       else {
                                           message.error("Invalid email address!")
                                       }
                                   }}/>
                    </div>
                </div>

            </AJCard>
        </div>
    )
    return (
        <CompanyLoginLayout leftComponent={leftComponent}/>
    )
}

export function ChangePassword(props) {
    let history = useHistory();
    const search = useLocation().search
    const params = queryString.parse(search);

    // TODO
    // if email or token is null, go back to home page
    const email = params.email
    const token = params.token
    const [loading, setLoading] = useState(false)
    const [password1, setPassword1] = useState(null)
    const [password2, setPassword2] = useState(null)
    if ((!email || email.length === 0) || (!token || token.length === 0) ) {
        message.error("Email or token should not be empty")
        // history.push("/")
        return null
    }

    const leftComponent = (<div  style={{height: "100%", float: 'right'}}>
        <AJCard width={500} height={623} borderRadius={44} hideClose={true}>
            <div className={'login-form'}>
                <div className={'login-common-title'}>
                    Change Password
                </div>
                <div className={'login-container-prompt'}>
                    <br/>
                    Enter new password for e-mail
                    <br/>
                    <span style={{color: '#181414'}}>
                    {email}
                    </span>
                </div>
                <EmptyLine height={61}/>
                <AJPasswordInput2 label={'New Password'} onChange={(e) => {
                    setPassword1(e.target.value)
                }}/>
                <EmptyLine height={38}/>
                <AJPasswordInput2 label={'New Password'} onChange={(e) => {
                    setPassword2(e.target.value)
                }}/>

                <div style={{position: 'absolute', bottom: 67}}>
                    <AJButton3 title={'Confirm'} width={303} height={60} shadow={'0px 10px 20px rgba(0, 0, 0, 0.15)'}
                               onClick={async ()=>{
                                   setLoading(true)
                                   const response = await changePassword(email, token, password1, password2)
                                   setLoading(false)
                                   if (response) {
                                       history.replace({pathname: '/register/change-password-success'})
                                   }
                                   else {
                                       message.error("Change password failed!")
                                   }
                               }}/>
                </div>

            </div>

        </AJCard>
    </div>)
    return (
        <CompanyLoginLayout leftComponent={leftComponent}/>
    )
}

export function ChangePasswordSuccess(props) {
    let history = useHistory();
    const leftComponent = (
        <div  style={{height: "100%", float: 'right'}}>
            <AJCard width={500} height={623} borderRadius={44} onClose={()=>{
                //history.replace()
                history.replace({pathname: '/register'})
            }}>
                <div className={'login-form'}>
                    <div>
                        <img src={'/img/value/all-right.svg'}/>
                    </div>
                    <EmptyLine height={30}/>
                    <div className={'login-common-title'}>
                        Success!
                    </div>
                    <div className={'login-container-prompt'}>
                        <br/>
                        Password is now changed, to continue please login.
                    </div>
                    <div style={{position: 'absolute', bottom: 67}}>
                        <AJButton3 title={'Login'} width={303} height={60} shadow={'0px 10px 20px rgba(0, 0, 0, 0.15)'}
                                   onClick={()=>{
                                       history.replace({pathname: '/register'})
                                   }}/>
                    </div>
                </div>

            </AJCard>
        </div>
    )
    return (
        <CompanyLoginLayout leftComponent={leftComponent}/>
    )
}

export default CompanyLogin
