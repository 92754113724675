import React, {Component, useState} from 'react'
import {message} from "antd";
const QuestionsInInterviewContext = React.createContext("")

export function QuestionsInInterviewProvider({children}) {
    const [selectedValues, setSelectedValues] = useState([])
    return <QuestionsInInterviewContext.Provider value={{
        selectedValues: selectedValues,
        toggleValues: (value) => {
            const found = selectedValues.find(v => v === value);
            if (found) {
                setSelectedValues(selectedValues.filter(v => v !== value))
            } else {
                if (selectedValues.length === 9) {
                    message.warn('You have chosen 9 values.')
                    return;
                }
                selectedValues.push(value)
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'custom_event',
                    'eventCategory': 'essential_questions_selection',
                    'eventAction': 'job_interview_questions',
                    'eventlabel': `${value}`
                });
                setSelectedValues([...selectedValues])
            }
        }
    }}>
        {children}
    </QuestionsInInterviewContext.Provider>
}

export default QuestionsInInterviewContext;