import React, {useState, useMemo} from "react";
import "./CompanyProfileHeader3.less";
import EmptySpan from "../../ui/EmptySpan";
import EmptyLine from "../../ui/EmptyLine";
import {EditIcon} from "../../page/CompanyProfile2";
import AJAvatar from "../../ui/AJAvatar";
import SocialMediaIcons from "../SocialMediaIcons";
import SvgFavorite from "../../icons/Favorite";
import {AJUpload2} from "../../ui/AJUpload";
import {AJImageUrl} from "../../http/AJHttpConst";
import {addClapCount, removeFavorite, submitFavorite} from "../../http/AJHttp";
import {getAJNoLoginUUID, getClapCount, isLogged, saveClapCount} from "../../storage/AJStorage";
import Clap from "../../component/react-clap-button";
import Claps from "../../icons/Claps";
import {getOrderedValues} from "./CompanyProfileValues";
import {valuePatch} from "../values/ValuesConfig";

export default function CompanyProfileHeader3(props) {
    function CompanyProfileLogo(props) {
        const {editing, profileData} = props
        const logoUrl = profileData.companyStructure.logoUrl
        const avatarSize = 169
        const avatarPadding = 0
        const imageSize = avatarSize - avatarPadding
        return (
            <div style={{position: "relative", width: avatarSize, height: avatarSize}}>
                <AJAvatar size={avatarSize} padding={avatarPadding}>
                    {
                        <>
                            <div hidden={!editing} style={{width: avatarSize, height:avatarSize, overflow: 'hidden', borderRadius: '50%'}}>
                                <AJUpload2 imageWidth={avatarSize}
                                           imageHeight={avatarSize}
                                           imageUrl={AJImageUrl(logoUrl)}
                                           placeholderImage={'/img/common/upload-placeholder.png'}
                                           onUploaded={(file) => {
                                               const logoUrl = file.response.image
                                               profileData.companyStructure.logoUrl = logoUrl
                                           }}/>
                            </div>

                            <div hidden={editing}>
                                <div style={{width: avatarSize, height:avatarSize, overflow: 'hidden',  borderRadius: '50%'}}>
                                    <img src={AJImageUrl(logoUrl) || '/img/common/upload-placeholder.png'} width={avatarSize} height={avatarSize}/>
                                </div>
                            </div>
                        </>
                    }
                </AJAvatar>

                <div style={{position: "absolute", bottom: 0, right: 0}} hidden={!editing}>
                    <EditIcon/>
                </div>
            </div>
        )
    }
    const [favorite, setFavorite] = useState(props.profileData.favorite)

    function checkIsSaved() {
        if (!props.favoriteData) {
            return false
        }
        if (!Array.isArray(props.favoriteData)) {
            return false
        }
        const found = props.favoriteData.filter(f =>{
            return (f.company === props.profileData.id
            && (f.uuid=== getAJNoLoginUUID() /*|| localStorage.getItem('local-ip') === f.ipaddress*/))
        })
        return found.length > 0
    }
    const [isSaved, setIsSaved] = useState(checkIsSaved())
    const toggleSave = async ()=>{
        if (isSaved) {
            let response = await removeFavorite(props.profileData.id)
            if (response) {
                let f = favorite - 1
                if (f < 0) {
                    f = 0
                }
                setFavorite(f)
                if (props.refreshFavorite) {
                    props.refreshFavorite()
                }
            }
        }
        else {
            let response = await submitFavorite(props.profileData.id)
            if (response) {
                setFavorite(favorite + 1)
                setIsSaved(true)
                if (props.refreshFavorite) {
                    props.refreshFavorite()
                }
            }
        }

    }

    function CompanyLikes(props) {
        const [count, setCount] = useState(props.profileData.clapTotal || 0)
        return (
            <>  
                {/* <div hidden={isSaved || isLogged()}  style={{display: "inline-block"}}>
                    <div onClick={toggleSave} style={{cursor: 'pointer', width: 'fit-content', display: "inline-block",}}>
                        <div style={{display: "inline-block", verticalAlign: 'bottom'}} >
                            {
                                isSaved ?
                                    <SvgFavorite fill={'#181414'}
                                                    width={24}
                                                    height={24}
                                                    style={{verticalAlign: 'bottom'}}/>
                                    : <img src={'/img/company-profile/favorite.svg'} style={{bottom: '4px', position: 'relative'}}/>
                            }

                        </div>
                        <EmptySpan width={11}/>
                        <div className={'company-profile-company-share2'}>
                            SAVE
                        </div>
                    </div>
                </div> */}
                <div style={{cursor: 'pointer', width: 'fit-content', display: "inline-block"}}>
                    <Clap
                        count={getClapCount(props.profileData.id)}
                        countTotal={count}
                        maxCount={50}
                        isClicked={false}
                        unclicked={false}
                        onCountChange={() => {
                            setCount(count + 1);
                            saveClapCount(getClapCount(props.profileData.id) + 1, props.profileData.id)
                            addClapCount(getClapCount(props.profileData.id), getAJNoLoginUUID(), props.profileData.id)
                        }}
                        style={{border: 'none', background: 'transparent'}}
                        iconComponent={props => <Claps {...props} style={{marginTop: -4}} size={38} /> }
                    />
                    <div style={{marginLeft: -6, display: "inline-block", color: '#181414', fontSize: 32, fontFamily: 'InterMedium'}}>
                        {count}
                    </div>
                </div>
            </>
        )
    }

    function getCompanyPic(valueList) {
        let img = undefined;
        for (let value of Object.values(valueList)) {
            if (value.image) {
                img = 'https://genvalues.com/' + value.image;
                break;
            }
        }
        return img = img || '/img/company-profile/default-company-pic.svg';
    }


    const orderedValues = useMemo(() => getOrderedValues(props.profileData), [props.profileData])
    const companyPic = useMemo(() => getCompanyPic(props.profileData.describeValues), [props.profileData])

    return (
        <>
        <div className={'company-profile-header3'}>
            <div className="company-desc">
                <div>
                    <span className="profile-title">Principles & values&nbsp;&nbsp;</span>
                    <span>
                        <CompanyLikes {...props}/>
                    </span>

                    <div className={'values-tags-container'}>
                        {
                            orderedValues.map((v, index) => {
                                return (
                                    <div key={index} style={{paddingRight: '7px', paddingBottom: '13px', display: 'inline-block'}}>
                                        <span className={'value-tag'}>
                                            {valuePatch(v)}
                                        </span>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <EmptyLine height={40}/>

                    <div style={{alignItems: 'center', display: 'flex'}}>
                        <div style={{display: 'inline-block'}}>
                            <CompanyProfileLogo {...props}/>
                        </div>

                        <div style={{display: 'inline-block'}} className="company-info">
                            <img src={'/img/company-profile/location.svg'}/>
                            <span>{props.profileData.location || 'Stockholm, Sweden'}</span>
                            <div className={'company-profile-company-icon-group'}>
                                <SocialMediaIcons
                                    color={'black'}
                                    width={18}
                                    height={18}
                                    space={18}
                                    socials={props.profileData.socialLinks}
                                    twitterStyle={{'width': '18px', 'height': '18px'}}/>
                            </div>
                        </div>
                    </div>

                    <EmptyLine height={40}/>
                    <div className="company-desc-text">{props.profileData.introducation}</div>
                </div>
            </div>

            <div className="company-pic">
                <img src={companyPic} />
            </div>
        </div>
        </>
    )
}



