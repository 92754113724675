import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';

import "./SelectableIcon.css"

function SelectableIcon(props) {

    let {checkSelected, allowSelect} = props
    checkSelected = checkSelected || (() => false)
    allowSelect = allowSelect || (() => true)
    const icon = props.icon || "/img/selectable-icon/zero-hunger.svg"
    const onClickCallback = props.onClick

    const [selected, setSelected] = useState(checkSelected(props.data.value))
    const [showHover, setShowHover] = useState(false)
    const [disableHover, setDisableHover] = useState(false)

    // console.log(`${icon} selected: ${checkSelected(icon)} ${selected}`)

    // console.log(props.data)


    const onClick = (e) => {

        if (!selected) {
            if (!allowSelect()) {
                return
            }
        }

        setSelected(!selected)

        if (selected) {
            setDisableHover(false)
        }
        else {
            setDisableHover(true)
            setShowHover(false)
        }

        onClickCallback(!selected, icon)
    }

    const onMouseOver = (e) => {
        if (!disableHover) {
            setShowHover(true)
        }
    }

    const onMouseLeave = (e) => {
        if (!disableHover) {
            setShowHover(false)
        }
    }

    return (
        <div className={'selectable-icon'}
                 onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}>
                <div className={'selectable-icon-container'} hidden={showHover}>
                    <img src={icon}/>
                </div>
                <div className={'selectable-icon-container-hover'} hidden={!showHover}>
                    <img src={icon} />
                </div>
                <div className={'selected-icon-container'} hidden={!selected}>
                    <div className={'selected-icon'} >

                        <Row type="flex" justify="space-around" align="middle" style={{height: '100%'}}>
                            <Col>
                                <img src={'/img/selectable-icon/selected.svg'}/>
                            </Col>
                        </Row>
                    </div>
                </div>
        </div>

    )
}

export default SelectableIcon

function formatSelectableIcon(props) {

}