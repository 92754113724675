import React from "react";

function SvgMedium(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 27" fill="none" {...props}>
      <path
        d="M0 .406v26.25h26.25V.406H0zm21.797 6.27L20.39 8.023c-.118.059-.176.235-.176.352v9.96c0 .118.058.294.176.352l1.347 1.348v.293h-6.914v-.293l1.465-1.348c.117-.175.117-.175.117-.41V10.25l-3.984 10.078h-.527L7.325 10.25v6.738a.866.866 0 00.234.762l1.875 2.285v.293H4.16v-.293l1.817-2.285c.234-.176.293-.469.234-.762V9.195c.059-.175-.059-.41-.176-.586l-1.64-1.933v-.352h5.097l3.926 8.672 3.516-8.613h4.863v.293z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgMedium;
