import React, {useState} from "react";

import CompanyStructureContext from "./CompanyStructureContext";
import {getCompanyStruct, saveCompanyStruct} from "../storage/AJStorage";

export const companyStructureItems = [
    'B2C',
    'B2B',
    'Self-Funded',
    'Technical Founder(s)',
    // 'PBC / B-Corp',
    'Remote-OK'
]

function initStructure() {
    const structure = {}
    companyStructureItems.forEach(item => {
        structure[item] = false
    })

    return structure
}

export default function CompanyStructureProvide(props) {

    // const [companyStructure, setCompanyStructure] = useState(getCompanyStruct().structure)
    // const [technologies, setTechnologies] = useState(getCompanyStruct().technologies)

    return (
        <CompanyStructureContext.Provider value={{
            companyStructure: () => {
                return getCompanyStruct().structure || {}
            },
            // getCompanyStructure: () => {
            //     return getCompanyStruct().structure
            // },
            setCompanyStructure: (structure) => {

                saveCompanyStruct({
                    ...getCompanyStruct(),
                    structure
                })
            },
            technologies: () => {
                return getCompanyStruct().technologies
            },
            setTechnologies: (technologies) => {
                saveCompanyStruct({
                    ...getCompanyStruct(),
                    technologies
                })
            },
            updateCompanyStructure: (selected, structureName) => {
                let structure = getCompanyStruct().structure || initStructure()
                console.log(structure)
                structure[structureName] = selected
                saveCompanyStruct({
                    ...getCompanyStruct(),
                    structure
                })
            }
        }}>
            {props.children}
        </CompanyStructureContext.Provider>
    )
}