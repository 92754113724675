import React, {useState} from "react";
import update from "immutability-helper";
import {useDrop} from "react-dnd";
import ItemTypes2 from "./ItemTypes2";
import DNDCard2 from "./DNDCard2";


function _DNDContainer(props) {

}

export default function DNDContainer2(props) {
    const [cards, setCards] = useState(props.items || [])
    return <DNDContainer3 {...props} cards={cards} setCards={setCards}/>
}

export function DNDContainer3(props) {
    const {cards, setCards, renderContent, renderHeader, containerStyle} = props

    const onEnd = props.onEnd || (()=>{})
    const onMovedCard = props.onMovedCard || (()=>{})

    // console.log(items)

    let onEndWrapper = () =>{
        onEnd(cards)
        // console.log(cards)
    }

    const moveCard = (id, atIndex) => {
        const { card, index } = findCard(id)
        const newCards = update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        });
        setCards(newCards)
        onMovedCard(newCards)
        // onMovedCard(newCards)
    }

    const findCard = id => {
        const card = cards.filter(c => `${c.id}` === id)[0]

        return {
            card,
            index: cards.indexOf(card),
        }
    }

    const [, drop] = useDrop({ accept: ItemTypes2.CARD })
    return (
        <div ref={drop} style={{...containerStyle}}>
            {cards.map((card, index) => (
                <DNDCard2
                    onEnd={onEndWrapper}
                    index={index}
                    key={card.id}
                    id={`${card.id}`}
                    moveCard={moveCard}
                    findCard={findCard}
                    renderHeader={renderHeader}>
                    {renderContent ? renderContent(card.content) : card.content}
                </DNDCard2>
            ))}
        </div>
    )
}


export const AJDraggableContainer = DNDContainer2
export const AJDraggableContainerV2 = DNDContainer3