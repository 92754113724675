import React, {Component, useState} from "react";
import AppContext from "./AppContext"
import {
    AJLoginInfoKey,
    getFromAJStorage,
    saveToAJStorage, setCurrentCompanyId, setCurrentCompanyName, setCurrentIsAdmin,
    setCurrentToken,
    setCurrentUserId, setCurrentUserName
} from "../storage/AJStorage";

function AppProvider(props) {
    const [currentAccount, setCurrentAccount] = useState(getFromAJStorage(AJLoginInfoKey))
    return (
        <AppContext.Provider value={{
            currentAccount : currentAccount,
            setCurrentAccount: (account) => {
                setCurrentAccount(account)
                setCurrentUserId(account.userid)
                setCurrentToken(account.token)
                setCurrentUserName(account.username)
                setCurrentIsAdmin(account.is_admin)
                setCurrentCompanyId(account.companyid)
                setCurrentCompanyName(account.companyname)

                saveToAJStorage(AJLoginInfoKey, account)
            }
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppProvider
