import React from 'react'

import CompanyProfileEditorContext from "./CompanyProfileEditorContext";
import {useState} from "react";

export default function CompanyProfileEditorProvider(props) {

    const [companyImage, setCompanyImage] = useState(null)


    return (
        <CompanyProfileEditorContext.Provider value={{
            companyImage : companyImage,
            setCompanyImage : setCompanyImage,

        }}>
            {props.children}
        </CompanyProfileEditorContext.Provider>

    )
}