import React from "react";


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import CreateAccount from "../component/login/CreateAccount";
import CompanyLogin, {ChangePassword, ChangePasswordSuccess, ResetPassword} from "../component/login/CompanyLogin";
import CompanyStructure from "../component/login/CompanyStructure";
import CompanyRegisterProvide from "../context/CompanyRegisterProvider";
import FilterKeyValues, {UpdateFilterKeyValues} from "../component/login/FilterKeyValues";
import RankValues, {UpdateRankValues} from "../component/login/RankValues";
import DescribeValuesContainer, {
    CompanyDescribeValue,
    DescribeValues2,
    UpdateDescribeValuesContainer
} from "../component/login/DescribeValues";
import {HCenterContainer} from "../ui/VCenterContainer";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import styled, {keyframes} from "styled-components";
import {PageWithAnimation} from "../page/PageAnimation";
import {
    AddJobOpeningManually, AddJobOpeningManually2,
    AddSocialLinks,
    ConfirmOpenings,
    InputCareerUrl,
    SyncJobOpenings
} from "../component/login/AddJobOpenings";
import NotFound404 from "../page/NotFound404";


export default function RegisterRoute(props) {
    let { path, url } = useRouteMatch();
    let history = useHistory();

    return (
        <div>
            <CompanyRegisterProvide>
                <Switch>
                    <Route exact path={path}>
                        <CompanyLogin login={props.login || (()=>{})}/>
                    </Route>
                    <Route exact path={`${path}/reset-password`}>
                        <ResetPassword/>
                    </Route>
                    <Route exact path={`${path}/change-password`}>
                        <ChangePassword/>
                    </Route>
                    <Route exact path={`${path}/change-password-success`}>
                        <ChangePasswordSuccess/>
                    </Route>
                    <Route path={`${path}/create-account`}>
                        <CreateAccount login={props.login || (()=>{})}/>
                    </Route>
                    <Route path={`${path}/company-structure`}>
                        <CompanyStructure/>
                    </Route>
                    <Route exact path={`${path}/filter-key-values`}>
                        <FilterKeyValues/>
                    </Route>
                    <Route path={`${path}/filter-key-values/update/:id`} component={UpdateFilterKeyValues}/>

                    <Route exact path={`${path}/rank-values`}>
                        <RankValues/>
                    </Route>

                    <Route path={`${path}/rank-values/update/:id`} component={UpdateRankValues}/>

                    <Route exact
                           path={`${path}/describe-values/update/:companyId`}
                           component={UpdateDescribeValuesContainer}/>
                    <Route exact
                        path={`${path}/describe-values`}
                        component={DescribeValuesContainer}/>

                    <Route exact
                           path={`${path}/describe-values/:id`}
                           render={routeProps =>{
                               return <CompanyDescribeValue
                                   {...routeProps}/>
                           }}/>

                    <Route path={`${path}/sync-job-openings`} component={SyncJobOpenings}/>
                    <Route path={`${path}/add-job-openings-manually`} component={AddJobOpeningManually2}/>

                    <Route path={`${path}/input-career-url`} component={InputCareerUrl}/>

                    <Route path={`${path}/confirm-openings`} component={ConfirmOpenings}/>

                    <Route path={`${path}/add-social-links`} component={AddSocialLinks}/>

                    <Route path={'*'}>
                        <NotFound404/>
                    </Route>
                </Switch>
            </CompanyRegisterProvide>
        </div>
    )
}


// {/*<Route render={({ location }) => {*/}
// {/*    return (*/}
// {/*        <PageWithAnimation>*/}
// {/*            <TransitionGroup component={null}>*/}
// {/*                <CSSTransition*/}
// {/*                    timeout={300}*/}
// {/*                    classNames="PageWithAnimation"*/}
// {/*                    key={location.key}*/}
// {/*                >*/}
// {/*                    <Switch location={location}>*/}
// {/*                        <Route*/}
// {/*                            path={`${path}/describe-values/:id`}*/}
// {/*                            component={DescribeValues}/>*/}
// {/*                    </Switch>*/}
// {/*                </CSSTransition>*/}
// {/*            </TransitionGroup>*/}
// {/*        </PageWithAnimation>*/}
//
// {/*    )*/}
// {/*}}/>*/}
